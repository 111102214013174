import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ReactJson from 'react-json-view';
import { StyledTab, StyledTabs } from '../../../components/StyledTabs';
import { a11yProps, TabPanel } from '../../../components/TabPanel';
import FarmOnboardChecklist from './FarmOnboardChecklist';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { fetchFarmOnboardCheck, selectOnboardFarmById } from './onboardSlice';

type ParamProps = {
    farmId: number | null;
};

export default function DetailsSaveResponse({ farmId }: ParamProps) {
    const [selectedTab, setSelectedTab] = React.useState(0);

    const dispatch = useAppDispatch();

    const data = useAppSelector((state) => selectOnboardFarmById(state, farmId));

    useEffect(() => {
        if (farmId && data?.farmId !== farmId) {
            dispatch(fetchFarmOnboardCheck(farmId));
        }
    }, [data, dispatch, farmId]);

    if (!data) {
        return <></>;
    }

    return (
        <>
            <Divider />
            <StyledTabs
                value={selectedTab}
                onChange={(_, newTab) => {
                    setSelectedTab(newTab);
                }}
                aria-label="Onboarding response tabs"
            >
                <StyledTab label="Checklist" {...a11yProps(0)} />
                <StyledTab label="JSON" {...a11yProps(1)} />
            </StyledTabs>
            <TabPanel value={selectedTab} index={0}>
                <FarmOnboardChecklist response={data} />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
                <Box padding={4}>
                    <ReactJson
                        src={data}
                        name="Response"
                        iconStyle="square"
                        displayDataTypes={false}
                    />
                </Box>
            </TabPanel>
        </>
    );
}
