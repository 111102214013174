import firebase from 'firebase/app';
import { get, post } from '../../../../api/client';
import { ParkMeasurement } from '../types/parkMeasurementTypes';
import 'firebase/auth';

export const getParkMeasurements = async (
    fromUtc: string,
    toUtc: string,
    farmId: number
): Promise<ParkMeasurement[]> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
        return [];
    }
    const response = await get(
        token,
        `parks/farm/${farmId}/measurements/from/${fromUtc}/to/${toUtc}`
    );
    return response as ParkMeasurement[];
};

export const downloadParkMeasurements = async (
    fromUtc: string,
    toUtc: string,
    farmId: number,
    farmName: string
): Promise<any> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
        return;
    }
    const response = await post(
        token,
        `parks/farm/${farmId}/measurements/from/${fromUtc}/to/${toUtc}/download`,
        {}
    );
    return {
        ...response,
        fileName: `paddocks-${farmName}-${fromUtc.substr(0, 10)}-${toUtc.substr(0, 10)}.csv`,
    };
};
