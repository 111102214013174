import { Dispatch } from '@reduxjs/toolkit';
import { addNotification } from './notificationsSlice';

export const genericErrorMessage = 'Sorry, something went wrong.';

export async function useFriendlyErrorHandler<D>(
    callbackFnc: Function,
    rejectWithValue: any,
    dispatch: Dispatch,
    options?: {
        params?: D;
        customMessage?: string;
        severity?: 'error' | 'success' | 'info' | 'warning' | undefined;
    }
) {
    try {
        return await callbackFnc(options?.params);
    } catch (e: any) {
        let message = options?.customMessage || extractErrorMessage(e);
        dispatch(addNotification({ message: message, severity: options?.severity || 'error' }));
        return rejectWithValue(message);
    }
}

function extractErrorMessage(error: any) {
    if (!error) {
        return genericErrorMessage;
    }

    if (typeof error === 'string') {
        if (error.startsWith('<')) return genericErrorMessage;
        return error;
    }

    if (typeof error === 'object' && error.message) {
        return error.message;
    }

    return genericErrorMessage;
}
