import { fetch, del } from 'api/client';
import { EventType } from 'components/activity-log/EventType';
import firebase from 'firebase/app';
import { ZoneGrazedEvent } from './grazingsHistoryType';

const getDeletedGrazingsHistoryByFarm = async (
    farmId: string,
    page: number,
    limit: number
): Promise<ZoneGrazedEvent[]> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    const queryParams = `?page=${page}&limit=${limit}&eventType=${EventType.ZoneGrazeDeleted}&sortBy=-payload.end&filterBy={"$and":[{"payload.farmId":"${farmId}"}]}`;
    const request = {
        token: token!,
        baseApiUri: `${process.env.REACT_APP_UAL_API_URL}/api`,
        endpointUri: `user_activity_log${queryParams}`,
    };

    const { result } = await fetch(request);
    return result;
};

export const getGrazingsHistoryByFarm = async (
    farmId: string,
    eventType: string,
    page: number,
    limit: number
): Promise<ZoneGrazedEvent[]> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    const queryParams = `?page=${page}&limit=${limit}&eventType=${eventType}&sortBy=-payload.end&filterBy={"$and":[{"payload.farmId":"${farmId}"}]}`;
    const request = {
        token: token!,
        baseApiUri: `${process.env.REACT_APP_UAL_API_URL}/api`,
        endpointUri: `user_activity_log${queryParams}`,
    };

    const { result } = await fetch(request);
    return result;
};

export const getActiveGrazingsHistoryByFarm = async (req: {
    farmId: string;
    eventType: string;
    page: number;
    limit: number;
}): Promise<ZoneGrazedEvent[]> => {
    const grazingHistory = await getGrazingsHistoryByFarm(
        req.farmId,
        req.eventType,
        req.page,
        req.limit
    );
    const deletedGrazingHistory = await getDeletedGrazingsHistoryByFarm(
        req.farmId,
        req.page,
        req.limit
    );
    const excludeIds = deletedGrazingHistory.map((d) => d.payload.grazedGroupId);
    return grazingHistory.filter((g) => !excludeIds.includes(g.payload.grazedGroupId));
};

export const deleteZoneGrazed = async <T>(req: {
    farmId: string;
    zoneId: string;
    grazedGroupId: string;
}): Promise<T> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    return await del(
        token!,
        `zone-grazing/farm/${req.farmId}/zone/${req.zoneId}/grazed-group/${req.grazedGroupId}`,
        {}
    );
};
