import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { shallowEqual } from 'react-redux';
import { fetchFormula } from './regressionSlice';
import { useFirestoreConnect } from 'react-redux-firebase';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(4),
        },
        textField: {
            minWidth: '24ch',
        },
    })
);

type InfoPanelProps = {
    batchId: string;
};

export default function InfoPanel({ batchId }: InfoPanelProps) {
    const classes = useStyles();

    useFirestoreConnect([
        {
            collection: 'ndvi-process-batch',
            storeAs: 'singleBatch',
            doc: batchId,
        },
    ]);
    const batch = useAppSelector((state) => (state.firestore as any).data?.singleBatch);

    const dispatch = useAppDispatch();
    const { formulasLoading, formulas } = useAppSelector((state) => {
        return {
            formulasLoading: state.regression.formulasState.loading,
            formulasError: state.regression.formulasState.error,
            formulas: state.regression.formulas,
        };
    }, shallowEqual);

    useEffect(() => {
        dispatch(fetchFormula(batchId));
    }, [batchId, dispatch]);

    if (!batchId) {
        return null;
    }

    return (
        <Paper className={classes.root}>
            <Box padding={1}>
                <Typography variant="subtitle1">Regression Details</Typography>
            </Box>
            <Divider />
            <Box padding={4}>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <strong>Processes</strong>
                            </TableCell>
                            <TableCell>
                                <Box
                                    display="flex"
                                    flexWrap="wrap"
                                    gridColumnGap={8}
                                    gridRowGap={8}
                                >
                                    {batch?.process_ids?.map((p: string) => (
                                        <Chip label={p} variant="outlined" />
                                    ))}
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <strong>Date Reported</strong>
                            </TableCell>
                            <TableCell>{batch?.date_reported_str} limit: 85</TableCell>
                        </TableRow>
                        <>
                            {formulasLoading ? (
                                <div>Loading Formulae...</div>
                            ) : (
                                formulas.map((f: any) => (
                                    <TableRow>
                                        <TableCell>
                                            <strong>{f.type}</strong>
                                        </TableCell>
                                        <TableCell>
                                            <strong>m:</strong> {f.m}, <strong>c:</strong> {f.c},{' '}
                                            <strong>r2:</strong> {f.r2}{' '}
                                            <strong>{f.type === 'WDRRE' && `weight:`}</strong>
                                            {f.type === 'WDRRE' && 0.1}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </>
                    </TableBody>
                </Table>
            </Box>
        </Paper>
    );
}
