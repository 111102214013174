import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { middayUtcStr } from '../../../../utils/DateUtil';
import { RootState } from '../../../../app/store';
import { GrazingDto, PaddockGrazing } from './grazingType';
import { getLatestGrazing, getPaddockGrazingsByFarm, reportPaddockGrazings } from './grazingsApi';
import { useFriendlyErrorHandler } from 'features/notifications/hooks';
import { STATUS } from 'utils/Store/Constants';

interface PaddockGrazingsState {
    grazings: PaddockGrazing[];
    latestGrazing: string;
    loading: boolean;
    status: string;
    error: string | null;
}

type fetchGrazingsProps = {
    from: string;
    to: string;
    farmId: number;
    farmLocalTimeZone: string;
};

export const fetchGrazingsByFarm = createAsyncThunk(
    'grazings/fetchAllGrazings',
    async (props: fetchGrazingsProps) =>
        await getPaddockGrazingsByFarm(
            middayUtcStr(props.from, props.farmLocalTimeZone),
            middayUtcStr(props.to, props.farmLocalTimeZone),
            props.farmId
        )
);

type reportGrazingsProps = {
    farmId: number;
    farmName: string;
    farmLocalTimeZone: string;
    grazings: GrazingDto[];
};

export const fetchReportGrazings = createAsyncThunk(
    'grazings/fetchReportGrazings',
    async ({ farmId, farmName, grazings }: reportGrazingsProps, { rejectWithValue, dispatch }) => {
        const callbackFnc = async () => await reportPaddockGrazings(farmId, farmName, grazings);
        return await useFriendlyErrorHandler(callbackFnc, rejectWithValue, dispatch);
    }
);

type latestGrazingProps = {
    moteId: number;
    before: string;
};
export const fetchLatestGrazing = createAsyncThunk(
    'grazings/fetchLatestGrazing',
    async (props: latestGrazingProps) => await getLatestGrazing(props.moteId, props.before)
);

const initialState: PaddockGrazingsState = {
    grazings: [],
    latestGrazing: '',
    loading: false,
    status: STATUS.idle,
    error: null,
};

export const grazingsSlice = createSlice({
    name: 'grazings',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: {
        [fetchGrazingsByFarm.pending.type]: (state: PaddockGrazingsState) => {
            state.loading = true;
            state.status = STATUS.loading;
            state.error = null;
        },
        [fetchGrazingsByFarm.fulfilled.type]: (
            state: PaddockGrazingsState,
            action: PayloadAction<PaddockGrazing[]>
        ) => {
            state.grazings = action.payload;
            state.loading = false;
            state.status = STATUS.succeeded;
            state.error = null;
        },
        [fetchGrazingsByFarm.rejected.type]: (
            state: PaddockGrazingsState,
            action: PayloadAction<string>
        ) => {
            state.loading = false;
            state.status = STATUS.failed;
            state.error = action.payload;
        },

        [fetchLatestGrazing.fulfilled.type]: (
            state: PaddockGrazingsState,
            action: PayloadAction<any>
        ) => {
            state.latestGrazing = action.payload;
        },

        [fetchReportGrazings.pending.type]: (state: PaddockGrazingsState) => {
            state.loading = true;
            state.status = STATUS.loading;
            state.error = null;
        },
        [fetchReportGrazings.fulfilled.type]: (state: PaddockGrazingsState) => {
            state.loading = false;
            state.status = STATUS.succeeded;
            state.error = null;
        },
    },
});

export const { reset } = grazingsSlice.actions;
export const selectAllPaddockGrazings = (state: RootState) => state.grazings.grazings;
export const selectLatestGrazing = (state: RootState) => state.grazings.latestGrazing;

export default grazingsSlice.reducer;
