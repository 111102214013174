// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

const appApiBaseUri = `${process.env.REACT_APP_API_URL}`;

async function callApi(
    token: string,
    baseUri: string,
    endpoint: string,
    { body, method, ...customConfig }: any = {}
) {
    const headers: any = { 'Content-Type': 'application/json' };
    headers.Authorization = `Bearer ${token}`;

    const config = {
        method: method,
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,
        },
    };

    if (body) {
        config.body = JSON.stringify(body);
    }

    return window
        .fetch(`${baseUri}/${endpoint}`, config)
        .then(async (response) => {
            try {
                const data = await response.json();
                if (response.ok) {
                    return data;
                } else {
                    return Promise.reject(data);
                }
            } catch {
                return Promise.reject(response);
            }
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

export const get = async (token: string, endpoint: string, customConfig = {}) => {
    return callApi(token, appApiBaseUri, endpoint, { ...customConfig, method: 'GET' });
};

export const post = async (token: string, endpoint: string, body: any, customConfig = {}) => {
    return callApi(token, appApiBaseUri, endpoint, { ...customConfig, body, method: 'POST' });
};

export const del = async (token: string, endpoint: string, body: any, customConfig = {}) => {
    return callApi(token, appApiBaseUri, endpoint, { ...customConfig, body, method: 'DELETE' });
};

/**
 * Generic fetch that can construct the entire uri based on baseUri and endpoint uri
 * @param req
 * @param customConfig
 * @returns Promise
 */
export const fetch = async (
    req: { token: string; baseApiUri: string; endpointUri: string },
    customConfig = {}
) => {
    return await callApi(req.token!, req.baseApiUri, req.endpointUri, {
        ...customConfig,
        method: 'GET',
    });
};
