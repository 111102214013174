import firebase from 'firebase/app';
import { get } from '../../api/client';
import { Farm } from './farmType';
import 'firebase/auth';

export const getFarms = async (farmGroupId: number): Promise<Farm[]> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
        return [];
    }
    return await get(token, `farms/group/${farmGroupId}`);
};
