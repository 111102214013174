import React, { useEffect } from 'react';

import FarmOnboardChecklist from './FarmOnboardChecklist';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Box, Divider, Typography } from '@material-ui/core';
import { fetchFarmOnboardCheck, selectOnboardFarmById } from './onboardSlice';

type ParamProps = {
    farmId: number | null;
};

export default function ManageHouseDetailsResponse({ farmId }: ParamProps) {
    const dispatch = useAppDispatch();

    const data = useAppSelector((state) => selectOnboardFarmById(state, farmId));

    useEffect(() => {
        if (farmId && data?.farmId !== farmId) {
            dispatch(fetchFarmOnboardCheck(farmId));
        }
    }, [data, dispatch, farmId]);

    if (!data) {
        return <></>;
    }

    return (
        <Box margin={2}>
            <Divider />
            <Box padding={2}>
                <Typography>Farm onboarding response details </Typography>
            </Box>
            <FarmOnboardChecklist response={data} />
        </Box>
    );
}
