import React, { Fragment } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ZoneGroupGrowthDisplayable } from '../types/paddockMeasurementTypes';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { Typography } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectMeasurementByDate, selectPaddock } from '../reducers/paddockMeasurementsSlice';
import { getGraphColor } from '../../../../utils/GraphColors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: 200,
            overflowX: 'scroll',
        },
        lastCell: {
            maxWidth: 20,
        },
        button: {
            margin: theme.spacing(1),
        },
        inactiveRow: {
            backgroundColor: '#eeeeee',
        },
        checkButtonCell: {
            paddingRight: 0,
        },
    })
);

type SingleDayMeasurementsProps = {
    forDate: Date;
};

export default function SingleDayMeasurements({ forDate }: SingleDayMeasurementsProps) {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const measurement = useAppSelector((state) =>
        selectMeasurementByDate(state, forDate?.toISOString())
    );
    const selectedPaddockId = useAppSelector(
        (state) => state.paddockMeasurements.selectedPaddockId
    );

    if (!measurement) {
        return <div>No Measurment Found for the given Date</div>;
    }

    return (
        <div className={classes.root}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            style={{ padding: 0, paddingLeft: 6 }}
                        />
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            style={{ padding: 0, paddingLeft: 6 }}
                        />
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            style={{ padding: 0, paddingLeft: 6 }}
                        />
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            style={{ padding: 0, paddingLeft: 6 }}
                        />
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            colSpan={3}
                            style={{ padding: 0, paddingLeft: 6 }}
                        >
                            <Typography variant="caption">Growth Rate</Typography>
                        </TableCell>
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            style={{ padding: 0, paddingLeft: 6 }}
                        />
                    </TableRow>
                    <TableRow>
                        <TableCell
                            align="right"
                            className={classes.lastCell}
                            style={{ padding: 0, paddingLeft: 6 }}
                        />
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            style={{ padding: 0, paddingLeft: 6 }}
                        >
                            <Typography variant="caption">Cover</Typography>
                        </TableCell>
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            style={{ padding: 0, paddingLeft: 6 }}
                        >
                            <Typography variant="caption">Available Cover</Typography>
                        </TableCell>
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            style={{ padding: 0, paddingLeft: 6 }}
                        >
                            <Typography variant="caption">Available Feed</Typography>
                        </TableCell>
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            style={{ padding: 0 }}
                        >
                            <Typography variant="caption">Daily</Typography>
                        </TableCell>
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            style={{ padding: 0 }}
                        >
                            <Typography variant="caption">3 Days</Typography>
                        </TableCell>
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            style={{ padding: 0 }}
                        >
                            <Typography variant="caption">7 Days</Typography>
                        </TableCell>
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            style={{ padding: 0, paddingLeft: 6 }}
                        >
                            <Typography variant="caption">Effective Area</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            align="right"
                            style={{ padding: 0, paddingLeft: 6, maxWidth: '18ch' }}
                        >
                            Farm Average
                        </TableCell>
                        <TableCell align="right">{measurement.aggregate.average_cover}</TableCell>
                        <TableCell align="right">
                            {measurement.aggregate.average_available_cover}
                        </TableCell>
                        <TableCell align="right">{`Total: ${measurement.aggregate.total_available_feed}`}</TableCell>
                        <TableCell align="right">
                            {measurement.aggregate.average_daily_growth_rate}
                        </TableCell>
                        <TableCell align="right">
                            {measurement.aggregate.average_growth_3_days}
                        </TableCell>
                        <TableCell align="right">
                            {measurement.aggregate.average_growth_7_days}
                        </TableCell>
                        <TableCell align="right">{`Total: ${measurement.aggregate.total_effective_area}`}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {measurement.zone_groups.map(
                        (zg: ZoneGroupGrowthDisplayable, index: number) => (
                            <Fragment key={zg.zone_group}>
                                {!zg.is_active && (
                                    <TableRow
                                        style={{ backgroundColor: getGraphColor(index).heading }}
                                    >
                                        <TableCell align="center" colSpan={9}>
                                            <Typography
                                                variant={'body2'}
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                {zg.zone_group}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {zg.is_active && (
                                    <>
                                        <TableRow
                                            style={{
                                                backgroundColor: getGraphColor(index).aggregate,
                                            }}
                                        >
                                            <TableCell
                                                align="right"
                                                style={{
                                                    fontWeight: 'bold',
                                                    padding: 0,
                                                    paddingLeft: 6,
                                                    maxWidth: '18ch',
                                                }}
                                            >
                                                {zg.zone_group} Group Average
                                            </TableCell>
                                            <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                                {zg.aggregate.average_cover}
                                            </TableCell>
                                            <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                                {zg.aggregate.average_available_cover}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                style={{ fontWeight: 'bold' }}
                                            >{`Total: ${zg.aggregate.total_available_feed}`}</TableCell>
                                            <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                                {zg.aggregate.average_daily_growth_rate}
                                            </TableCell>
                                            <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                                {zg.aggregate.average_growth_3_days}
                                            </TableCell>
                                            <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                                {zg.aggregate.average_growth_7_days}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                style={{ fontWeight: 'bold' }}
                                            >{`Total: ${zg.aggregate.total_effective_area}`}</TableCell>
                                        </TableRow>
                                        {zg.growths.map((g) => (
                                            <TableRow
                                                key={g.zone_name}
                                                style={{
                                                    backgroundColor:
                                                        g.paddock_id === selectedPaddockId
                                                            ? '#fff59d'
                                                            : getGraphColor(index).rows,
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() =>
                                                    dispatch(selectPaddock(g.paddock_id))
                                                }
                                            >
                                                <TableCell align="right">
                                                    {g.paddock_name}
                                                </TableCell>
                                                <TableCell align="right">{g.cover}</TableCell>
                                                <TableCell align="right">
                                                    {g.available_cover}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {g.available_feed}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {g.daily_growth_rate}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {g.growth_3_days}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {g.growth_7_days}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {g.effective_area}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                )}
                                {!zg.is_active &&
                                    zg.growths.map((g) => (
                                        <TableRow key={g.zone_name}>
                                            <TableCell align="right">{g.paddock_name}</TableCell>
                                            <TableCell align="right" />
                                            <TableCell align="right" />
                                            <TableCell align="right" />
                                            <TableCell align="right" />
                                            <TableCell align="right" />
                                            <TableCell align="right" />
                                            <TableCell align="right" />
                                        </TableRow>
                                    ))}
                            </Fragment>
                        )
                    )}
                </TableBody>
            </Table>
        </div>
    );
}
