import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import FarmsAutoComplete from '../farms/FarmsAutoComplete';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Farm } from '../farms/farmType';
import DateRangePicker from '../../components/DateRangePicker';
import CtaGroup from '../../components/CtaGroup';
import { DateTime } from 'luxon';
import { LinearProgress, Paper, Snackbar } from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import { doRunPphProcessor, reset } from './pphProcessorSlice';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { DATE_FORMATS } from '../../utils/DateUtil';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: theme.spacing(2),
        },
        paper: {
            margin: theme.spacing(2),
        },
    })
);

export default function PphProcessor() {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const farmGroupId = useAppSelector((state) => (state.firebase as any).profile?.farmGroupId);

    const { loading, message, error } = useAppSelector((state) => ({
        loading: state.pphProcessor.loading,
        message: state.pphProcessor.response?.message,
        error: state.pphProcessor.error,
    }));

    useEffect(() => {
        if (!loading && (message || error)) {
            setTimeout(() => dispatch(reset()), 6000);
        }
    }, [loading, message, error, dispatch]);

    const [selectedFarm, setSelectedFarm] = useState<Farm | null>(null);
    const [dateRange, setDateRange] = React.useState([
        DateTime.local().minus({ days: 1 }),
        DateTime.local(),
    ]);
    const [areFieldsCleared, setAreFieldsCleared] = useState(false);
    const handleAfterReset = () => setAreFieldsCleared(false);

    const handleCtaClick = (selectedCtaIndex: number) => {
        let type = 'full';
        switch (selectedCtaIndex) {
            case 1:
                type = 'net';
                break;
            case 2:
                type = 'zones';
                break;
        }
        dispatch(
            doRunPphProcessor({
                farmId: selectedFarm?.farmId!,
                farmLocalTimeZone: selectedFarm?.timeZone!,
                since: dateRange[0].toJSDate(),
                until: dateRange[1].toJSDate(),
                type: type,
            })
        );
    };

    const skeletonFields = (
        <>
            <Skeleton animation="wave" width={210} height={80} />
            <Skeleton animation="wave" width={210} height={80} />
            <Skeleton animation="wave" width={100} height={60} />
        </>
    );

    const fields = (
        <>
            <FarmsAutoComplete
                farmGroupId={farmGroupId}
                preSelectedFarm={selectedFarm}
                onSelect={(farm) => setSelectedFarm(farm)}
            />
            <DateRangePicker
                value={dateRange}
                placeholder="Select a date range"
                onChange={(values: any) => {
                    setDateRange([values.begin, values.end]);
                }}
                autoOk={true}
                format={DATE_FORMATS.dayMonthYearShort}
                reset={areFieldsCleared}
                onAfterReset={handleAfterReset}
            />
            <CtaGroup options={['Full', 'Net', 'zones']} onClick={handleCtaClick} />
        </>
    );

    return (
        <div className={classes.root}>
            <h4>PPH PROCESSOR</h4>
            {loading && <Alert severity="info">Queuing the request...</Alert>}
            {loading && <LinearProgress />}
            <Snackbar
                open={!!error}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity="error">Something went wrong! {error}</Alert>
            </Snackbar>
            <Snackbar
                open={!!message}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity="success">{message}</Alert>
            </Snackbar>
            <Paper className={classes.paper}>
                <Box display="flex" gridColumnGap={16} alignItems="center" padding={2}>
                    {!farmGroupId ? skeletonFields : fields}
                </Box>
            </Paper>
            <Paper className={classes.paper}>
                <Box padding={1}>
                    <Typography variant="subtitle1">Currently Running</Typography>
                </Box>
                <Divider />
                <Box display="flex" gridColumnGap={16} alignItems="center" padding={2}>
                    coming soon...
                </Box>
            </Paper>
        </div>
    );
}
