import React from 'react';
import { Box, createStyles, lighten, makeStyles, Theme } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { Str } from 'utils/StringConstants';
import clsx from 'clsx';
import { MoveHistoryModel } from '../paddockMoveHistoryType';
import { displayDateFormat } from 'utils/DateUtil';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            padding: theme.spacing(1),
            position: 'relative',
        },
        hide: {
            visibility: 'hidden',
        },
        spaceBetween: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        confirmation: {
            fontSize: '0.85rem',
            position: 'absolute',
            left: 0,
            backgroundColor: theme.palette.error.light,
            padding: theme.spacing(1),
        },
        highlight: {
            backgroundColor: lighten(theme.palette.error.light, 0.5),
            boxShadow: 'inset 0 0 2px #000',
        },
    })
);

type RowProps = {
    row: MoveHistoryModel;
    hasHighlight: boolean;
    displayDeleteButton: boolean;
    userFriendlyMessage: (row: MoveHistoryModel, idx: string) => JSX.Element;
    onRowClickHandler: (event: React.MouseEvent<unknown>, model: MoveHistoryModel) => void;
    onIconDeleteHandler: (event: React.MouseEvent<unknown>, model: MoveHistoryModel) => void;
};

export default function TableRowContent(props: RowProps) {
    const classes = useRowStyles();
    const {
        row,
        hasHighlight,
        displayDeleteButton,
        userFriendlyMessage,
        onRowClickHandler,
        onIconDeleteHandler,
    } = props;

    return (
        <TableRow
            id={`table-item-${row.eventId}`}
            hover
            onClick={(event) => onRowClickHandler(event, row)}
            role="checkbox"
            aria-checked={hasHighlight}
            tabIndex={-1}
            selected={hasHighlight}
        >
            <TableCell
                component="th"
                id={`row-delete-${row.eventId}`}
                scope="row"
                className={clsx(classes.row, {
                    [classes.highlight]: hasHighlight,
                })}
            >
                <Box width="100%" display="flex" alignItems="center">
                    <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={(e) => onIconDeleteHandler(e, row)}
                        className={clsx(Str.empty, {
                            [classes.hide]: !displayDeleteButton,
                        })}
                    >
                        <DeleteIcon />
                    </IconButton>
                    <Tooltip
                        key={row.eventId}
                        title={`Actioned by ${row.user} on ${displayDateFormat(row.eventTime)}`}
                    >
                        {userFriendlyMessage(row, row.eventId)}
                    </Tooltip>
                </Box>
            </TableCell>
        </TableRow>
    );
}
