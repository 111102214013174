import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import { Checkbox, ListItem } from '@material-ui/core';
import { PaddockChanges } from './onboardTypes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            zIndex: 999,
            marginTop: theme.spacing(1),
            overflow: 'scroll',
        },
        details: {
            overflow: 'scroll',
            height: 300,
            padding: 0,
        },
    })
);

type PaddocksToDeployProps = {
    paddocks: PaddockChanges[];
    onPaddocksChange: (paddocks: PaddockChanges[]) => void;
};

export default function PaddocksToDeploy({ paddocks, onPaddocksChange }: PaddocksToDeployProps) {
    const classes = useStyles();
    const [selectedPaddocks, setSelectedPaddocks] = useState<PaddockChanges[]>(paddocks);

    const handleToggle = (paddock: PaddockChanges) => () => {
        const isHidden = selectedPaddocks?.find((p) => p.name === paddock.name)?.hide;
        const paddocks = [
            ...(selectedPaddocks?.filter((p) => p.name !== paddock.name) ?? []),
            { name: paddock.name, hide: !isHidden },
        ];
        setSelectedPaddocks(paddocks);
        onPaddocksChange(paddocks);
    };

    useEffect(() => {
        setSelectedPaddocks(paddocks);
        // eslint-disable-next-line
    }, []);

    return (
        <div className={classes.root}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    Paddocks
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <List>
                        {paddocks &&
                            paddocks.map((p) => (
                                <ListItem button key={p.name} onClick={handleToggle(p)}>
                                    <Checkbox
                                        edge="start"
                                        checked={
                                            !selectedPaddocks?.find((f) => f.name === p.name)?.hide
                                        }
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': p.name }}
                                    />
                                    {p.name}
                                </ListItem>
                            ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
