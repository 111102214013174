import firebase from 'firebase/app';
import { get } from '../../api/client';
import { Mote } from './moteType';
import 'firebase/auth';

export const getMotes = async (farmGroupId: number, from: Date, to: Date): Promise<Mote[]> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
        return [];
    }
    return await get(
        token,
        `motes/farm-group/${farmGroupId}/from/${from.toISOString()}/to/${to.toISOString()}`
    );
};

export const getAllMotes = async (farmGroupId: number): Promise<Mote[]> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
        return [];
    }
    return await get(token, `motes/farm-group/${farmGroupId}`);
};
