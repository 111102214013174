import React, { useState } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { shallowEqual } from 'react-redux';
import Box from '@material-ui/core/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TableIcon from '@material-ui/icons/ViewListSharp';
import GraphIcon from '@material-ui/icons/AssessmentSharp';
import OutputGraph from './OutputGraph';

const columns: GridColDef[] = [
    { field: 'farm_name', headerName: 'Farm', width: 120 },
    { field: 'zone_name', headerName: 'Zone', width: 140 },
    { field: 'hapgr_mean_from_wdrre', headerName: 'WDRRE HAPGR', width: 160 },
    { field: 'zone_wdrre', headerName: 'WDRRE Zone', width: 160 },
    { field: 'hapgr_mean_from_ndre', headerName: 'NDRE HAPGR', width: 160 },
    { field: 'zone_ndre', headerName: 'NDRE Zone', width: 160 },
    { field: 'hapgr_mean_from_ndvi', headerName: 'NDVI HAPGR', width: 160 },
    { field: 'zone_ndvi', headerName: 'NDVI Zone', width: 160 },
];

type OutputProps = {
    output: any;
};

export default function Output({ output }: OutputProps) {
    const { outputLoading } = useAppSelector((state) => {
        return {
            outputLoading: state.regression.outputState.loading,
            outputError: state.regression.outputState.error,
        };
    }, shallowEqual);

    const [showGraph, setShowGraph] = useState<boolean>(false);
    const toggleGraphClick = () => setShowGraph(!showGraph);

    const rows = output.map((o: any) => ({ id: o.zone_name, ...o }));

    return (
        <Box display="flex" flexDirection="column">
            <Box alignSelf="flex-end">
                {showGraph ? (
                    <TableIcon color="secondary" onClick={toggleGraphClick} cursor="pointer" />
                ) : (
                    <GraphIcon color="secondary" onClick={toggleGraphClick} cursor="pointer" />
                )}
            </Box>
            {showGraph ? (
                <OutputGraph output={output} />
            ) : (
                <Box height={400} width="100%">
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10, 50, 100]}
                        disableSelectionOnClick
                        loading={outputLoading}
                    />
                </Box>
            )}
        </Box>
    );
}
