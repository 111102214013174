import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { shallowEqual } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Paddock } from './paddockType';
import { fetchPaddocks } from './paddocksSlice';

const styles = makeStyles((_: Theme) =>
    createStyles({
        root: {
            width: 180,
        },
    })
);

type PaddocksAutoCompleteProps = {
    farmId: number;
    farmLocalTimeZone: string;
    from: Date;
    to: Date;
    preSelectedPaddock: Paddock | null;
    onSelect: (paddock: Paddock) => void;
    refresh: boolean;
    onAfterRefresh: () => void;
};

export default function PaddocksAutoComplete({
    farmId,
    farmLocalTimeZone,
    from,
    to,
    preSelectedPaddock,
    onSelect,
    refresh,
    onAfterRefresh,
}: PaddocksAutoCompleteProps) {
    const classes = styles();
    const dispatch = useAppDispatch();
    const { paddocksLoading, paddocksLoadingStatus, paddocks } = useAppSelector((state) => {
        return {
            paddocksLoading: state.paddocks.loading,
            paddocksLoadingStatus: state.paddocks.status,
            paddocksError: state.paddocks.error,
            paddocks: state.paddocks.paddocks,
        };
    }, shallowEqual);

    useEffect(() => {
        if (paddocksLoadingStatus === 'idle' || refresh) {
            dispatch(fetchPaddocks({ farmId, farmLocalTimeZone, from, to }));
            onAfterRefresh();
        }
    }, [
        farmId,
        farmLocalTimeZone,
        from,
        to,
        paddocksLoadingStatus,
        dispatch,
        refresh,
        onAfterRefresh,
    ]);

    const [value, setValue] = React.useState<Paddock | null>(null);
    useEffect(() => {
        setValue(preSelectedPaddock);
    }, [preSelectedPaddock]);

    return (
        <Autocomplete
            id="paddocks"
            loading={paddocksLoading}
            options={paddocks}
            getOptionLabel={(option) => option.paddockName}
            getOptionSelected={(option, value) => option.paddockId === value.paddockId}
            renderInput={(params) => <TextField {...params} label="Paddock" variant="outlined" />}
            value={value}
            onChange={(event: any, newValue: any) => {
                setValue(newValue);
                onSelect(newValue);
            }}
            className={classes.root}
        />
    );
}
