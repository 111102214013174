export const GraphColors = [
    { heading: '#4FCF95', aggregate: '#6FD8A8', rows: '#BFEDD9' },
    { heading: '#64BAE3', aggregate: '#86CAE9', rows: '#BAE1F2' },
    { heading: '#F7B578', aggregate: '#F9CA9F', rows: '#FCDFC5' },
    { heading: '#CACF85', aggregate: '#D9DDA7', rows: '#ECEED3' },
    { heading: '#BA9C8C', aggregate: '#C9B2A6', rows: '#E0D3CC' },
];

export function getGraphColor(index: number) {
    return GraphColors && index < GraphColors.length ? GraphColors[index] : GraphColors[0];
}
