import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Paddock } from 'features/paddocks/paddockType';
import { PaddockGroup } from '../paddockGroupType';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectFilters } from 'features/measurements/filters/filtersSlice';
import DateSelector from 'components/DateSelector';
import Cta from 'components/Cta';
import { movePaddock } from '../paddockGroupsSlice';
import { shallowEqual } from 'react-redux';
import { fetchPaddocks } from 'features/paddocks/paddocksSlice';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LoadingButton from 'components/LoadingButton';
import _find from 'lodash/find';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: theme.spacing(1),
        },
        paper: {
            marginTop: theme.spacing(1),
            padding: theme.spacing(2),
        },
        wrapper: {
            display: 'flex',
            columnGap: '1rem',
            rowGap: '1rem',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        autoComplete: {
            width: '12rem',
        },
    })
);

type Props = {
    refresh: boolean;
};

export default function Move({ refresh }: Props) {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const filters = useAppSelector(selectFilters);

    const [selectedPaddock, setSelectedPaddock] = useState<Paddock | null>(null);
    const [selectedPaddockGroup, setSelectedPaddockGroup] = useState<PaddockGroup | null>(null);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [paddockMoveLoading, setPaddockMoveLoading] = useState<boolean>(false);

    const [areFieldsCleared, setAreFieldsCleared] = useState(false);
    const clearFields = () => {
        setSelectedDate(new Date());
        setSelectedPaddock(null);
        setPaddockValue(null);
        setPaddockGroupValue(null);
        setSelectedPaddockGroup(null);
        setAreFieldsCleared(true);
    };

    const handleAfterReset = () => setAreFieldsCleared(false);

    const handleMoveClick = () => {
        setPaddockMoveLoading(true);
        dispatch(
            movePaddock({
                farmId: filters.farm.farmId,
                farmLocalTimeZone: filters.farm.timeZone,
                asOf: selectedDate!,
                paddockId: selectedPaddock?.paddockId!,
                groupId: selectedPaddockGroup?.groupId!,
            })
        ).then((v) => setPaddockMoveLoading(false));
        clearFields();
    };

    const [refreshPaddocks, setRefreshPaddocks] = useState(true);

    useEffect(() => {
        setSelectedPaddock(null);
        setSelectedPaddockGroup(null);
    }, [dispatch]);

    const handleDateChange = (date: Date) => {
        setRefreshPaddocks(true);
        setSelectedDate(date);
        setPaddockGroupValue(null);
    };

    const [paddockGroupValue, setPaddockGroupValue] = React.useState<PaddockGroup | null>(null);
    const { paddockGroups } = useAppSelector((state) => {
        return {
            paddockGroups: state.paddockGroups.groups,
        };
    }, shallowEqual);

    const { paddocksLoading, paddocksLoadingStatus, paddocks } = useAppSelector((state) => {
        return {
            paddocksLoading: state.paddocks.loading,
            paddocksLoadingStatus: state.paddocks.status,
            paddocksError: state.paddocks.error,
            paddocks: state.paddocks.paddocks,
        };
    }, shallowEqual);

    useEffect(() => {
        if (refreshPaddocks) {
            const searchParams = {
                farmId: filters.farm.farmId,
                farmLocalTimeZone: filters.farm.timeZone,
                from: selectedDate,
                to: selectedDate,
            };
            dispatch(fetchPaddocks(searchParams));
            setRefreshPaddocks(false);
        }
    }, [
        dispatch,
        filters.farm.farmId,
        filters.farm.timeZone,
        paddocksLoadingStatus,
        refreshPaddocks,
        selectedDate,
    ]);

    const [paddockValue, setPaddockValue] = React.useState<Paddock | null>(null);
    useEffect(() => {
        if (selectedPaddock && selectedPaddock.paddockId > 0) {
            setPaddockValue(selectedPaddock);
        }
    }, [selectedPaddock]);

    useEffect(() => {
        if (refresh) {
            setRefreshPaddocks(true);
            clearFields();
        }
    }, [refresh]);

    useEffect(() => {
        const pdk = _find(paddocks, (p) => p.paddockId === selectedPaddock?.paddockId);
        setPaddockValue((v) => pdk || v);
        setSelectedPaddock((v) => pdk || v);
    }, [paddocks, selectedPaddock?.paddockId]);

    const isPaddockSelected = selectedPaddock && selectedPaddock.paddockId > 0;
    const isPaddockGroupSelected = selectedPaddockGroup && selectedPaddockGroup.groupId > 0;
    const showMoveCta = isPaddockSelected && isPaddockGroupSelected && selectedDate !== null;

    return (
        <div className={classes.root}>
            <Typography variant="body2">Move a Paddock to a Group</Typography>
            <Paper className={classes.paper}>
                <div className={classes.wrapper}>
                    <DateSelector
                        label="Select Date"
                        onChange={handleDateChange}
                        reset={areFieldsCleared}
                        onAfterReset={handleAfterReset}
                    />

                    <Autocomplete
                        id="paddocks"
                        loading={paddocksLoading}
                        options={paddocks}
                        getOptionLabel={(option) => option.paddockName}
                        getOptionSelected={(option, value) => option.paddockId === value.paddockId}
                        renderInput={(params) => (
                            <TextField {...params} label="Paddock" variant="outlined" />
                        )}
                        value={paddockValue}
                        onChange={(event: any, newValue: Paddock | null) => {
                            setPaddockValue(newValue);
                            setSelectedPaddock(newValue);
                            if (paddockGroupValue?.groupId === newValue?.zoneGroup?.zoneGroupId) {
                                setSelectedPaddockGroup(null);
                                setPaddockGroupValue(null);
                            }
                        }}
                        className={classes.autoComplete}
                    />

                    {isPaddockSelected && (
                        <Autocomplete
                            id="paddocksGroups"
                            options={paddockGroups}
                            getOptionLabel={(option) => option.groupName}
                            getOptionSelected={(option, value) => option.groupId === value.groupId}
                            getOptionDisabled={(option) =>
                                option.groupId === selectedPaddock?.zoneGroup?.zoneGroupId
                            }
                            renderInput={(params) => (
                                <TextField {...params} label="Paddock Group" variant="outlined" />
                            )}
                            value={paddockGroupValue}
                            onChange={(event: any, newValue: any) => {
                                setPaddockGroupValue(newValue);
                                setSelectedPaddockGroup(newValue);
                            }}
                            className={classes.autoComplete}
                        />
                    )}

                    <LoadingButton label="Move" size="small" loading={paddockMoveLoading}>
                        <Cta onClick={handleMoveClick} label="Move" isDisabled={!showMoveCta} />
                    </LoadingButton>
                </div>
            </Paper>
        </div>
    );
}
