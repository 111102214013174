import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grazing } from './grazingType';
import TableHeadInfo from 'components/TableHeadInfo';
import { IconButton } from '@material-ui/core';

interface HeadCell {
    id: keyof Grazing;
    label: string;
}

const headCells: HeadCell[] = [
    { id: 'paddock', label: 'Paddock' },
    { id: 'begin', label: 'Date' },
];

const enhancedTableStyles = makeStyles((theme: Theme) =>
    createStyles({
        cell: {
            lineHeight: '0.8rem',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    })
);

function EnhancedTableHead() {
    const classes = enhancedTableStyles();
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align="left" className={classes.cell}>
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(2),
        },
        table: {
            width: '100%',
        },
        cell: {
            display: 'flex',
            alignItems: 'center',
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        spacing: {
            margin: theme.spacing(1),
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        noBreak: {
            [theme.breakpoints.between('lg', 'lg')]: {
                maxWidth: '190px',
                textOverflow: 'ellipsis',
            },
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
    })
);

type AddedGrazingsProps = {
    grazings: Grazing[];
    onDeleteClick: (grazingToDelete: Grazing) => void;
};

export default function AddedGrazings({ grazings, onDeleteClick }: AddedGrazingsProps) {
    const classes = useStyles();

    const handleDeleteClick = (grazing: Grazing) => {
        onDeleteClick(grazing);
    };

    return (
        <Paper className={classes.root}>
            <TableHeadInfo title="Grazings" />
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="medium"
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead />
                    <TableBody>
                        {grazings.map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.paddock.paddockId}
                                >
                                    <TableCell component="th" id={labelId} padding="none">
                                        <div className={classes.cell}>
                                            <IconButton
                                                aria-label="delete"
                                                size="small"
                                                className={classes.spacing}
                                                onClick={(e) => handleDeleteClick(row)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                            <span className={classes.noBreak}>
                                                {row.paddock.paddockName}
                                            </span>
                                        </div>
                                    </TableCell>
                                    <TableCell
                                        padding="none"
                                        align="left"
                                        className={classes.noBreak}
                                    >
                                        <span className={classes.spacing}>
                                            {row.begin} - {row.end}
                                        </span>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
