import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { ArrowForwardIos, Autorenew, CheckCircleRounded, ErrorOutline } from '@material-ui/icons';
import { Button, Grid, Paper } from '@material-ui/core';
import { FarmMotesAssociation, MoteAssociation } from './interfaces/FarmMotesAssociation.d';
import { DateTime } from 'luxon';
import { DATE_FORMATS, toDateOnly } from '../../../../utils/DateUtil';
import { AssociationTransferProps } from '../../../admin/motes/associations/interfaces/FarmMotesAssociation.d';
import { FarmGroup } from 'features/farm-groups/farmGroupType';
import { STATUS } from 'utils/Store/Constants';

type AddedAssociatedMotesProps = {
    farms: FarmMotesAssociation[];
    transfers: AssociationTransferProps[];
    batchTransfersLoadingStatus: string;
    batchTransfersLoading: boolean;
    onRemoveMoteClick: (moteToDelete: MoteAssociation) => void;
    onTransferClick: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(2),
        },
        table: {
            minWidth: '100%',
        },
        tableItem: {
            width: '100%',
        },
        header: {
            paddingBottom: '1rem',
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        title: {
            flex: '1 1 100%',
        },
        farmRow: {
            marginBottom: '1rem',
        },
        rowTitle: {
            '& > span': {
                marginLeft: '0.4rem',
            },
        },
        lightColor: {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    })
);

function DisplayTableHead(i: number, prevFma: FarmMotesAssociation, fma: FarmMotesAssociation) {
    const isDiffFarmFrom = prevFma?.farmFrom?.farmGroupId !== fma.farmFrom.farmGroupId;
    const isDiffFarmTo = prevFma?.farmTo?.farmGroupId !== fma.farmTo.farmGroupId;
    const isDiffDates = prevFma?.transferDate.toISODate() !== fma.transferDate.toISODate();
    return i === 0 || isDiffFarmFrom || isDiffFarmTo || isDiffDates;
}

export default function AddedAssociatedMotes({
    farms,
    onRemoveMoteClick,
    onTransferClick,
    transfers,
    batchTransfersLoadingStatus,
    batchTransfersLoading,
}: AddedAssociatedMotesProps) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const fma = farms || [];

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, fma.length - page * rowsPerPage);

    const DisplayMoteRow = (props: {
        farmGroup: FarmGroup;
        tableHeader: JSX.Element | null;
        dateTo: DateTime;
        mote: MoteAssociation;
        transferStatus?: string;
        index: number;
    }) => {
        const { mote, farmGroup, dateTo, tableHeader, transferStatus, index } = props;
        const batchTransferResult =
            batchTransfersLoadingStatus === STATUS.succeeded && transfers && transfers.length > 0
                ? transfers
                : [];
        const moteStatus = batchTransferResult.find(
            (i) =>
                i.farmGroupId === farmGroup.farmGroupId &&
                i.moteId === mote.moteId &&
                i.selectedDate === toDateOnly(dateTo)
        );
        return (
            <React.Fragment key={`item-${index}-${mote.moteId}`}>
                <TableRow>{tableHeader}</TableRow>
                <TableRow className={classes.lightColor}>
                    <TableCell>{mote.moteName ?? mote.devEui}</TableCell>
                    <TableCell align="right">
                        {transferStatus !== 'transferred' && (
                            <Tooltip title="Removed">
                                <IconButton
                                    aria-label="remove"
                                    onClick={() => onRemoveMoteClick(mote)}
                                >
                                    <DeleteIcon fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip title={moteStatus?.reason || ''}>
                            <IconButton>
                                {(moteStatus?.status === 'fulfilled' ||
                                    transferStatus === 'transferred') && (
                                    <CheckCircleRounded htmlColor="#388e3c" fontSize="medium" />
                                )}
                                {moteStatus?.status === 'rejected' && (
                                    <ErrorOutline htmlColor="#f50057" fontSize="medium" />
                                )}
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    };

    const generateMotesContent = (f: FarmMotesAssociation[]) => {
        let prevFma: FarmMotesAssociation;
        return f.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((fma, i) => {
            const displayTableHeader = DisplayTableHead(i, prevFma, fma);
            prevFma = fma;
            const tableHeader = (
                <TableCell className={classes.rowTitle}>
                    <ArrowForwardIos />
                    <Typography variant="h6" component="span" noWrap>
                        {fma.farmFrom.farmGroupName}
                    </Typography>
                    <Typography variant="h6" component="span">
                        {' '}
                        TO{' '}
                    </Typography>
                    <Typography variant="h6" component="span" noWrap>
                        {fma.farmTo.farmGroupName}
                    </Typography>
                    <Typography variant="h6" component="span">
                        {' '}
                        ON{' '}
                    </Typography>
                    <Typography variant="h6" component="span">
                        {toDateOnly(fma.transferDate, DATE_FORMATS.dayMonthYearShort)}
                    </Typography>
                </TableCell>
            );
            return (
                <DisplayMoteRow
                    key={`mote-row-${i}`}
                    farmGroup={fma.farmTo}
                    tableHeader={displayTableHeader ? tableHeader : null}
                    dateTo={fma.transferDate}
                    mote={fma.mote}
                    transferStatus={fma?.transferFlag}
                    index={i}
                ></DisplayMoteRow>
            );
        });
    };

    return (
        <>
            <Grid
                container
                className={classes.header}
                direction="row"
                justifyContent="space-between"
            >
                <Grid item>
                    <Typography
                        className={classes.title}
                        variant="h4"
                        id="tableTitle"
                        component="span"
                    >
                        Motes for transfer
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        disabled={batchTransfersLoading}
                        variant="contained"
                        size="medium"
                        onClick={onTransferClick}
                        endIcon={batchTransfersLoading && <Autorenew />}
                    >
                        Transfer
                    </Button>
                </Grid>
            </Grid>

            <TableContainer component={Paper}>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="small"
                    aria-label="enhanced table"
                >
                    <TableBody>
                        {generateMotesContent(fma)}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                labelRowsPerPage="Rows:"
                component="div"
                count={fma.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
}
