// @ts-ignore
import mapboxgl, { GeoJSONSource } from 'mapbox-gl/dist/mapbox-gl-csp';

export const MAPBOX_ACCESS_TOKEN =
    'pk.eyJ1IjoicmFtLWZhcm1vdGUiLCJhIjoiY2t2MHlwcjlvMHIxajJ2cjJveWJoM2VudSJ9.wT5EzJVsZiOV3ESRaRzUUw';

const oldSourceColors = {
    fillColor: 'rgba(94, 128, 231, 0.5)',
    fillOuterColor: 'rgba(94, 128, 231, 1)',
};

const newSourceColors = {
    fillColor: 'rgba(243, 171, 86, 0.5)',
    fillOuterColor: 'rgba(243, 171, 86, 1)',
};

const getLatLonCenterFromGeom = (coords: [number, number][]): [number, number] | null => {
    const arrAvg = (arr: number[]) => arr.reduce((a, b) => a + b, 0) / arr.length;

    const centerLat = arrAvg(coords.map((c) => c[1]));
    const centerLon = arrAvg(coords.map((c) => c[0]));

    if (isNaN(centerLat) || isNaN(centerLon)) return null;
    else return [centerLon, centerLat];
};

const lng = 171.387381927029;
const lat = -43.959121009162;
const zoom = 13;

export const loadMap = (mapContainer: any, map: any): boolean => {
    if (map.current) {
        return false;
    } // initialize map only once

    // @ts-ignore
    map.current = new mapboxgl.Map({
        // @ts-ignore
        container: mapContainer.current,
        style: 'mapbox://styles/ram-farmote/ckv8ic24p7nra15phpnmwclti',
        center: [lng, lat],
        zoom: zoom,
        scrollZoom: false,
    });

    map.current.addControl(
        new mapboxgl.NavigationControl({
            showCompass: false,
        }),
        'bottom-right'
    );

    return true;
};

const addSourceToMap = (
    map: any,
    styleLoaded: boolean,
    sourceId: string,
    geoJson: any,
    layerName: string,
    fillColorRgba: string,
    fillOutlineColorRgba: string
) => {
    if (!map.current || !styleLoaded) {
        return;
    }

    const source = map.current.getSource(sourceId) as GeoJSONSource;
    if (source) {
        return;
    }

    map.current.addSource(sourceId, {
        type: 'geojson',
        data: geoJson,
    });

    map.current.addLayer({
        id: layerName,
        type: 'fill',
        source: sourceId,
        paint: {
            'fill-color': fillColorRgba,
            'fill-outline-color': fillOutlineColorRgba,
        },
        filter: ['==', '$type', 'Polygon'],
    });

    map.current.on('click', layerName, (e: any) => {
        new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(e.features![0].properties?.ZONE_NAME ?? e.features![0].properties?.name)
            .addTo(map.current!);
    });

    map.current.on('mouseenter', layerName, () => {
        map.current!.getCanvas().style.cursor = 'pointer';
    });

    map.current.on('mouseleave', layerName, () => {
        map.current!.getCanvas().style.cursor = '';
    });

    if (geoJson && geoJson.features && geoJson.features[0]?.geometry) {
        // source.setData(geoJson);
        const center = getLatLonCenterFromGeom(geoJson.features[0].geometry.coordinates[0]);
        map.current.flyTo({
            center: center ?? map.current.getCenter(),
            zoom: 13,
        });
    }
};

export const addOldSourceToMap = (map: any, styleLoaded: boolean, geoJsonOld: any) => {
    addSourceToMap(
        map,
        styleLoaded,
        'old-source',
        geoJsonOld,
        'old-layer',
        oldSourceColors.fillColor,
        oldSourceColors.fillOuterColor
    );
};

export const addNewSourceToMap = (map: any, styleLoaded: boolean, geoJsonNew: any) => {
    addSourceToMap(
        map,
        styleLoaded,
        'new-source',
        geoJsonNew,
        'new-layer',
        newSourceColors.fillColor,
        newSourceColors.fillOuterColor
    );
};

const removeSourceFromMap = (map: any, styleLoaded: boolean, sourceId: string, layerId: string) => {
    if (!map.current || !styleLoaded) {
        return;
    }

    const source = map.current.getSource(sourceId) as GeoJSONSource;
    if (!source) {
        return;
    }

    map.current.removeLayer(layerId);
    map.current.removeSource(sourceId);
};

export const removeOldSourceFromMap = (map: any, styleLoaded: boolean) =>
    removeSourceFromMap(map, styleLoaded, 'old-source', 'old-layer');
export const removeNewSourceFromMap = (map: any, styleLoaded: boolean) =>
    removeSourceFromMap(map, styleLoaded, 'new-source', 'new-layer');
