import React, { useEffect, useState } from 'react';
import Page from '../../../components/Page';
import Container from '@material-ui/core/Container';
import Downloader from './scenes/Downloader';
import Scenes from './scenes';
import { a11yProps, TabPanel } from '../../../components/TabPanel';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Batches from './batches';
import { StyledTab, StyledTabs } from '../../../components/StyledTabs';
import { useParams } from 'react-router-dom';
import Regression from './regression';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectBatchId, selectedBatchId, showRegression } from './batches/batchesSlice';

export default function Tracker() {
    const { batchId } = useParams<any>();
    const dispatch = useAppDispatch();

    const [selectedTab, setSelectedTab] = useState(batchId ? 2 : 0);
    const selectedBId = useAppSelector(selectedBatchId);
    const showRegressionTab = useAppSelector(showRegression);

    useEffect(() => {
        if (showRegressionTab) {
            setSelectedTab(2);
        }
    }, [showRegressionTab]);

    return (
        <Page title="NDVI Tracker">
            <Box>
                <StyledTabs
                    value={selectedTab}
                    onChange={(_, newTab) => {
                        setSelectedTab(newTab);
                        dispatch(selectBatchId(null));
                    }}
                    aria-label="tracker tabs"
                >
                    <StyledTab label="Scenes" {...a11yProps(0)} />
                    <StyledTab label="Batches" {...a11yProps(1)} />
                    <StyledTab label="Regression" {...a11yProps(2)} />
                </StyledTabs>
            </Box>
            <Divider />
            <Container maxWidth="md">
                <TabPanel value={selectedTab} index={0}>
                    <Downloader />
                    <Scenes />
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    <Batches />
                </TabPanel>
                <TabPanel value={selectedTab} index={2}>
                    <Regression batchId={batchId ?? selectedBId} />
                </TabPanel>
            </Container>
        </Page>
    );
}
