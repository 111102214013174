import { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { LinearProgress, Divider, Hidden, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import clsx from 'clsx';
import DateTabs from 'components/DateTabs';
import Page from 'components/Page';
import Filters from 'features/measurements/filters';
import FiltersSummary from 'features/measurements/filters/FiltersSummary';
import { shallowEqual } from 'react-redux';
import { useFirebaseConnect } from 'react-redux-firebase';
import { fromStrISOToDateTime, toBrowserLocal } from 'utils/DateUtil';
import ContextActions from '../ContextActions';
import {
    selectAllMeasurements,
    fetchParkMeasurements,
    fetchDownloadParkMeasurements,
} from '../reducers/parkMeasurementsSlice';
import SingleDayMeasurementsForPark from '../components/SingleDayMeasurementsForParks';
import Indigo from '@material-ui/core/colors/indigo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        disable: {
            opacity: 0.4,
            pointerEvents: 'none',
        },
        filters: {
            backgroundColor: Indigo[50],
            position: 'relative',
        },
        measurementsWrapper: {
            margin: theme.spacing(1),
            marginTop: theme.spacing(2),
        },
        measurements: {
            maxWidth: '60rem',
            margin: 'auto auto',
        },
    })
);

export default function ParkMeasurements() {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const filters = useAppSelector((state) => state.filters, shallowEqual);
    const measurements = useAppSelector(selectAllMeasurements);
    const { isMoveSuccessfulOrIdle, isFetchingMeasurements, isFetchFailed } = useAppSelector(
        (state) => {
            return {
                isMoveSuccessfulOrIdle:
                    state.paddockGroups.moveStatus.status === 'succeeded' ||
                    state.paddockGroups.moveStatus.status === 'idle',
                isFetchingMeasurements: state.paddockMeasurements.fetchStatus === 'loading',
                isFetchFailed: state.paddockMeasurements.fetchStatus === 'failed',
            };
        },
        shallowEqual
    );

    const [showFilters, setShowFilters] = useState(true);
    const [editClicked, setEditClicked] = useState(false);

    const handleFiltersEdit = () => setEditClicked(true);

    const handleApply = () => {
        setShowFilters(false);
        setEditClicked(false);
    };

    const handleCancel = () => {
        setShowFilters(false);
        setEditClicked(false);
    };

    useFirebaseConnect(`processes/status/pph/${filters.farm.farmId}`);

    useEffect(() => {
        let farmId = filters?.farm?.farmId;
        if (farmId && farmId !== 0 && isMoveSuccessfulOrIdle && !editClicked) {
            setShowFilters(false);
            dispatch(fetchParkMeasurements(filters));
        }
    }, [filters, dispatch, isMoveSuccessfulOrIdle, editClicked]);

    const [selectedDate, setSelectedDate] = useState<Date>();
    useEffect(() => {
        if (measurements && measurements.length > 0) {
            const measurementDate = measurements[measurements.length - 1]?.date;
            if (measurementDate) setSelectedDate(toBrowserLocal(measurementDate));
        }
    }, [measurements]);

    const handleDateChange = (date: Date) => setSelectedDate(date);

    const handleDownloadClick = () => dispatch(fetchDownloadParkMeasurements(filters));

    return (
        <Page
            title="Dashboard"
            actionIcons={<ContextActions handleDownloadClick={handleDownloadClick} />}
        >
            <div className={classes.root}>
                {isFetchFailed && (
                    <Alert severity="error">
                        Sorry something went wrong! Please reload the page.
                    </Alert>
                )}
                {isFetchingMeasurements && (
                    <Alert severity="info">Getting your Pasture data...</Alert>
                )}
                {isFetchingMeasurements && <LinearProgress />}
                <div className={clsx({ [classes.disable]: isFetchingMeasurements })}>
                    <div className={classes.filters}>
                        {showFilters || editClicked ? (
                            <Filters onApply={handleApply} onCancel={handleCancel} />
                        ) : (
                            <FiltersSummary
                                from={fromStrISOToDateTime(filters.from)}
                                to={fromStrISOToDateTime(filters.to)}
                                appliedTo={filters.farm?.farmName}
                                timeZone={filters.timeZone}
                                onEdit={handleFiltersEdit}
                            />
                        )}
                    </div>
                    <Divider />
                    {measurements && measurements.length > 0 && (
                        <>
                            <div className={classes.measurementsWrapper}>
                                <div className={classes.measurements}>
                                    <Hidden xsDown implementation="css">
                                        <ContextActions handleDownloadClick={handleDownloadClick} />
                                    </Hidden>
                                    <Paper>
                                        <DateTabs
                                            dates={measurements.map((m) => new Date(m.date))}
                                            datesWithCameraIcons={[]}
                                            cameraIconClicable={false}
                                            onDateChange={(d) => handleDateChange(d)}
                                            onCameraIconClick={() => {}}
                                        />
                                        <Divider />
                                        <SingleDayMeasurementsForPark forDate={selectedDate!} />
                                    </Paper>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Page>
    );
}
