import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { MoveHistoryModel } from '../paddockMoveHistoryType';
import _map from 'lodash/map';
import TableRowContent from './TableRowContent';
import AlertDialog from 'components/AlertDialog';
import { displayDateFormat } from 'utils/DateUtil';
import { Str } from 'utils/StringConstants';
import { DateTime } from 'luxon';

const useTableStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 450,
        },
        text: {
            fontSize: '0.8rem',
            color: theme.palette.secondary.main,
        },
    })
);

const defaultPaddockMove: MoveHistoryModel = {
    eventId: Str.empty,
    eventTime: Str.empty,
    paddockId: Str.empty,
    paddockGroupId: Str.empty,
    dateMoved: Str.empty,
    dateMovedDt: DateTime.now(),
    groupName: Str.empty,
    previousGroupName: Str.empty,
    paddockName: Str.empty,
    user: Str.empty,
};

export type TableProps = {
    rows: MoveHistoryModel[];
    onConfirmItemDeletehandler: (event: React.MouseEvent<unknown>, model: MoveHistoryModel) => void;
};

export default function TableContent(props: TableProps) {
    const classes = useTableStyles();
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<MoveHistoryModel>(defaultPaddockMove);
    const { rows, onConfirmItemDeletehandler } = props;

    if (!rows.length) {
        return <></>;
    }

    const onRowClickHandler = (event: React.MouseEvent<unknown>, model: MoveHistoryModel) => {
        setSelected(model);
    };

    const onIconDeleteHandler = (e: React.MouseEvent<any>, model: MoveHistoryModel) => {
        setOpen((v) => true);
        e.stopPropagation();
    };

    const isSimilarSelected = (p: MoveHistoryModel) =>
        selected.paddockId === p.paddockId && p.dateMovedDt >= selected.dateMovedDt;

    const isSelected = (p: MoveHistoryModel) => selected.eventId === p.eventId;

    const modalMessage = (row: MoveHistoryModel): JSX.Element => {
        const list = _map(rows, (r, i) => {
            if (!isSimilarSelected(r)) return;
            return userFriendlyMessage(r, i.toString());
        });
        return <>{list}</>;
    };

    const userFriendlyMessage = (row: MoveHistoryModel, idx: string): JSX.Element => {
        return (
            <Box flex={1} className={classes.text} key={`msg-item-${idx}`}>
                {`${displayDateFormat(row.dateMoved)} `}
                <strong>{`${row.paddockName} - `}</strong>
                <strong>{row.previousGroupName}</strong>
                {' to '}
                <strong>{`${row.groupName}`}</strong>
            </Box>
        );
    };

    return (
        <TableContainer>
            <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
                id="table-container"
            >
                <AlertDialog
                    title="The following paddock move(s) will be deleted"
                    isOpen={open}
                    onClose={() => setOpen(false)}
                    children={selected && modalMessage(selected)}
                    onClickConfirm={(e) => onConfirmItemDeletehandler(e, selected!)}
                ></AlertDialog>
                <TableBody>
                    {_map(rows, (row, idx) => {
                        return (
                            <TableRowContent
                                row={row}
                                key={idx}
                                hasHighlight={isSimilarSelected(row)}
                                displayDeleteButton={isSelected(row)}
                                userFriendlyMessage={userFriendlyMessage}
                                onRowClickHandler={onRowClickHandler}
                                onIconDeleteHandler={onIconDeleteHandler}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
