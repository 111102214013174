import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import timeZonesData from './timeZones.json';
import { FormControlLabel, IconButton, TextField, Typography } from '@material-ui/core';
import { getEpsg } from './epsgMapper';
import DateSelector from 'components/DateSelector';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { OnboardFarmRequest } from './onboardTypes';
import { fetchFarmsByFarmGroup } from 'features/farms/farmsSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import FarmCollectiveAutoComplete from 'features/farm-collectives/FarmCollectivesAutoComplete';
import { FarmCollective } from 'features/farm-collectives/farmCollectivesType';
import { Farm } from 'features/farms/farmType';
import LoadingButton from 'components/LoadingButton';
import FarmGroupsAutoComplete from 'features/farm-groups/FarmGroupsAutoComplete';
import { FarmGroup } from 'features/farm-groups/farmGroupType';
import { fromStrISOToDateTime } from 'utils/DateUtil';
import { Str } from 'utils/StringConstants';
import FarmsAutoCompleteAdmin from './FarmsAutoCompleteAdmin';
import ManageHouseDetailsResponse from './ManageHouseDetailsResponse';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { onboaringNewFarm } from './onboardApi';
import { addNotification } from 'features/notifications/notificationsSlice';
import AlertDialog from 'components/AlertDialog';
import { DateTime } from 'luxon';

const defaultOnboardedOn = fromStrISOToDateTime(
    new Date().toISOString(),
    DateTime.local().zoneName
).toISO();

const defaultValues: OnboardFarmRequest = {
    farmId: 0,
    farmName: Str.empty,
    farmAddress: Str.empty,
    isNet: true,
    farmGroupName: Str.empty,
    farmGroupId: 0,
    farmCollectiveName: Str.empty,
    farmCollectiveId: 0,
    timeZone: DateTime.local().zoneName,
    epsg: null,
    onboardedOn: defaultOnboardedOn,
};

export default function ManageFarm() {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState<boolean>(false);
    const [timeZones] = useState<string[]>(timeZonesData);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [refreshOnboard, setRefreshOnboard] = useState<boolean>(false);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [selectedFarm, setSelectedFarm] = useState<Farm | null>(null);
    const [values, setValues] = useState<OnboardFarmRequest>(defaultValues);
    const [showFarmEditInput, setShowFarmEditInput] = useState<boolean>(false);
    const [selectedFarmGroup, setSelectedFarmGroup] = useState<FarmGroup | null>(null);
    const [selectedFarmCollective, setSelectedFarmCollective] = useState<FarmCollective | null>(
        null
    );
    const farmGroupId = useAppSelector((state) => (state.firebase as any).profile?.farmGroupId);

    useEffect(() => {
        if (farmGroupId) {
            dispatch(fetchFarmsByFarmGroup(farmGroupId));
        }
    }, [dispatch, farmGroupId]);

    const setSelectedFarmHandler = (farm: Farm) => {
        const data = {
            ...values,
            farmId: farm.farmId,
            farmName: farm.farmName,
            farmAddress: farm.farmAddress,
            farmGroupId: farm.farmGroupId,
            farmGroupName: farm.farmGroupName || Str.empty,
            farmCollectiveName: farm.farmCollectiveName || Str.empty,
            farmCollectiveId: farm.farmCollectiveId || 0,
            timeZone: farm.timeZone,
            epsg: farm.epsg,
            onbordedOn: farm.startDate
                ? fromStrISOToDateTime(farm.startDate, farm.timeZone).toISO()
                : defaultOnboardedOn,
        };

        setSelectedFarmCollective({
            farmCollectiveId: farm.farmCollectiveId!,
            farmCollectiveName: farm.farmCollectiveName!,
            epsg: null,
        });
        setSelectedFarmGroup({
            farmGroupId: farm.farmGroupId!,
            farmGroupName: farm.farmGroupName!,
        });
        setValues(data);
        setSelectedFarm(farm);
        setRefreshOnboard(true);
    };

    const setSelectedFarmGroupHandler = (value: FarmGroup) => {
        setValues({
            ...values,
            farmGroupId: value.farmGroupId,
            farmGroupName: value.farmGroupName,
        });
        setSelectedFarmGroup(value);
    };

    const setFarmCollectiveHandler = (value: FarmCollective) => {
        setValues({
            ...values,
            farmCollectiveId: value.farmCollectiveId,
            farmCollectiveName: value.farmCollectiveName,
        });
        setSelectedFarmCollective(value);
    };

    const onboardingFarmHandler = () => {
        const req: OnboardFarmRequest = {
            farmId: values.farmId,
            farmName: values.farmName,
            farmAddress: values.farmAddress,
            isNet: values.isNet,
            farmGroupId: values.farmGroupId,
            farmGroupName: values.farmGroupName,
            farmCollectiveId: values.farmCollectiveId,
            farmCollectiveName: values.farmCollectiveName,
            timeZone: values.timeZone,
            epsg: values.epsg,
            onboardedOn: values.onboardedOn,
        };

        setSaveLoading(true);

        onboaringNewFarm(req)
            .then((d) => {
                setRefresh(true);
                clearFields();
                dispatch(addNotification({ message: 'Success', severity: 'success' }));
            })
            .catch((err) => {
                dispatch(
                    addNotification({
                        message: 'We were unable to process your request',
                        severity: 'error',
                    })
                );
            })
            .finally(() => setSaveLoading(false));
    };

    const onClickSavehandler = () => {
        if (!checkRequiredFields()) {
            return;
        }

        // Get confirmation from user if he is happy changing collective and the implications of it
        if (
            selectedFarm &&
            selectedFarm.farmId > 0 &&
            selectedFarm.farmCollectiveId !== values.farmCollectiveId
        ) {
            setOpen(true);
            return;
        }

        onboardingFarmHandler();
    };

    const clearFields = () => {
        setShowFarmEditInput((v) => false);
        setValues(defaultValues);
        setSelectedFarm(null);
        setRefreshOnboard(true);
        setSelectedFarmGroup(null);
        setSelectedFarmCollective(null);
    };

    const onIconEditHandler = () => {
        setShowFarmEditInput((v) => true);
    };

    const onIconCloseHandler = () => {
        setShowFarmEditInput((v) => false);
    };

    const modalContent = () => {
        return (
            <Box>
                <Typography color="inherit" variant="subtitle1" component="div">
                    The PPH Processor will need to be manually triggered for the specified farm and
                    onboard date
                    <ul>
                        <li>Farm: {values.farmName}</li>
                        <li>Date: {values.onboardedOn}</li>
                        <li>New: {values.farmCollectiveName}</li>
                    </ul>
                </Typography>
            </Box>
        );
    };

    const checkRequiredFields = (): boolean => {
        return (
            !!values.farmName &&
            values.farmName?.trim() !== Str.empty &&
            !!values.farmAddress &&
            values.farmAddress?.trim() !== Str.empty &&
            !!values.timeZone &&
            values.timeZone?.trim() !== Str.empty &&
            (Number(values.farmCollectiveId) > 0 ||
                values.farmCollectiveName?.trim() !== Str.empty) &&
            (Number(values.farmGroupId) > 0 || values.farmGroupName?.trim() !== Str.empty)
        );
    };

    const isFormValid = checkRequiredFields();

    return (
        <>
            <AlertDialog
                title="Farm collective change"
                isOpen={open}
                onClose={() => setOpen(false)}
                children={modalContent()}
                onClickConfirm={(e) => onboardingFarmHandler()}
            ></AlertDialog>
            <Box
                display="flex"
                flexDirection="row"
                gridColumnGap={16}
                gridRowGap={16}
                flexWrap="wrap"
                alignItems="center"
                padding={2}
            >
                {showFarmEditInput && (
                    <TextField
                        label="Edit farm name"
                        placeholder="Farm"
                        value={values.farmName}
                        variant="outlined"
                        onChange={(e) => setValues({ ...values, farmName: e.target.value })}
                        style={{ width: false ? '8ch' : '24ch' }}
                    />
                )}

                {!showFarmEditInput && (
                    <FarmsAutoCompleteAdmin
                        allowAdd
                        refresh={refresh}
                        onSelect={setSelectedFarmHandler}
                        preSelectedFarm={selectedFarm || null}
                    />
                )}

                {selectedFarm && !!selectedFarm.farmId && (
                    <>
                        <IconButton
                            aria-label="edit"
                            size="small"
                            placeholder="Edit"
                            onClick={onIconEditHandler}
                        >
                            <EditIcon />
                        </IconButton>

                        {showFarmEditInput && (
                            <IconButton
                                aria-label="close"
                                size="small"
                                placeholder="Close"
                                onClick={onIconCloseHandler}
                            >
                                <CloseIcon />
                            </IconButton>
                        )}
                    </>
                )}
            </Box>
            <Box
                display="flex"
                flexDirection="row"
                gridColumnGap={16}
                gridRowGap={16}
                flexWrap="wrap"
                alignItems="center"
                padding={2}
            >
                <TextField
                    label="Address"
                    placeholder="Address"
                    value={values.farmAddress}
                    variant="outlined"
                    onChange={(e) => setValues({ ...values, farmAddress: e.target.value })}
                    style={{ width: false ? '8ch' : '24ch' }}
                />

                <FarmGroupsAutoComplete
                    allowAdd
                    refresh={refresh}
                    onSelect={setSelectedFarmGroupHandler}
                    preSelectedFarmGroup={selectedFarmGroup || null}
                />

                <FarmCollectiveAutoComplete
                    allowAdd
                    refresh={refresh}
                    onSelect={setFarmCollectiveHandler}
                    preSelectedValue={selectedFarmCollective || null}
                />

                <Autocomplete
                    id="time-zones"
                    options={timeZones}
                    getOptionSelected={(a, b) => a === b}
                    getOptionLabel={(option: any) => option || Str.empty}
                    renderInput={(params) => (
                        <TextField {...params} label="Time Zone" variant="outlined" />
                    )}
                    value={values.timeZone}
                    onChange={(event: any, newValue: any) => {
                        setValues((v) => ({ ...v, timeZone: newValue, epsg: getEpsg(newValue) }));
                    }}
                    style={{ width: '20ch' }}
                    disableClearable
                />

                <DateSelector
                    label="Onboarded on"
                    onChange={(value: Date) => {
                        setValues({
                            ...values,
                            onboardedOn: fromStrISOToDateTime(
                                value.toISOString(),
                                values.timeZone
                            ).toISO(),
                        });
                    }}
                    date={new Date(values.onboardedOn)}
                    reset={refreshOnboard}
                    onAfterReset={() => setRefreshOnboard((v) => true)}
                />
            </Box>
            <Box
                display="flex"
                flexDirection="row"
                gridColumnGap={16}
                gridRowGap={16}
                flexWrap="wrap"
                alignItems="center"
                padding={2}
            >
                {!values.farmId && (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={values.isNet}
                                onChange={() => setValues({ ...values, isNet: !values.isNet })}
                                name="is-net"
                            />
                        }
                        label={values.isNet ? 'Net' : 'Gross'}
                    />
                )}

                <Button variant="contained" color="secondary" onClick={clearFields}>
                    Clear
                </Button>

                <LoadingButton label="Save" size="medium" loading={saveLoading}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onClickSavehandler}
                        disabled={!isFormValid}
                    >
                        Save
                    </Button>
                </LoadingButton>
            </Box>

            <ManageHouseDetailsResponse farmId={selectedFarm?.farmId || 0} />
        </>
    );
}
