import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

type Props = {
    title: string;
    isOpen: boolean;
    onClose: () => void;
    children?: React.ReactNode;
    onClickConfirm: (event: React.MouseEvent<unknown>) => void;
};

export default function AlertDialog(props: Props) {
    const { title, isOpen, children, onClose, onClickConfirm } = props;

    const onClickConfirmHandler = (e: React.MouseEvent<unknown>) => {
        onClickConfirm(e);
        onClose();
    };

    return (
        <Dialog
            maxWidth="md"
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" variant="contained" size="small">
                    Cancel
                </Button>
                <Button
                    onClick={onClickConfirmHandler}
                    color="primary"
                    autoFocus
                    variant="contained"
                    size="small"
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
