import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxContainer: {
            minHeight: theme.spacing(6),
            paddingTop: theme.spacing(4),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    })
);

export type Props = {
    description: string;
};

export function BoxCenter({ description }: Props) {
    const classes = useStyles();

    return (
        <Box className={classes.boxContainer}>
            <Typography variant="caption">{description}</Typography>
        </Box>
    );
}
