import React from 'react';
import { RegressionInput } from './RegressionInput';
import Box from '@material-ui/core/Box';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from 'plotly.js-basic-dist';
import Divider from '@material-ui/core/Divider';
import { useAppSelector } from '../../../../app/hooks';
import { shallowEqual } from 'react-redux';

const chart = (
    type: string,
    fnMoteMean: (i: RegressionInput) => number,
    input: RegressionInput[],
    fnFormula: (x: number) => number,
    formula: string
) => {
    const Plot = createPlotlyComponent(Plotly);

    const excluded = input.filter((d) => d.excluded);
    const included = input.filter((d) => !d.excluded);

    const excludedXs = excluded.map(fnMoteMean);
    const excludedYs = excluded.map((d) => d.hapgr);

    const includedXs = included.map(fnMoteMean);
    const includedYs = included.map((d) => d.hapgr);

    const chartData = {
        scatter: {
            excludedXs: excludedXs,
            excludedYs: excludedYs,
            includedXs: includedXs,
            includedYs: includedYs,
            text: `${type} Regression Input`,
        },
        bestFitLine: {
            xs: input.map(fnMoteMean),
            ys: input.map(fnMoteMean).map((x) => fnFormula(x)),
        },
    };

    const excludedColor = 'rgb(219, 64, 82)';
    const includedColor = 'rgb(55, 128, 191)';

    return (
        <Box>
            <Plot
                data={[
                    {
                        type: 'scatter',
                        mode: 'markers',
                        name: 'Included',
                        x: chartData.scatter.excludedXs,
                        y: chartData.scatter.excludedYs,
                        // trendline: 'ols',
                        text: chartData.scatter.text,
                        textposition: 'auto',
                        marker: {
                            color: excludedColor,
                            size: 6,
                        },
                    },
                    {
                        type: 'scatter',
                        mode: 'markers',
                        name: 'Excluded',
                        x: chartData.scatter.includedXs,
                        y: chartData.scatter.includedYs,
                        // trendline: 'ols',
                        text: chartData.scatter.text,
                        textposition: 'auto',
                        marker: {
                            color: includedColor,
                            size: 6,
                        },
                    },
                    {
                        mode: 'lines',
                        x: chartData.bestFitLine.xs,
                        y: chartData.bestFitLine.ys,
                        text: formula,
                        line: {
                            color: includedColor,
                            width: 2,
                        },
                    },
                ]}
                layout={{
                    title: `${type} Regression Input`,
                    showlegend: false,
                    xaxis: { title: `Mean Mote ${type}` },
                    yaxis: { title: 'HAPGR' },
                    dragmode: 'pan',
                }}
                config={{
                    modeBarButtonsToRemove: ['lasso2d', 'select2d'],
                }}
            />
        </Box>
    );
};

type InputGraphProps = {
    input: RegressionInput[];
};

export default function InputGraph({ input }: InputGraphProps) {
    const { formulas } = useAppSelector((state) => {
        return {
            formulas: state.regression.formulas,
        };
    }, shallowEqual);

    const formulaText = (formula: any) => `${formula.m} * x + ${formula.c}`;

    return (
        <Box display="flex" flexDirection="column" gridColumnGap={16}>
            {formulas[2] &&
                chart(
                    'WDRRE',
                    (i) => i.mean_mote_wdrre,
                    input,
                    (x) => formulas[2].m * x + formulas[2].c,
                    formulaText(formulas[2])
                )}
            <Divider />
            {chart(
                'NDRE',
                (i) => i.mean_mote_ndre,
                input,
                (x) => formulas[1].m * x + formulas[1].c,
                formulaText(formulas[1])
            )}
            <Divider />
            {chart(
                'NDVI',
                (i) => i.mean_mote_ndvi,
                input,
                (x) => formulas[0].m * x + formulas[0].c,
                formulaText(formulas[0])
            )}
        </Box>
    );
}
