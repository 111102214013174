import React from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { useFirestoreConnect } from 'react-redux-firebase';
import BatchesDataGrid from './BatchesDataGrid';

export default function ArchivedBatches() {
    useFirestoreConnect([
        {
            collection: 'ndvi-process-batch',
            storeAs: 'batches',
            orderBy: ['status.DELETED', 'desc'],
            startAt: null,
        },
    ]);
    const batches = useAppSelector((state) => (state.firestore as any).data?.batches);

    return <BatchesDataGrid batches={batches} enableSelection={false} />;
}
