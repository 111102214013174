import { IconButton, createStyles, makeStyles, Paper, Popover, Theme } from '@material-ui/core';
import { Notifications } from '@material-ui/icons';
import { useState } from 'react';
import { ActivityLogActionMenuContent } from './ActivityLogActionMenuContent';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            paddingBottom: theme.spacing(2),
            maxWidth: 450,
            width: 450,
        },
    })
);

export const ActivityLogActionMenu = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = !!anchorEl;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                <Notifications />
            </IconButton>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Paper className={classes.paper}>
                    <ActivityLogActionMenuContent />
                </Paper>
            </Popover>
        </div>
    );
};
