import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import clsx from 'clsx';
import Hidden from '@material-ui/core/Hidden';
import CameraIcon from '@material-ui/icons/PhotoCamera';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(1),
            paddingBottom: 0,
        },
        active: {
            color: theme.palette.primary.main,
        },
        date: {
            display: 'flex',
        },
    })
);

type DateTabsProps = {
    dates: Date[];
    datesWithCameraIcons?: Date[];
    cameraIconClicable?: boolean;
    onDateChange: (date: Date) => void;
    onCameraIconClick?: (date: Date) => void;
};

export default function DateTabs({
    dates,
    datesWithCameraIcons,
    cameraIconClicable,
    onDateChange,
    onCameraIconClick,
}: DateTabsProps) {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = React.useState(dates.length - 1);

    const handleDateChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedIndex(newValue);
        onDateChange(dates[newValue]);
    };

    const hasCameraInformationFor = (d: Date) => {
        return (
            datesWithCameraIcons &&
            datesWithCameraIcons?.some((c) => c.toISOString() === d.toISOString())
        );
    };

    const cameraIcon = (d: Date) =>
        cameraIconClicable ? (
            <CameraButton onClick={() => onCameraIconClick && onCameraIconClick(d)} />
        ) : (
            <CameraIcon />
        );

    return (
        <div className={classes.root}>
            <Tabs
                value={selectedIndex}
                onChange={handleDateChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable dates"
            >
                {dates.map((d, i) => (
                    <Tab
                        key={d.toString()}
                        label={
                            <div
                                className={clsx(
                                    classes.date,
                                    dates[selectedIndex] === d && classes.active
                                )}
                            >
                                {hasCameraInformationFor(d) && cameraIcon(d)}
                                <Hidden xsDown implementation="css">
                                    <Typography variant="h5">
                                        <strong>{DateTime.fromJSDate(d).day}</strong>
                                    </Typography>
                                    <Typography variant="body1">
                                        {DateTime.fromJSDate(d).weekdayShort}
                                    </Typography>
                                    <Typography variant="button">
                                        {DateTime.fromJSDate(d).monthShort}{' '}
                                        {DateTime.fromJSDate(d).year}
                                    </Typography>
                                </Hidden>
                                <Hidden smUp implementation="css">
                                    <Typography variant="button">
                                        <strong>
                                            {DateTime.fromJSDate(d).weekdayShort},{' '}
                                            {DateTime.fromJSDate(d).day}{' '}
                                            {DateTime.fromJSDate(d).monthShort}{' '}
                                            {DateTime.fromJSDate(d).year.toString().substr(-2)}
                                        </strong>
                                    </Typography>
                                </Hidden>
                            </div>
                        }
                    />
                ))}
            </Tabs>
        </div>
    );
}

type CameraButtonProps = {
    onClick?: () => void;
};

const CameraButton = ({ onClick }: CameraButtonProps) => {
    const [cursor, setCursor] = useState('zoom-in');

    const handleClick = (e: React.MouseEvent<SVGSVGElement>) => {
        if (!onClick) return;

        e.stopPropagation();
        setCursor('wait');
        setTimeout(() => setCursor('zoom-in'), 3000);

        onClick();
    };

    return <CameraIcon onClick={(e) => handleClick(e)} style={{ cursor: cursor }} />;
};
