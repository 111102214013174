import React from 'react';
import SearchPanel from './SearchPanel';
import InfoPanel from './InfoPanel';
import Data from './Data';

type RegressionProps = {
    batchId: string;
};

export default function Regression({ batchId }: RegressionProps) {
    return (
        <div>
            <SearchPanel batchId={batchId} />
            <InfoPanel batchId={batchId} />
            <Data batchId={batchId} />
        </div>
    );
}
