import { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import Filters from '../../../measurements/filters';
import {
    fetchPaddockMeasurements,
    selectAllMeasurements,
    selectActiveZoneGroupGrowths,
    fetchDownloadPaddockMeasurements,
} from '../reducers/paddockMeasurementsSlice';
import SingleDayMeasurements from './SingleDayMeasurements';
import DateTabs from '../../../../components/DateTabs';
import Divider from '@material-ui/core/Divider';
import FiltersSummary from '../../../measurements/filters/FiltersSummary';
import Indigo from '@material-ui/core/colors/indigo';
import { LinearProgress, Paper } from '@material-ui/core';
import MultiFeedWedges from './Graph/MultiFeedWedges';
import PaddockActions from '../../actions';
import { useFirebaseConnect } from 'react-redux-firebase';
import clsx from 'clsx';
import Alert from '@material-ui/lab/Alert';
import PaddockTargets from '../../PaddockTargets';
import { shallowEqual } from 'react-redux';
import Page from '../../../../components/Page';
import Hidden from '@material-ui/core/Hidden';
import SingleDayMeasurementsMobile from './mobile/SingleDayMeasurementsMobile';
import ContextActions from '../ContextActions';
import PaddockUnits from '../../PaddockUnits';
import { DATE_FORMATS, fromStrISOToDateTime, toBrowserLocal } from '../../../../utils/DateUtil';
import { addNotification } from '../../../notifications/notificationsSlice';
import { getFarmVegetationIndexImage } from '../api/vegetationIndexImageApi';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { VegetationIndexImageDialog } from '../../../../components/VegetationIndexImageDialog';
import { fetchPaddockGroups } from 'features/paddock-groups/paddockGroupsSlice';
import { fetchPaddocks } from '../../paddocksSlice';
import { DateTime } from 'luxon';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        disable: {
            opacity: 0.4,
            pointerEvents: 'none',
        },
        filters: {
            backgroundColor: Indigo[50],
            position: 'relative',
        },
        measurementsWrapper: {
            margin: theme.spacing(1),
            marginTop: theme.spacing(2),
        },
        measurements: {
            maxWidth: '60rem',
            margin: 'auto auto',
        },
    })
);

export default function PaddockMeasurements() {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { portalSatImageDialogEnabled } = useFlags();

    const filters = useAppSelector((state) => state.filters, shallowEqual);
    const measurements = useAppSelector(selectAllMeasurements);
    const { isMoveSuccessfulOrIdle, isFetchingMeasurements, isFetchFailed } = useAppSelector(
        (state) => {
            return {
                isMoveSuccessfulOrIdle:
                    state.paddockGroups.moveStatus.status === 'succeeded' ||
                    state.paddockGroups.moveStatus.status === 'idle',
                isFetchingMeasurements: state.paddockMeasurements.fetchStatus === 'loading',
                isFetchFailed: state.paddockMeasurements.fetchStatus === 'failed',
            };
        },
        shallowEqual
    );

    const [showFilters, setShowFilters] = useState(true);
    const [editClicked, setEditClicked] = useState(false);
    const [vegetationIndexState, setVegetationIndexState] = useState<{
        location: string;
        date: Date;
    }>();
    const handleFiltersEdit = () => setEditClicked(true);

    const handleApply = () => {
        setShowFilters(false);
        setEditClicked(false);
    };

    const handleCancel = () => {
        setShowFilters(false);
        setEditClicked(false);
    };

    useFirebaseConnect(`processes/status/pph/${filters.farm.farmId}`);

    useEffect(() => {
        let farmId = filters?.farm?.farmId;
        if (farmId && farmId !== 0 && isMoveSuccessfulOrIdle && !editClicked) {
            setShowFilters(false);
            dispatch(fetchPaddockMeasurements(filters));
            dispatch(fetchPaddockGroups(filters.farm.farmId));
            const searchParams = {
                farmId: filters.farm.farmId,
                farmLocalTimeZone: filters.farm.timeZone,
                from: new Date(),
                to: new Date(),
            };
            dispatch(fetchPaddocks(searchParams));
        }
    }, [filters, dispatch, isMoveSuccessfulOrIdle, editClicked]);

    const [selectedDate, setSelectedDate] = useState<Date>();
    useEffect(() => {
        if (measurements && measurements.length > 0) {
            const measurementDate = measurements[measurements.length - 1]?.date;
            if (measurementDate) setSelectedDate(toBrowserLocal(measurementDate));
        }
    }, [measurements]);

    const growths = useAppSelector((state) => selectActiveZoneGroupGrowths(state, selectedDate));

    const handleDateChange = (date: Date) => setSelectedDate(date);

    const [showGraph, setShowGraph] = useState<boolean>(false);
    const toggleGraphClick = () => setShowGraph(!showGraph);

    const handleDownloadClick = () => dispatch(fetchDownloadPaddockMeasurements(filters));

    const [showTargets, setShowTargets] = useState(false);
    const handleTargetClick = () => setShowTargets(!showTargets);

    const [showUnits, setShowUnits] = useState(false);
    const handleUnitsClick = () => setShowUnits(!showUnits);

    const handleCameraIconClick = async (date: Date) => {
        const farmId = filters?.farm?.farmId;
        const image = await getFarmVegetationIndexImage(farmId, date.toISOString());

        if (!image.exists || !image.location) {
            dispatch(
                addNotification({
                    message: 'Sorry, the satellite image is not available for this date.',
                    severity: 'warning',
                })
            );
            return;
        }

        setVegetationIndexState({ location: image.location, date });
    };

    const closeVegetationIndexImageDialog = () => setVegetationIndexState(undefined);

    return (
        <Page
            title="Dashboard"
            actionIcons={
                <ContextActions
                    handleDownloadClick={handleDownloadClick}
                    handleTargetClick={handleTargetClick}
                    handleUnitsClick={handleUnitsClick}
                    toggleGraphClick={toggleGraphClick}
                    showGraph={showGraph}
                    showTargetsIcon
                    showUnitsIcon
                    showGraphIcon
                />
            }
        >
            <div className={classes.root}>
                {isFetchFailed && (
                    <Alert severity="error">
                        Sorry something went wrong! Please reload the page.
                    </Alert>
                )}
                {isFetchingMeasurements && (
                    <Alert severity="info">Getting your Pasture data...</Alert>
                )}
                {isFetchingMeasurements && <LinearProgress />}
                <div className={clsx({ [classes.disable]: isFetchingMeasurements })}>
                    <div className={classes.filters}>
                        {showFilters || editClicked ? (
                            <Filters onApply={handleApply} onCancel={handleCancel} />
                        ) : (
                            <FiltersSummary
                                from={fromStrISOToDateTime(filters.from)}
                                to={fromStrISOToDateTime(filters.to)}
                                appliedTo={filters.farm?.farmName}
                                timeZone={filters.timeZone}
                                onEdit={handleFiltersEdit}
                            />
                        )}
                    </div>
                    <Divider />
                    {measurements && measurements.length > 0 && (
                        <>
                            <div className={classes.measurementsWrapper}>
                                <div className={classes.measurements}>
                                    <Hidden xsDown implementation="css">
                                        <ContextActions
                                            handleDownloadClick={handleDownloadClick}
                                            handleTargetClick={handleTargetClick}
                                            handleUnitsClick={handleUnitsClick}
                                            toggleGraphClick={toggleGraphClick}
                                            showGraph={showGraph}
                                            showTargetsIcon
                                            showUnitsIcon
                                            showGraphIcon
                                        />
                                    </Hidden>
                                    <PaddockActions />
                                    <PaddockTargets
                                        openDrawer={showTargets}
                                        onClose={() => setShowTargets(false)}
                                    />
                                    <PaddockUnits
                                        openDrawer={showUnits}
                                        onClose={() => setShowUnits(false)}
                                    />
                                    <Paper>
                                        <DateTabs
                                            dates={measurements.map((m) => new Date(m.date))}
                                            datesWithCameraIcons={measurements
                                                .filter((m) => m.has_satellite)
                                                .map((m) => new Date(m.date))}
                                            cameraIconClicable={portalSatImageDialogEnabled}
                                            onDateChange={(d) => handleDateChange(d)}
                                            onCameraIconClick={handleCameraIconClick}
                                        />
                                        <Divider />
                                        {showGraph ? (
                                            <MultiFeedWedges growths={growths} />
                                        ) : (
                                            <>
                                                <Hidden xsDown implementation="css">
                                                    <SingleDayMeasurements
                                                        forDate={selectedDate!}
                                                    />
                                                </Hidden>
                                                <Hidden smUp implementation="css">
                                                    <SingleDayMeasurementsMobile
                                                        forDate={selectedDate!}
                                                    />
                                                </Hidden>
                                            </>
                                        )}
                                    </Paper>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {vegetationIndexState?.location && (
                <VegetationIndexImageDialog
                    onClose={closeVegetationIndexImageDialog}
                    title={DateTime.fromJSDate(vegetationIndexState.date).toFormat(
                        DATE_FORMATS.dayMonthYearShort
                    )}
                    imageLocation={vegetationIndexState.location}
                />
            )}
        </Page>
    );
}
