import firebase from 'firebase/app';
import { get } from '../../../../api/client';
import { Formula } from './FormulaType';
import { RegressionInput } from './RegressionInput';
import { RegressionOutput } from './RegressionOutput';
import 'firebase/auth';

export const getFormula = async (batchId: string): Promise<Formula> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    return await get(token!, `regression/formula/${batchId}`);
};

export const getInput = async (
    batchId: string,
    wdrreWeight: number,
    wdrreLimit: number
): Promise<RegressionInput> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    return await get(
        token!,
        `regression/input/${batchId}/weight/${wdrreWeight}/limit/${wdrreLimit}`
    );
};

export const getOutput = async (batchId: string): Promise<RegressionOutput> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    return await get(token!, `regression/zone-hapgr/${batchId}`);
};
