import firebase from 'firebase/app';
import { get } from '../../api/client';
import 'firebase/auth';
import 'firebase/database';

export const runPphProcessor = async (
    farmId: number,
    since: string,
    until: string,
    type: string
): Promise<any> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    return await get(token!, `pph/run/farm/${farmId}/since/${since}/until/${until}/${type}`);
};
