import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Box from '@material-ui/core/Box';

const columns: GridColDef[] = [
    { field: 'date_reported_str', headerName: 'Date Reported', width: 200 },
    { field: 'farm_name', headerName: 'Farm', width: 160 },
    { field: 'datetime_image_str', headerName: 'Image Date', width: 220 },
    { field: 'scene_id', headerName: 'Scene ID', width: 260 },
    { field: 'id', headerName: 'ID', width: 200 },
    { field: 'order_id', headerName: 'Order ID', width: 300 },
];

type ScenesDataGridProps = {
    scenes: any;
    enableSelection: boolean;
    onSelect?: (scene: any) => void;
};

export default function ScenesDataGrid({
    scenes,
    enableSelection,
    onSelect = () => {},
}: ScenesDataGridProps) {
    const rows = Object.entries(scenes ?? {}).map(([k, v]) => ({ id: k, ...(v as any) }));

    return (
        <Box height={400} width="100%">
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection={enableSelection}
                onSelectionModelChange={(newSelection) => {
                    onSelect(newSelection as string[]);
                }}
                disableSelectionOnClick
            />
        </Box>
    );
}
