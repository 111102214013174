import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useAppSelector } from '../../app/hooks';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useFirebase } from 'react-redux-firebase';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        small: {
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
    })
);

export default function ProfileStrip() {
    const classes = useStyles();
    const profile = useAppSelector((state) => (state.firebase as any).profile);
    const firebase = useFirebase();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        firebase.logout();
    };

    return (
        <div>
            <Avatar
                src={profile.avatarUrl}
                alt={profile.displayName}
                className={classes.small}
                onClick={handleMenu}
            />
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </div>
    );
}
