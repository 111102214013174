import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { MoveHistoryModel, PaddockMoved } from '../paddockMoveHistoryType';
import { fromStrISOToDateTime } from 'utils/DateUtil';
import { FetchNext } from 'components/FetchNext';
import { BoxCenter } from 'components/BoxCenter';
import _map from 'lodash/map';
import _uniqWith from 'lodash/uniqWith';
import _isEqual from 'lodash/isEqual';
import { PaddockDictionary } from 'features/paddocks/paddockType';
import { PaddockGroupsDictionary } from 'features/paddock-groups/paddockGroupType';
import TableHeadInfo from 'components/TableHeadInfo';
import TableContent from './TableContent';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(2),
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        boxContainer: {
            minHeight: theme.spacing(6),
            paddingTop: theme.spacing(4),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    })
);

type Props = {
    data: PaddockMoved[];
    loading: boolean;
    refresh: boolean;
    rowsPerPage: number;
    page: number;
    paddocks: PaddockDictionary;
    paddockGroups: PaddockGroupsDictionary;
    setPage: (i: number) => void;
    onItemDeleted: (paddockId: string, groupId: string, date: string) => void;
};

export default function Index(props: Props) {
    const classes = useStyles();
    const [rows, setRows] = React.useState<MoveHistoryModel[]>([]);

    const {
        data,
        loading,
        refresh,
        rowsPerPage,
        page,
        paddocks,
        paddockGroups,
        setPage,
        onItemDeleted,
    } = props;

    useEffect(() => {
        const list = _map(data, (p: PaddockMoved) => ({
            eventId: p.eventId,
            eventTime: p.eventTime,
            paddockId: p.payload.paddockId,
            paddockGroupId: p.payload.paddockGroupId,
            dateMoved: p.payload.date,
            dateMovedDt: fromStrISOToDateTime(p.payload.date),
            groupName:
                paddockGroups[p.payload.paddockGroupId]?.groupName || p.payload.paddockGroupId,
            previousGroupName: paddockGroups[p.payload.previousPaddockGroupId]?.groupName,
            paddockName: paddocks[p.payload.paddockId]?.paddockName,
            user: p.payload.userId,
        }));

        if (refresh) {
            setRows((p) => list);
            return;
        }

        setRows((p) => {
            return _uniqWith([...p, ...list], _isEqual);
        });
    }, [data, refresh, paddockGroups, paddocks]);

    const onConfirmItemDeletehandler = (e: React.MouseEvent<any>, model: MoveHistoryModel) => {
        onItemDeleted(model.paddockId, model.paddockGroupId, model.dateMoved);
        e.stopPropagation();
    };

    const fetchMoreHandler = (p: number) => {
        setPage(p);
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableHeadInfo title="Paddock move history" />

                {!loading && !rows.length && <BoxCenter description="No records" />}

                <TableContent rows={rows} onConfirmItemDeletehandler={onConfirmItemDeletehandler} />

                <FetchNext
                    skip={page}
                    fetchMore={fetchMoreHandler}
                    hasMore={data.length >= rowsPerPage}
                    loading={loading}
                />
            </Paper>
        </div>
    );
}
