import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Formula } from './FormulaType';
import { getFormula, getInput, getOutput } from './regressionApi';
import { RootState } from '../../../../app/store';
import { RegressionInput } from './RegressionInput';
import { RegressionOutput } from './RegressionOutput';

type ProgressState = {
    loading: boolean;
    status: string;
    error: string | null;
};

interface RegressionState {
    formulas: Formula[];
    formulasState: ProgressState;
    input: RegressionInput[];
    inputState: ProgressState;
    output: RegressionOutput[];
    outputState: ProgressState;
}

const initialState: RegressionState = {
    formulas: [],
    formulasState: {
        loading: false,
        status: 'idle',
        error: null,
    },
    input: [],
    inputState: {
        loading: false,
        status: 'idle',
        error: null,
    },
    output: [],
    outputState: {
        loading: false,
        status: 'idle',
        error: null,
    },
};

export const fetchFormula = createAsyncThunk(
    'regression/fetchFormula',
    async (batchId: string): Promise<Formula> => await getFormula(batchId)
);

type fetchInputParams = {
    batchId: string;
    wdrreWeight: number;
    wdrreLimit: number;
};

export const fetchInput = createAsyncThunk(
    'regression/fetchInput',
    async ({ batchId, wdrreWeight, wdrreLimit }: fetchInputParams): Promise<RegressionInput> =>
        await getInput(batchId, wdrreWeight, wdrreLimit)
);

export const fetchOutput = createAsyncThunk(
    'regression/fetchOutput',
    async (batchId: string): Promise<RegressionOutput> => await getOutput(batchId)
);

export const regressionSlice = createSlice({
    name: 'regression',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchFormula.pending.type]: (state: RegressionState) => {
            state.formulas = [];
            state.formulasState = {
                loading: true,
                status: 'loading',
                error: null,
            };
        },
        [fetchFormula.fulfilled.type]: (
            state: RegressionState,
            action: PayloadAction<Formula[]>
        ) => {
            state.formulas = action.payload;
            state.formulasState = {
                loading: false,
                status: 'succeeded',
                error: null,
            };
        },
        [fetchFormula.rejected.type]: (state: RegressionState, action: PayloadAction<string>) => {
            state.formulas = [];
            state.formulasState = {
                loading: false,
                status: 'failed',
                error: action.payload,
            };
        },
        [fetchInput.pending.type]: (state: RegressionState) => {
            state.input = [];
            state.inputState = {
                loading: true,
                status: 'loading',
                error: null,
            };
        },
        [fetchInput.fulfilled.type]: (
            state: RegressionState,
            action: PayloadAction<RegressionInput[]>
        ) => {
            state.input = action.payload;
            state.inputState = {
                loading: false,
                status: 'succeeded',
                error: null,
            };
        },
        [fetchInput.rejected.type]: (state: RegressionState, action: PayloadAction<string>) => {
            state.input = [];
            state.inputState = {
                loading: false,
                status: 'failed',
                error: action.payload,
            };
        },
        [fetchOutput.pending.type]: (state: RegressionState) => {
            state.output = [];
            state.outputState = {
                loading: true,
                status: 'loading',
                error: null,
            };
        },
        [fetchOutput.fulfilled.type]: (
            state: RegressionState,
            action: PayloadAction<RegressionOutput[]>
        ) => {
            state.output = action.payload;
            state.outputState = {
                loading: false,
                status: 'succeeded',
                error: null,
            };
        },
        [fetchOutput.rejected.type]: (state: RegressionState, action: PayloadAction<string>) => {
            state.output = [];
            state.outputState = {
                loading: false,
                status: 'failed',
                error: action.payload,
            };
        },
    },
});

export const selectFormulas = (state: RootState): Formula[] => state.regression.formulas;

export default regressionSlice.reducer;
