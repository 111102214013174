import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DownloadIcon from '@material-ui/icons/SaveAlt';
import TargetIcon from '../../../resources/target.svg';
import TableIcon from '@material-ui/icons/ViewListSharp';
import GraphIcon from '@material-ui/icons/AssessmentSharp';
import UnitsIcon from '@material-ui/icons/Straighten';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionsWrapper: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        actionIcons: {
            display: 'flex',
            gridColumnGap: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            alignItems: 'center',
        },
        targetIcon: {
            cursor: 'pointer',
        },
    })
);

type ContextActionsProps = {
    handleDownloadClick: () => void;
    handleTargetClick?: () => void;
    handleUnitsClick?: () => void;
    toggleGraphClick?: () => void;
    showGraph?: boolean;
    showUnitsIcon?: boolean;
    showTargetsIcon?: boolean;
    showGraphIcon?: boolean;
};

export default function ContextActions({
    handleDownloadClick,
    handleTargetClick = () => {},
    handleUnitsClick = () => {},
    toggleGraphClick = () => {},
    showGraph = false,
    showUnitsIcon = false,
    showTargetsIcon = false,
    showGraphIcon = false,
}: ContextActionsProps) {
    const classes = useStyles();

    return (
        <div className={classes.actionsWrapper}>
            <div className={classes.actionIcons}>
                <Hidden xsDown implementation="css">
                    <DownloadIcon
                        color="secondary"
                        onClick={handleDownloadClick}
                        cursor="pointer"
                    />
                </Hidden>
                {showUnitsIcon && (
                    <UnitsIcon color="secondary" onClick={handleUnitsClick} cursor="pointer" />
                )}
                {showTargetsIcon && (
                    <img
                        src={TargetIcon}
                        alt="target"
                        onClick={handleTargetClick}
                        className={classes.targetIcon}
                    />
                )}
                {showGraphIcon && (
                    <>
                        {showGraph ? (
                            <TableIcon
                                color="secondary"
                                onClick={toggleGraphClick}
                                cursor="pointer"
                            />
                        ) : (
                            <GraphIcon
                                color="secondary"
                                onClick={toggleGraphClick}
                                cursor="pointer"
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
