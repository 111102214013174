import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FarmGroup } from '../farm-groups/farmGroupType';
import FarmGroupsAutoComplete from '../farm-groups/FarmGroupsAutoComplete';
import { Paper, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Box from '@material-ui/core/Box';
import DateRangePicker from '../../components/DateRangePicker';
import { DateTime } from 'luxon';
import Button from '@material-ui/core/Button';
import { DATE_FORMATS } from '../../utils/DateUtil';
import { autoMapping, reset } from './mappingsSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: theme.spacing(2),
        },
        paper: {
            margin: theme.spacing(2),
        },
    })
);

const defaultFarmGroupFrom: FarmGroup = {
    farmGroupId: 0,
    farmGroupName: 'Select One',
};

export default function AutoMappings() {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const [selectedFarmGroupFrom, setSelectedFarmGroupFrom] =
        useState<FarmGroup>(defaultFarmGroupFrom);

    const handleFarmGroupFromSelect = (farmGroup: FarmGroup) => {
        setSelectedFarmGroupFrom(farmGroup || defaultFarmGroupFrom);
    };

    const [refreshFrom, setRefreshFrom] = useState(false);
    useEffect(() => {
        setSelectedFarmGroupFrom(defaultFarmGroupFrom);
    }, [refreshFrom]);

    const [dateRange, setDateRange] = React.useState([
        DateTime.local().minus({ days: 1 }),
        DateTime.local(),
    ]);

    const [areFieldsCleared, setAreFieldsCleared] = useState(false);
    const handleAfterReset = () => setAreFieldsCleared(false);

    const handleMappingClick = () => {
        dispatch(
            autoMapping({
                farmGroupId: selectedFarmGroupFrom.farmGroupId,
                since: dateRange[0].toJSDate(),
                until: dateRange[1].toJSDate(),
            })
        );
    };

    const { message, error } = useAppSelector((state) => ({
        message: state.mappings.response,
        error: state.mappings.error,
    }));

    useEffect(() => {
        if (message || error) {
            setTimeout(() => dispatch(reset()), 6000);
        }
    }, [message, error, dispatch]);

    return (
        <div className={classes.root}>
            <h4>AUTO MAPPING</h4>
            <Paper className={classes.paper}>
                <Box display="flex" gridColumnGap={16} alignItems="center" padding={2}>
                    <FarmGroupsAutoComplete
                        preSelectedFarmGroup={selectedFarmGroupFrom}
                        onSelect={handleFarmGroupFromSelect}
                        refresh={refreshFrom}
                        afterLoad={() => setRefreshFrom(false)}
                    />
                    <DateRangePicker
                        value={dateRange}
                        placeholder="Select a date range"
                        onChange={(values: any) => {
                            setDateRange([values.begin, values.end]);
                        }}
                        autoOk={true}
                        format={DATE_FORMATS.dayMonthYearShort}
                        reset={areFieldsCleared}
                        onAfterReset={handleAfterReset}
                    />

                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        disabled={selectedFarmGroupFrom.farmGroupId === 0}
                        onClick={handleMappingClick}
                    >
                        Initiate Mapping
                    </Button>
                </Box>
            </Paper>

            <Snackbar
                open={!!error}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity="error">Something went wrong! {error}</Alert>
            </Snackbar>
            <Snackbar
                open={!!message}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity="success">Request initiated!</Alert>
            </Snackbar>
        </div>
    );
}
