import {
    PaddockCover,
    ZoneGroupGrowthDisplayable,
} from '../measurements/types/paddockMeasurementTypes';
import _ from 'lodash';
import { FiltersState } from '../../measurements/filters/filtersSlice';

export const buildChartData = (g: PaddockCover[]) => {
    let totalArea = 0;
    const barXs: any[] = [];
    const barYs: any[] = [];
    const widths: any[] = [];
    const targets: any[] = [];
    const zones: any[] = [];
    const sortedData = _(g)
        .filter((g) => g.cover > -1)
        .orderBy((g) => g.cover, 'desc')
        .value();
    sortedData.forEach((d: PaddockCover) => {
        const x = totalArea + d.area / 2;
        totalArea += d.area;
        barXs.push(x);
        barYs.push(d.cover);
        widths.push(d.area);
        targets.push(d.target);
        zones.push(d.zone);
    });
    return {
        bar: { x: barXs, y: barYs, text: zones, widths: widths },
        line: {
            x: [barXs[0], barXs[barXs.length - 1]],
            y: [targets[0], targets[targets.length - 1]],
            text: [zones[0], zones[zones.length - 1]],
        },
    };
};

export const getPaddockCovers = (
    zoneGroup: ZoneGroupGrowthDisplayable,
    filters: FiltersState
): PaddockCover[] => {
    const increment =
        (filters.targets.preGrazingTarget - filters.targets.postGrazingTarget) /
        (zoneGroup.growths.filter((zg) => zg.cover_num > 0).length - 1);
    const getTargetGrazing = (index: number) =>
        filters.targets.preGrazingTarget - increment * index;
    return zoneGroup.growths
        .filter((zg) => !zg.grazed)
        .map((g, index) => ({
            zoneName: zoneGroup.zone_group,
            zone: g.zone_name ?? '',
            cover: g.cover_num,
            target: zoneGroup.is_cutting_zone_group
                ? filters.targets.preCuttingTarget
                : getTargetGrazing(index),
            area: g.effective_area ?? 0,
        }));
};
