import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { shallowEqual } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { fetchFarmsByFarmGroup } from './farmsSlice';
import { Farm } from './farmType';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { STATUS } from 'utils/Store/Constants';
import { Sizes } from 'utils/BaseStyles';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: Sizes.width,
        },
    })
);

type FarmsAutoCompleteProps = {
    farmGroupId: number;
    preSelectedFarm: Farm | null;
    onSelect: (farm: Farm) => void;
    refresh?: boolean;
    afterLoad?: () => void;
};

export default function FarmsAutoComplete({
    farmGroupId,
    preSelectedFarm,
    onSelect,
    refresh,
    afterLoad = () => {},
}: FarmsAutoCompleteProps) {
    const classes = styles();
    const dispatch = useAppDispatch();
    const { farmsLoading, farmsLoadingStatus, farms } = useAppSelector((state) => {
        return {
            farmsLoading: state.farms.loading,
            farmsLoadingStatus: state.farms.status,
            farmsError: state.farms.error,
            farms: state.farms.farms,
        };
    }, shallowEqual);

    useEffect(() => {
        if ((farmGroupId && farmsLoadingStatus === STATUS.idle) || refresh) {
            dispatch(fetchFarmsByFarmGroup(farmGroupId));
            afterLoad();
        }
    }, [farmGroupId, farmsLoadingStatus, dispatch, refresh, afterLoad]);

    const [value, setValue] = React.useState<Farm | null>(null);
    useEffect(() => {
        setValue(preSelectedFarm);
    }, [preSelectedFarm]);

    return (
        <Autocomplete
            id="farms"
            loadingText="loading..."
            loading={farmsLoading}
            options={farms}
            getOptionSelected={(a, b) => a.farmId === b.farmId}
            getOptionLabel={(option) => option.farmName}
            renderInput={(params) => <TextField {...params} label="Farm" variant="outlined" />}
            value={value}
            onChange={(event: any, newValue: any) => {
                setValue(newValue);
                onSelect(newValue);
            }}
            className={classes.root}
        />
    );
}
