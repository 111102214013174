import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STATUS } from '../../../../../utils/Store/Constants';
import { useFriendlyErrorHandler } from '../../../../notifications/hooks';
import {
    AssociationTransferProps,
    BatchAssociationTransferProps,
} from '../interfaces/FarmMotesAssociation.d';
import { batchTransferMotes } from './associationsApi';

export const batchAssociationTransfer = createAsyncThunk(
    'batchAssociationTransfer',
    async (
        transfers: BatchAssociationTransferProps,
        { rejectWithValue, dispatch }
    ): Promise<any> => {
        const callbackFnc = async () => {
            return await batchTransferMotes(transfers);
        };
        return await useFriendlyErrorHandler(callbackFnc, rejectWithValue, dispatch, {
            customMessage: 'Could not transfer all the motes',
        });
    }
);

const initialStatus: Status = {
    transfers: [],
    loading: false,
    status: STATUS.idle,
    error: null,
};
interface Status {
    transfers: AssociationTransferProps[];
    loading: boolean;
    status: string;
    error: string | null;
}
interface BatchAssociationsState {
    status: Status;
}
const initialState: BatchAssociationsState = {
    status: initialStatus,
};
export const batchAssociationsSlice = createSlice({
    name: 'batchAssociationTransfer',
    initialState,
    reducers: {},
    extraReducers: {
        [batchAssociationTransfer.pending.type]: (state: BatchAssociationsState) => {
            state.status.loading = true;
            state.status.status = STATUS.loading;
            state.status.error = null;
        },
        [batchAssociationTransfer.fulfilled.type]: (
            state: BatchAssociationsState,
            action: PayloadAction<AssociationTransferProps[]>
        ) => {
            state.status.transfers = action.payload;
            state.status.loading = false;
            state.status.status = STATUS.succeeded;
            state.status.error = null;
        },
        [batchAssociationTransfer.rejected.type]: (
            state: BatchAssociationsState,
            action: PayloadAction<any>
        ) => {
            state.status.loading = false;
            state.status.status = STATUS.failed;
            state.status.error = action.payload?.error;
        },
    },
});

export default batchAssociationsSlice.reducer;
