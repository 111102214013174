import React, { useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import RegressionIcon from '@material-ui/icons/InsertChart';
import ReprocessIcon from '@material-ui/icons/ReplayRounded';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { fetchDeleteBatch, selectBatchId, fetchReprocessBatch } from './batchesSlice';
import DeleteToaster from './DeleteToaster';
import { Alert } from '@material-ui/lab';
import { LinearProgress } from '@material-ui/core';

const columns = (
    showRegression: boolean,
    onRegressionClick: (batchId: string) => void,
    enableDeletion: boolean,
    onDeletionClick: (batchId: string) => void,
    enableReprocess: boolean,
    onReprocessClick: (batchId: string) => void
): GridColDef[] => {
    const cols: GridColDef[] = [
        { field: 'date_reported_str', headerName: 'Date Reported', width: 200 },
        { field: 'id', headerName: 'ID', width: 200 },
    ];
    if (showRegression) {
        cols.push({
            field: 'regression',
            headerName: ' ',
            sortable: false,
            renderCell: (params) => {
                return (
                    <RegressionIcon
                        cursor="pointer"
                        color="secondary"
                        onClick={() => onRegressionClick(params.id.toString())}
                    />
                );
            },
        });
    }
    if (enableDeletion) {
        cols.push({
            field: 'delete',
            headerName: ' ',
            sortable: false,
            renderCell: (params) => {
                return (
                    <DeleteIcon
                        cursor="pointer"
                        color="secondary"
                        onClick={() => onDeletionClick(params.id.toString())}
                    />
                );
            },
        });
    }
    if (enableReprocess) {
        cols.push({
            field: 'reprocess',
            headerName: ' ',
            sortable: false,
            renderCell: (params) => {
                return (
                    <ReprocessIcon
                        cursor="pointer"
                        color="secondary"
                        onClick={() => onReprocessClick(params.id.toString())}
                    />
                );
            },
        });
    }
    return cols;
};

type BatchesDataGridProps = {
    batches: any;
    enableSelection: boolean;
    enableDeletion?: boolean;
    enableReprocess?: boolean;
    showRegression?: boolean;
};

export default function BatchesDataGrid({
    batches,
    enableSelection,
    enableDeletion = false,
    enableReprocess = false,
    showRegression = false,
}: BatchesDataGridProps) {
    const dispatch = useAppDispatch();

    const deleteBatchLoading = useAppSelector((state) => state.batches.deleteStatus.loading);

    const handleRegressionClick = (batchId: string) => {
        dispatch(selectBatchId(batchId));
    };

    const [batchIdToDelete, setBatchIdToDelete] = useState<string | null>();
    const handleDeletionClick = (batchId: string) => {
        setBatchIdToDelete(batchId);
        dispatch(fetchDeleteBatch(batchId));
    };

    // const [batchIdToReprocess, setBatchIdToReprocess] = useState<string|null>();
    const wdrreWeight = 0.1;
    const wdrreLimit = 85;
    const handleReprocessClick = (batchId: string) => {
        // setBatchIdToReprocess(batchId);
        dispatch(
            fetchReprocessBatch({
                batchId: batchId,
                wdrreWeight: wdrreWeight,
                wdrreLimit: wdrreLimit,
            })
        );
    };

    const rows = Object.entries(batches ?? {}).map(([k, v]) => ({ id: k, ...(v as any) }));

    return (
        <Box height={'calc(100vh - 300px)'} width="100%">
            {batchIdToDelete && batchIdToDelete !== '' && (
                <>
                    <DeleteToaster batchId={batchIdToDelete} />
                    {deleteBatchLoading && (
                        <Alert severity="warning">Deleting batch {batchIdToDelete} ..</Alert>
                    )}
                    {deleteBatchLoading && <LinearProgress />}
                </>
            )}
            <DataGrid
                rows={rows}
                columns={columns(
                    showRegression,
                    handleRegressionClick,
                    enableDeletion,
                    handleDeletionClick,
                    enableReprocess,
                    handleReprocessClick
                )}
                pageSize={10}
                rowsPerPageOptions={[10, 50, 100]}
                checkboxSelection={enableSelection}
                disableSelectionOnClick
            />
        </Box>
    );
}
