import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { shallowEqual } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { Farm } from '../../farms/farmType';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { STATUS } from 'utils/Store/Constants';
import { Str } from 'utils/StringConstants';
import { Sizes } from 'utils/BaseStyles';
import { fetchAllFarms } from './onboardSlice';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: Sizes.width,
        },
    })
);

const build = (name: string): Farm => {
    return {
        farmId: 0,
        timeZone: Str.empty,
        farmName: name,
        farmGroupName: Str.empty,
        address: Str.empty,
        farmGroupId: 0,
        epsg: null,
        startDate: Str.empty,
    };
};

type ParamProps = {
    refresh?: boolean;
    allowAdd?: boolean;
    preSelectedFarm: Farm | null;
    onSelect: (farm: Farm) => void;
};

export default function FarmsAutoCompleteAdmin({
    refresh,
    preSelectedFarm,
    allowAdd = false,
    onSelect,
}: ParamProps) {
    const classes = styles();
    const dispatch = useAppDispatch();
    const { loading, status, data } = useAppSelector((state) => {
        return {
            loading: state.onboarding.allFarms.loading,
            status: state.onboarding.allFarms.status,
            data: state.onboarding.allFarms.farms,
        };
    }, shallowEqual);

    // OnMount
    useEffect(() => {
        if (status === STATUS.idle && data?.length === 0) {
            dispatch(fetchAllFarms());
        }
    }, [data, dispatch, status]);

    useEffect(() => {
        if (refresh) {
            dispatch(fetchAllFarms());
        }
    }, [dispatch, refresh]);

    const [value, setValue] = React.useState<Farm | null>(null);
    useEffect(() => {
        setValue(preSelectedFarm);
    }, [preSelectedFarm]);

    if (allowAdd) {
        const optionsfilter = createFilterOptions<Farm>();

        return (
            <Autocomplete
                id="FarmAutoCompleteAdd"
                options={data}
                loadingText="loading..."
                loading={loading}
                freeSolo
                selectOnFocus
                clearOnBlur
                openOnFocus
                handleHomeEndKeys
                className={classes.root}
                renderOption={(option) => option.farmName}
                renderInput={(params) => <TextField {...params} label="Farm" variant="outlined" />}
                value={value}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    return option.farmName;
                }}
                onChange={(event, newValue) => {
                    if (!newValue) return;

                    if (typeof newValue === 'string') {
                        onSelect(build(newValue));
                    } else {
                        onSelect(newValue);
                    }
                }}
                filterOptions={(options: Farm[], params) => {
                    const filtered = optionsfilter(options, params);
                    if (params.inputValue) {
                        filtered.unshift(build(params.inputValue));
                    }
                    return filtered;
                }}
            />
        );
    }

    return (
        <Autocomplete
            id="farms"
            loadingText="loading..."
            loading={loading}
            options={data}
            getOptionSelected={(a, b) => a.farmId === b.farmId}
            getOptionLabel={(option) => option.farmName}
            renderInput={(params) => <TextField {...params} label="Farm" variant="outlined" />}
            value={value}
            onChange={(event: any, newValue: any) => {
                setValue(newValue);
                onSelect(newValue);
            }}
            className={classes.root}
        />
    );
}
