import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../../resources/Farmote-Product-Logo-(white).png';
import ProfileStrip from '../profile/ProfileStrip';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ActivityLogActionMenu } from 'features/activity-log/ActivityLogActionMenu';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        title: {
            margin: '0 auto',
        },
    })
);

type MobileNavBarProps = {
    onMobileDrawerToggle: () => void;
};

export default function MobileNavBar({ onMobileDrawerToggle }: MobileNavBarProps) {
    const classes = useStyles();
    const { portalUalActionMenuEnabled } = useFlags();

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
        onMobileDrawerToggle();
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.title}>
                        <img src={Logo} alt="logo" width={90} />
                    </div>

                    {portalUalActionMenuEnabled && <ActivityLogActionMenu />}
                    <ProfileStrip />
                </Toolbar>
            </AppBar>
        </div>
    );
}
