import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel, Paper, TextField } from '@material-ui/core';
import DateRangePicker from '../../../../components/DateRangePicker';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { DateTime } from 'luxon';
import { shallowEqual } from 'react-redux';
import { Mote } from '../../../motes/moteType';
import { fetchMotesByFarmGroup, selectAllMotes } from '../../../motes/motesSlice';
import Cta from '../../../../components/Cta';
import { Adjustment, AdjustmentTypes, ReasonTypes } from './adjustmentTypes';
import { fetchLatestGrazing, selectLatestGrazing } from '../grazings/grazingsSlice';
import { selectFilters } from '../../../measurements/filters/filtersSlice';
import AddedAdjustments from './AddedAdjustments';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import { fetchReportAdjustments } from './adjustmentsSlice';
import DateSelector from '../../../../components/DateSelector';
import { DATE_FORMATS, fromStrISOToDateTime } from '../../../../utils/DateUtil';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                margin: theme.spacing(4),
            },
        },
        wrapper: {
            display: 'flex',
            columnGap: '1.5rem',
            rowGap: '1rem',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginTop: theme.spacing(1),
            padding: theme.spacing(2),
        },
        autoComplete: {
            width: '12rem',
        },
        pgr: {
            width: '10ch',
        },
        addCta: {
            textAlign: 'right',
            marginTop: theme.spacing(2),
        },
    })
);

export default function Adjustments() {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const filters = useAppSelector(selectFilters);
    const farmGroupId = filters.farm.farmGroupId;
    const latestGrazing = useAppSelector(selectLatestGrazing);
    const [dateRange, setDateRange] = React.useState<DateTime[]>([
        fromStrISOToDateTime(filters.to),
        fromStrISOToDateTime(filters.to),
    ]);
    const defaultMote: Mote = {
        moteName: 'Select One',
        devEui: '',
        moteId: 0,
        timeZone: '',
    };
    const [selectedMote, setSelectedMote] = useState<Mote>(defaultMote);
    const [selectedAdjustment, setSelectedAdjustment] = useState<AdjustmentTypes>(
        AdjustmentTypes.NONE
    );
    const [selectedReason, setSelectedReason] = useState<string>();
    const [isAutoPgr, setIsAutoPgr] = useState<boolean>(true);
    const [pgr, setPgr] = useState<string>();
    const [notes, setNotes] = useState<string>();
    const [adjustments, setAdjustments] = useState<Adjustment[]>([]);

    const motes = useAppSelector(selectAllMotes);
    const { motesLoading } = useAppSelector((state) => {
        return {
            motesLoading: state.motes.loading,
        };
    }, shallowEqual);

    useEffect(() => {
        dispatch(
            fetchMotesByFarmGroup({ farmGroupId: farmGroupId, from: new Date(), to: new Date() })
        );
    }, [dispatch, farmGroupId]);

    useEffect(() => {
        if (
            selectedAdjustment === AdjustmentTypes.PREVIOUS_GRAZING &&
            selectedMote &&
            selectedMote.moteId
        ) {
            dispatch(
                fetchLatestGrazing({ moteId: selectedMote.moteId, before: dateRange[0].toISO() })
            );
        }
    }, [dispatch, selectedAdjustment, selectedMote, dateRange]);

    const showExclusionReasons = selectedAdjustment === AdjustmentTypes.EXCLUSION;
    const showAutoPgr = selectedAdjustment === AdjustmentTypes.GRAZING;
    const showPreviousGrazing = selectedAdjustment === AdjustmentTypes.PREVIOUS_GRAZING;

    const showAddCta =
        selectedMote.moteId > 0 && dateRange && selectedAdjustment !== AdjustmentTypes.NONE;

    const [areFieldsCleared, setAreFieldsCleared] = useState(false);
    const clearFields = () => {
        setSelectedMote(defaultMote);
        setSelectedAdjustment(AdjustmentTypes.NONE);
        setSelectedReason('');
        setIsAutoPgr(true);
        setPgr(undefined);
        setNotes('');
        setAreFieldsCleared(true);
    };

    const handleAfterReset = () => setAreFieldsCleared(false);

    const handleAddClick = () => {
        const newAdjustment: Adjustment = {
            mote: selectedMote!,
            adjustment: selectedAdjustment!,
            reason: selectedReason!,
            notes: notes!,
            begin: dateRange[0]?.toFormat(DATE_FORMATS.dayMonthYearShort),
            end: dateRange[1]?.toFormat(DATE_FORMATS.dayMonthYearShort),
            previousPgr: latestGrazing,
            enteredPgr: pgr,
        };
        setAdjustments([...adjustments, newAdjustment]);
        clearFields();
    };

    const handleDeleteClick = (adjustmentToDelete: Adjustment[]) => {
        setAdjustments(_.difference(adjustments, adjustmentToDelete));
    };

    const handleReportClick = () => {
        dispatch(
            fetchReportAdjustments({
                farmId: filters.farm.farmId,
                farmName: filters.farm.farmName,
                adjustments: adjustments,
            })
        );
        setAdjustments([]);
    };

    return (
        <div className={classes.root}>
            <Typography variant="body2">Add a new Adjustment</Typography>
            <Paper className={classes.wrapper}>
                <Autocomplete
                    id="adjustment-types"
                    options={Object.values(AdjustmentTypes)}
                    getOptionLabel={(option: any) => option}
                    renderInput={(params) => (
                        <TextField {...params} label="Adjustment" variant="outlined" />
                    )}
                    value={selectedAdjustment}
                    onChange={(event: any, newValue: any) => {
                        setSelectedAdjustment(newValue);
                    }}
                    className={classes.autoComplete}
                    disableClearable
                />
                {selectedAdjustment === AdjustmentTypes.EXCLUSION ? (
                    <DateRangePicker
                        value={dateRange}
                        placeholder="Select a date range"
                        onChange={(values: any) => {
                            setSelectedMote(defaultMote);
                            setDateRange([values.begin, values.end]);
                            dispatch(
                                fetchMotesByFarmGroup({
                                    farmGroupId: farmGroupId,
                                    from: values.begin?.toJSDate(),
                                    to: values.end?.toJSDate(),
                                })
                            );
                        }}
                        autoOk={true}
                        format={DATE_FORMATS.dayMonthYearShort}
                        reset={areFieldsCleared}
                        onAfterReset={handleAfterReset}
                    />
                ) : (
                    <DateSelector
                        label="Select a date"
                        onChange={(value: Date) => {
                            setSelectedMote(defaultMote);
                            setDateRange([DateTime.fromJSDate(value), DateTime.fromJSDate(value)]);
                            dispatch(
                                fetchMotesByFarmGroup({
                                    farmGroupId: farmGroupId,
                                    from: value,
                                    to: value,
                                })
                            );
                        }}
                        reset={areFieldsCleared}
                        onAfterReset={handleAfterReset}
                    />
                )}
                <Autocomplete
                    id="motes"
                    loading={motesLoading}
                    options={motes}
                    getOptionLabel={(option: Mote) => option.moteName}
                    renderInput={(params) => (
                        <TextField {...params} label="Mote" variant="outlined" />
                    )}
                    value={selectedMote}
                    onChange={(event: any, newValue: any) => {
                        setSelectedMote(newValue);
                    }}
                    className={classes.autoComplete}
                    disableClearable
                />
                {showExclusionReasons && (
                    <Autocomplete
                        id="reason-types"
                        options={ReasonTypes}
                        getOptionLabel={(option: string) => option}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Reason"
                                value={selectedReason}
                                variant="outlined"
                            />
                        )}
                        value={selectedReason}
                        onChange={(event: any, newValue: any) => {
                            setSelectedReason(newValue);
                        }}
                        className={classes.autoComplete}
                        disableClearable
                    />
                )}
                {showAutoPgr && (
                    <>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isAutoPgr}
                                    onChange={() => setIsAutoPgr(!isAutoPgr)}
                                    name="Auto"
                                />
                            }
                            label="Auto"
                        />
                        {!isAutoPgr && (
                            <TextField
                                id="pgr"
                                label="PGR"
                                variant="outlined"
                                className={classes.pgr}
                                onChange={(e) => setPgr(e.target.value)}
                            />
                        )}
                    </>
                )}
                {showPreviousGrazing && (
                    <TextField
                        label="Previous PGR Date"
                        variant="outlined"
                        disabled
                        value={DateTime.fromISO(latestGrazing).toLocaleString(DateTime.DATE_SHORT)}
                    />
                )}
                <TextField
                    label="Notes"
                    value={notes}
                    variant="outlined"
                    multiline
                    rows={2}
                    fullWidth
                    onChange={(e) => setNotes(e.target.value)}
                />
            </Paper>
            <div className={classes.addCta}>
                <Cta label="add" onClick={handleAddClick} isDisabled={!showAddCta} />
            </div>
            {adjustments.length > 0 && (
                <>
                    <AddedAdjustments adjustments={adjustments} onDeleteClick={handleDeleteClick} />
                    <div className={classes.addCta}>
                        <Cta label="Report" onClick={handleReportClick} />
                    </div>
                </>
            )}
        </div>
    );
}
