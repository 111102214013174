import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DateSelector from '../../../../components/DateSelector';
import Typography from '@material-ui/core/Typography';
import FarmGroupsAutoComplete from '../../../farm-groups/FarmGroupsAutoComplete';
import { FarmGroup } from '../../../farm-groups/farmGroupType';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import Grid from '@material-ui/core/Grid';
import MultiMotesAutoComplete from './MultiMotesAutoComplete';
import AddedAssociatedMotes from './AddedAssociatedMotes';
import { FarmMotesAssociation, MoteAssociation } from './interfaces/FarmMotesAssociation.d';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { toDateOnly } from '../../../../utils/DateUtil';
import { batchAssociationTransfer } from './store/batchAssociationsSlice';
import { shallowEqual } from 'react-redux';
import { STATUS } from '../../../../utils/Store/Constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: theme.spacing(1),
        },
        paper: {
            marginTop: theme.spacing(1),
            padding: theme.spacing(2),
        },
        wrapper: {
            display: 'flex',
            columnGap: '1.5rem',
            rowGap: '1rem',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        textField: {
            minWidth: '12rem',
        },
        item: {},
    })
);

function moteAssociationSort(a: FarmMotesAssociation, b: FarmMotesAssociation) {
    const firstValue =
        a.farmFrom.farmGroupId + a.farmTo.farmGroupId + (a.transferDate.month + a.transferDate.day);
    const secondValue =
        b.farmFrom.farmGroupId + b.farmTo.farmGroupId + (b.transferDate.month + b.transferDate.day);
    return firstValue - secondValue;
}

export default function MoteAssociationTransfer() {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const [selectedFarmGroupFrom, setSelectedFarmGroupFrom] = useState<FarmGroup | null>(null);

    const handleFarmGroupFromSelect = (farmGroup: FarmGroup) => {
        setSelectedMotes(null);
        setClearCurrentMotesSelection(true);
        setAllowToAddMote(false);
        setSelectedFarmGroupFrom(farmGroup);
    };

    const clearFields = () => {
        setSelectedFarmGroup(null);
        setSelectedFarmGroupFrom(null);
        setSelectedMotes(null);
        setClearCurrentMotesSelection(true);
        setAllowToAddMote(false);
    };

    const [selectedMotes, setSelectedMotes] = useState<MoteAssociation[] | null>(null);
    const [preSelectedMotesForAssociation, setPreSelectedMotesForAssociation] = useState<
        MoteAssociation[]
    >([]);
    const handleMoteAutoCompleteChange = (motes: MoteAssociation[]) => {
        setSelectedMotes(motes);
    };

    const [selectedFarmGroupTo, setSelectedFarmGroup] = useState<FarmGroup | null>(null);
    const handleFarmGroupSelect = (farmGroup: FarmGroup) => {
        setSelectedFarmGroup(farmGroup);
    };

    const [clearCurrentMotesSelection, setClearCurrentMotesSelection] = useState<boolean>(false);
    const [transferDate, setTransferDate] = useState<DateTime>(DateTime.local());
    const handleTransferDateChange = (date: Date) => {
        setTransferDate(DateTime.fromJSDate(date));
        setClearCurrentMotesSelection(true);
        setPreSelectedMotesForAssociation([]);
    };

    const [allowToAddMote, setAllowToAddMote] = useState(false);
    useEffect(() => {
        const buttonDisabled =
            selectedMotes != null &&
            selectedMotes.length > 0 &&
            selectedFarmGroupFrom != null &&
            selectedFarmGroupFrom.farmGroupId > 0 &&
            selectedFarmGroupTo != null &&
            selectedFarmGroupTo.farmGroupId > 0;

        setAllowToAddMote(buttonDisabled);
    }, [selectedMotes, selectedFarmGroupTo, selectedFarmGroupFrom]);

    const handleTransferClick = () => {
        if (!motesAssociation) {
            return;
        }
        let clonedList = _.cloneDeep(motesAssociation);
        clonedList = clonedList.filter((m) => m.transferFlag !== 'transferred');
        const bulkMotesTransfer = clonedList.map((v) => ({
            moteId: v.mote.moteId,
            farmGroupId: v.farmTo.farmGroupId,
            selectedDate: toDateOnly(v.transferDate),
        }));
        dispatch(batchAssociationTransfer({ transfers: bulkMotesTransfer }));
    };

    const handleAddClick = () => {
        if (!selectedFarmGroupTo || !selectedFarmGroupFrom || !selectedMotes) {
            return;
        }

        const farmMotes: FarmMotesAssociation[] = _.map(selectedMotes, (m) => ({
            farmFrom: selectedFarmGroupFrom,
            farmTo: selectedFarmGroupTo,
            transferDate: transferDate,
            mote: m,
        }));

        const uniqueValues = _.uniqWith([...motesAssociation, ...farmMotes], _.isEqual).sort(
            moteAssociationSort
        );
        setFarmMotesAssociation(uniqueValues);
        setPreSelectedMotesForAssociation(_.concat(preSelectedMotesForAssociation, selectedMotes));
        clearFields();
    };

    const [motesAssociation, setFarmMotesAssociation] = useState<FarmMotesAssociation[]>([]);
    const handleMoteRemovedClick = (moteRemoved: MoteAssociation) => {
        let clonedList = _.cloneDeep(motesAssociation);
        setFarmMotesAssociation(clonedList.filter((f) => f.mote.moteId !== moteRemoved.moteId));
        setPreSelectedMotesForAssociation(
            preSelectedMotesForAssociation.filter((v) => v.moteId !== moteRemoved.moteId)
        );
    };

    const { batchTransfersLoading, batchTransfersLoadingStatus, transfers } = useAppSelector(
        (state) => {
            return {
                batchTransfersLoading: state.batchAssociations.status.loading,
                batchTransfersLoadingStatus: state.batchAssociations.status.status,
                batchTransfersError: state.batchAssociations.status.error,
                transfers: state.batchAssociations.status.transfers,
            };
        },
        shallowEqual
    );

    useEffect(() => {
        const batchTransferResult =
            batchTransfersLoadingStatus === STATUS.succeeded && transfers && transfers.length > 0
                ? transfers
                : [];
        let clonedList = _.cloneDeep(motesAssociation);
        clonedList.forEach((x) => {
            const moteStatus = batchTransferResult.find(
                (i) =>
                    i.farmGroupId === x.farmTo.farmGroupId &&
                    i.moteId === x.mote.moteId &&
                    i.selectedDate === toDateOnly(x.transferDate)
            );
            if (moteStatus?.status === 'fulfilled') {
                x.transferFlag = 'transferred';
            }
        });
        setFarmMotesAssociation(clonedList);
        // eslint-disable-next-line
    }, [transfers, batchTransfersLoadingStatus]);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container spacing={2} direction="row" justifyContent="flex-start">
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="h6">Select mote to transfer</Typography>
                    </Grid>
                    <Grid item>
                        <DateSelector
                            label="Transfer Date"
                            onChange={handleTransferDateChange}
                            reset={false}
                            onAfterReset={() => {}}
                            disableFuture={true}
                        />
                    </Grid>
                    <Grid item>
                        <FarmGroupsAutoComplete
                            preSelectedFarmGroup={selectedFarmGroupFrom}
                            onSelect={handleFarmGroupFromSelect}
                        />
                    </Grid>
                    <Grid item>
                        {selectedFarmGroupFrom && selectedFarmGroupFrom.farmGroupId > 0 && (
                            <MultiMotesAutoComplete
                                selectedFarmGroupId={selectedFarmGroupFrom.farmGroupId}
                                dateFrom={transferDate}
                                preSelectedMotes={preSelectedMotesForAssociation}
                                clearValues={clearCurrentMotesSelection}
                                onSelect={handleMoteAutoCompleteChange}
                            />
                        )}
                    </Grid>
                </Grid>
            </Paper>

            <Paper className={classes.paper}>
                <Grid container spacing={2} direction="row" justifyContent="flex-start">
                    <Grid item xs={12} md={12}>
                        <Grid alignItems="center" container spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Typography variant="h6">Destination</Typography>
                            </Grid>
                            <Grid item>
                                <FarmGroupsAutoComplete
                                    preSelectedFarmGroup={selectedFarmGroupTo}
                                    onSelect={handleFarmGroupSelect}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    disabled={!allowToAddMote}
                                    onClick={handleAddClick}
                                >
                                    ADD
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>

            {motesAssociation.length > 0 && (
                <Paper className={classes.paper}>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start">
                        <Grid item xs={12} md={12} lg={12}>
                            <AddedAssociatedMotes
                                farms={motesAssociation}
                                onRemoveMoteClick={handleMoteRemovedClick}
                                onTransferClick={handleTransferClick}
                                transfers={transfers}
                                batchTransfersLoadingStatus={batchTransfersLoadingStatus}
                                batchTransfersLoading={batchTransfersLoading}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            )}
        </div>
    );
}
