import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Notification } from './Notification';
import { dismissNotification, selectNotifications } from './notificationsSlice';

export const Notifications = () => {
    const dispatch = useAppDispatch();
    const notifications = useAppSelector(selectNotifications);

    const handleDismiss = (id: string) => {
        dispatch(dismissNotification(id));
    };

    return (
        <div>
            {notifications.map((n) => (
                <Notification key={n.id} notification={n} onDismiss={handleDismiss} />
            ))}
        </div>
    );
};
