import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { CircularProgress, LinearProgress, Paper, Snackbar, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import FarmsAutoComplete from '../../farms/FarmsAutoComplete';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { shallowEqual } from 'react-redux';
import { fetchOnboardZoneBoundaries, fetchZoneBoundaries } from './onboardSlice';
import { Farm } from '../../farms/farmType';
import NewZonesMap from './NewZonesMap';
import { Alert } from '@material-ui/lab';
import DateSelector from '../../../components/DateSelector';
import ReactJson from 'react-json-view';
import { PaddockChanges } from './onboardTypes';
import { middayUtcStr } from '../../../utils/DateUtil';
import { addNotification } from 'features/notifications/notificationsSlice';
import LoadingButton from 'components/LoadingButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(2),
        },
        textField: {
            width: '25ch',
        },
    })
);

type NewZoneBoundariesProps = {
    selectedFarm: Farm | null;
    setSelectedFarm: (farm: Farm | null) => void;
};
export default function NewZoneBoundaries({
    selectedFarm,
    setSelectedFarm,
}: NewZoneBoundariesProps) {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const farmGroupId = useAppSelector((state) => (state.firebase as any).profile?.farmGroupId);

    const [refresh, setRefresh] = useState(false);
    const [zonesGeoJson, setZonesGeoJson] = useState<any>(null);
    const [dateOnboarded, setDateOnboarded] = useState<Date>(new Date());
    const [areFieldsCleared, setAreFieldsCleared] = useState(false);
    const handleAfterReset = () => setAreFieldsCleared(false);
    const [saveZoneLoading, setSaveZoneLoading] = useState<boolean>(false);

    useEffect(() => {
        if (selectedFarm) {
            dispatch(
                fetchZoneBoundaries({
                    farmId: selectedFarm.farmId,
                    asOf: middayUtcStr(dateOnboarded.toISOString(), selectedFarm.timeZone),
                })
            );
        }
    }, [selectedFarm, dateOnboarded, dispatch]);

    const { loading, error, response, zoneBoundaries } = useAppSelector((state) => {
        return {
            loading: state.onboarding.onboardZoneBoundariesResponseStatus.loading,
            error: state.onboarding.onboardZoneBoundariesResponseStatus.error,
            response: state.onboarding.onboardZoneBoundariesResponse,
            zoneBoundaries: state.onboarding.zoneBoundaries,
        };
    }, shallowEqual);

    const handleFileUpload = (file: Blob, onFileRead: (content: string) => void): void => {
        const fileReader = new FileReader();
        if (!file) {
            setZonesGeoJson(null);
            return;
        }
        fileReader.onloadend = () => {
            const content = fileReader.result as string;
            onFileRead(content);
            setZonesGeoJson(JSON.parse(content));
        };
        fileReader.readAsText(file);
    };

    const handleFarmFileChosen = (file: Blob) => {
        handleFileUpload(file, (content) => setZonesGeoJson(JSON.parse(content)));
    };

    const [paddocksChanges, setPaddocksChanges] = useState<PaddockChanges[]>([]);

    const handleUpdate = () => {
        const data = {
            farmId: selectedFarm?.farmId!,
            zonesGeoJson: zonesGeoJson!,
            onbordedOn: dateOnboarded,
            paddocksChanges: paddocksChanges,
        };

        if (!zonesGeoJson) {
            dispatch(
                addNotification({
                    message: 'Select a Zone Boundaries geojson file',
                    severity: 'warning',
                })
            );
            return;
        }

        if (!dateOnboarded) {
            dispatch(
                addNotification({
                    message: 'Select the `Onboard on` date',
                    severity: 'warning',
                })
            );
            return;
        }

        setSaveZoneLoading(true);

        dispatch(fetchOnboardZoneBoundaries(data)).then((resp) => {
            setSelectedFarm(null);
            setZonesGeoJson(null);
            setDateOnboarded(new Date());
            setSaveZoneLoading(false);
        });
    };

    return (
        <>
            <Paper className={classes.root}>
                <Box padding={2}>
                    <Typography variant="subtitle1">Zone Boundaries</Typography>
                </Box>
                <Divider />
                {loading && <LinearProgress />}
                <Box
                    display="flex"
                    gridRowGap={16}
                    gridColumnGap={16}
                    padding={2}
                    alignItems="center"
                    flexWrap="wrap"
                    style={{
                        pointerEvents: loading ? 'none' : 'inherit',
                        opacity: loading ? 0.6 : 1,
                    }}
                >
                    {farmGroupId ? (
                        <FarmsAutoComplete
                            farmGroupId={farmGroupId}
                            preSelectedFarm={selectedFarm}
                            onSelect={setSelectedFarm}
                            refresh={refresh}
                            afterLoad={() => setRefresh(false)}
                        />
                    ) : (
                        <TextField>
                            <CircularProgress />
                        </TextField>
                    )}
                    <TextField
                        label="Zone Boundaries"
                        placeholder="Provide a GeoJSON"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        type="file"
                        inputProps={{ accept: '.geojson' }}
                        onChange={(e) => handleFarmFileChosen((e.target as any).files[0])}
                        onClick={(e) => {
                            (e.target as any).value = null;
                        }}
                    />
                    <TextField
                        label="EPSG"
                        defaultValue="3857"
                        variant="outlined"
                        disabled
                        style={{ width: '8ch' }}
                    />
                    <DateSelector
                        label="Onboarded on"
                        onChange={(value: Date) => {
                            setDateOnboarded(value);
                        }}
                        reset={areFieldsCleared}
                        onAfterReset={handleAfterReset}
                    />
                    <LoadingButton label="Save" size="medium" loading={saveZoneLoading}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            onClick={handleUpdate}
                        >
                            Save
                        </Button>
                    </LoadingButton>
                </Box>
                <NewZonesMap
                    geoJsonOld={zoneBoundaries}
                    geoJsonNew={zonesGeoJson}
                    setPaddocksChanges={setPaddocksChanges}
                />
                {response && (
                    <Snackbar>
                        <Alert severity="success">Saved Successfully!</Alert>
                    </Snackbar>
                )}
                {error && (
                    <Snackbar>
                        <Alert severity="error">{error}</Alert>
                    </Snackbar>
                )}
                {response && (
                    <>
                        <Divider />
                        <Box padding={4}>
                            <ReactJson
                                src={response}
                                name="Response"
                                iconStyle="square"
                                displayDataTypes={false}
                            />
                        </Box>
                    </>
                )}
            </Paper>
        </>
    );
}
