import { BrowserRouter as Router } from 'react-router-dom';
import AuthIsLoaded from '../features/auth/AuthIsLoaded';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { ThemeProvider } from '@material-ui/core/styles';
import { buildTheme } from './theme';
import Routes from './Routes';
import { Notifications } from '../features/notifications/Notifications';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { setupLaunchdarklyListeners } from '../features/launchdarkly/listeners';
import { startAppListening } from './store';
import { useLDClient } from 'launchdarkly-react-client-sdk';

function App() {
    const ldClient = useLDClient();
    useEffect(() => {
        if (!ldClient) return;

        const unsubscribe = setupLaunchdarklyListeners(startAppListening, ldClient);

        return () => unsubscribe();
    }, [ldClient]);

    return (
        <div>
            <ThemeProvider theme={buildTheme('light')}>
                <header>
                    <Router>
                        <AuthIsLoaded>
                            <MuiPickersUtilsProvider utils={LuxonUtils}>
                                <Routes />
                                <Notifications />
                            </MuiPickersUtilsProvider>
                        </AuthIsLoaded>
                    </Router>
                </header>
            </ThemeProvider>
        </div>
    );
}

export default withLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_ID || '',
    options: {
        bootstrap: 'localStorage',
    },
})(App);
