import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: '48px',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        },

        title: {
            flex: '1 1 100%',
        },
        subtitle: {
            fontSize: '0.75rem',
        },
    })
);

interface ParamProps {
    title: string;
}

export default function TableHeadInfo(props: ParamProps) {
    const classes = useToolbarStyles();
    const { title } = props;

    return (
        <Toolbar className={classes.root}>
            <Typography
                className={classes.title}
                color="inherit"
                variant="subtitle1"
                component="div"
            >
                {title}
            </Typography>
        </Toolbar>
    );
}
