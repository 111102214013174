import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import ProfileStrip from '../features/profile/ProfileStrip';
import clsx from 'clsx';
import Hidden from '@material-ui/core/Hidden';
import MobileNavBar from '../features/nav/MobileNavBar';
import NavDrawer from '../features/nav/nav-drawer';
import { ActivityLogActionMenu } from 'features/activity-log/ActivityLogActionMenu';
import { useFlags } from 'launchdarkly-react-client-sdk';

const drawerWidth = 240;
const drawerShortWidth = 60;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
        },
        pageOpen: {
            [theme.breakpoints.up('sm')]: {
                marginLeft: drawerWidth,
            },
        },
        pageClose: {
            [theme.breakpoints.up('sm')]: {
                marginLeft: drawerShortWidth,
            },
        },
        titleBar: {
            backgroundColor: '#F6F7F9',
        },
        title: {
            flexGrow: 1,
            fontWeight: 500,
            color: '#383C56',
        },
        appBar: {
            color: '#000000',
        },
        appBarOpen: {
            [theme.breakpoints.up('sm')]: {
                marginLeft: drawerWidth,
            },
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        appBarClose: {
            [theme.breakpoints.up('sm')]: {
                marginLeft: drawerShortWidth,
            },
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
        },
        content: {
            marginTop: 64,
            margin: '0 auto',
            backgroundColor: '#fafafa',
            height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
            [theme.breakpoints.down('xs')]: {
                marginTop: 112,
            },
        },
        barActions: {
            display: 'flex',
            alignItems: 'center',
            gap: 10,
        },
    })
);

type PageTitleProps = {
    title: string;
    actionIcons?: JSX.Element;
};

const Page: React.FC<PageTitleProps> = ({ title, actionIcons, children }) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const { portalUalActionMenuEnabled } = useFlags();

    const handleShiftClick = () => {
        setOpen(!open);
    };

    const [showMobileDrawer, setShowMobileDrawer] = useState<boolean>(false);
    return (
        <main
            className={clsx(classes.page, {
                [classes.pageOpen]: open,
                [classes.pageClose]: !open,
            })}
        >
            <AppBar
                position="fixed"
                elevation={0}
                className={clsx(classes.appBar, {
                    [classes.appBarOpen]: open,
                    [classes.appBarClose]: !open,
                })}
            >
                <Hidden smUp implementation="css">
                    <MobileNavBar
                        onMobileDrawerToggle={() => setShowMobileDrawer(!showMobileDrawer)}
                    />
                    <NavDrawer
                        onShiftClick={() => {}}
                        open={false}
                        openMobileDrawer={showMobileDrawer}
                        onMobileDrawerClose={() => setShowMobileDrawer(!showMobileDrawer)}
                    />
                </Hidden>
                <Toolbar
                    className={clsx(classes.titleBar, {
                        [classes.appBarOpen]: open,
                        [classes.appBarClose]: !open,
                    })}
                >
                    <Typography variant={'h6'} className={classes.title}>
                        {title}
                    </Typography>
                    <Hidden xsDown implementation="css">
                        <div className={classes.barActions}>
                            {portalUalActionMenuEnabled && <ActivityLogActionMenu />}
                            <ProfileStrip />
                        </div>
                    </Hidden>
                    <Hidden smUp implementation="css">
                        {actionIcons}
                    </Hidden>
                </Toolbar>
            </AppBar>
            <section
                className={clsx(classes.content, {
                    [classes.pageOpen]: open,
                    [classes.pageClose]: !open,
                })}
            >
                <NavDrawer
                    onShiftClick={handleShiftClick}
                    open={open}
                    openMobileDrawer={false}
                    onMobileDrawerClose={() => {}}
                />
                {children}
            </section>
        </main>
    );
};

export default Page;
