import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Farm } from '../../farms/farmType';
import { DateTime } from 'luxon';
import { RootState } from '../../../app/store';

export interface FiltersState {
    from: string;
    to: string;
    farm: Farm;
    timeZone: string;
    targets: {
        preGrazingTarget: number;
        postGrazingTarget: number;
        preCuttingTarget: number;
    };
}

export const initialState: FiltersState = {
    from: DateTime.local().minus({ days: 6 }).toISO(),
    to: DateTime.local().toISO(),
    farm: {
        farmId: 0,
        farmGroupId: 0,
        farmName: 'Select One',
        timeZone: DateTime.local().zoneName,
        epsg: null,
        hasCuttingGroup: false,
    },
    timeZone: DateTime.local().zoneName,
    targets: {
        preGrazingTarget: 2850,
        postGrazingTarget: 1500,
        preCuttingTarget: 5000,
    },
};

export const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        apply: (state, action: PayloadAction<FiltersState>) => {
            return action.payload;
        },
        clear: () => {
            return initialState;
        },
    },
});

export const { apply, clear } = filtersSlice.actions;

export const selectFilters = (state: RootState) => state.filters;

export default filtersSlice.reducer;

export const selectCurrentFarmId = createSelector(selectFilters, (state) => state.farm.farmId);
