import React, { Fragment } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ZoneGroupGrowthDisplayable } from '../../types/paddockMeasurementTypes';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { Typography } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { selectMeasurementByDate, selectPaddock } from '../../reducers/paddockMeasurementsSlice';
import MeasurementRowMobile from './MeasurementRowMobile';
import { getGraphColor } from 'utils/GraphColors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: 200,
            overflowX: 'scroll',
        },
        button: {
            margin: theme.spacing(1),
        },
        inactiveRow: {
            backgroundColor: '#eeeeee',
        },
        checkButtonCell: {
            paddingRight: 0,
        },
    })
);

type SingleDayMeasurementsMobileProps = {
    forDate: Date;
};

export default function SingleDayMeasurementsMobile({ forDate }: SingleDayMeasurementsMobileProps) {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const measurement = useAppSelector((state) =>
        selectMeasurementByDate(state, forDate?.toISOString())
    );
    const selectedPaddockId = useAppSelector(
        (state) => state.paddockMeasurements.selectedPaddockId
    );

    if (!measurement) {
        return <div>No Measurment Found for the given Date</div>;
    }

    return (
        <div className={classes.root}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ padding: 0, width: '12rem' }} />
                        <TableCell style={{ padding: 0, width: '4rem' }} align="center">
                            <Typography variant="caption">Cover</Typography>
                        </TableCell>
                        <TableCell style={{ padding: 0, width: '4rem' }} align="center">
                            <Typography variant="caption">Avail.</Typography>
                            <br />
                            <Typography variant="caption">Cover</Typography>
                        </TableCell>
                        <TableCell style={{ padding: 0, width: '4rem' }} align="center">
                            <Typography variant="caption">Avail.</Typography>
                            <br />
                            <Typography variant="caption">Feed</Typography>
                        </TableCell>
                        <TableCell />
                    </TableRow>
                    <MeasurementRowMobile
                        row={{
                            name: `Farm Average`,
                            cover: measurement.aggregate.average_cover,
                            availableCover: measurement.aggregate.average_available_cover,
                            availableFeed: `Total: ${measurement.aggregate.total_available_feed}`,
                            dailyGrowthRate: measurement.aggregate.average_daily_growth_rate,
                            growth3Days: measurement.aggregate.average_growth_3_days,
                            growth7Days: measurement.aggregate.average_growth_7_days,
                            effectiveArea: measurement.aggregate.total_effective_area,
                            color: '',
                            isBold: false,
                        }}
                    />
                </TableHead>
                <TableBody>
                    {measurement.zone_groups.map(
                        (zg: ZoneGroupGrowthDisplayable, index: number) => (
                            <Fragment key={zg.zone_group}>
                                {!zg.is_active && (
                                    <TableRow
                                        style={{ backgroundColor: getGraphColor(index).heading }}
                                    >
                                        <TableCell align="center" colSpan={9}>
                                            <Typography
                                                variant={'body2'}
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                {zg.zone_group}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {zg.is_active && (
                                    <>
                                        <MeasurementRowMobile
                                            row={{
                                                name: `${zg.zone_group} Group Average`,
                                                cover: zg.aggregate.average_cover,
                                                availableCover:
                                                    zg.aggregate.average_available_cover,
                                                availableFeed: `Total: ${zg.aggregate.total_available_feed}`,
                                                dailyGrowthRate:
                                                    zg.aggregate.average_daily_growth_rate,
                                                growth3Days: zg.aggregate.average_growth_3_days,
                                                growth7Days: zg.aggregate.average_growth_7_days,
                                                effectiveArea: zg.aggregate.total_effective_area,
                                                color: getGraphColor(index).aggregate,
                                                isBold: true,
                                            }}
                                        />
                                        {zg.growths.map((g) => (
                                            <MeasurementRowMobile
                                                key={g.paddock_id}
                                                row={{
                                                    name: g.paddock_name,
                                                    cover: g.cover,
                                                    availableCover: g.available_cover,
                                                    availableFeed: g.available_feed,
                                                    dailyGrowthRate: g.daily_growth_rate,
                                                    growth3Days: g.growth_3_days,
                                                    growth7Days: g.growth_7_days,
                                                    effectiveArea: g.effective_area,
                                                    color:
                                                        g.paddock_id === selectedPaddockId
                                                            ? '#fff59d'
                                                            : getGraphColor(index).rows,
                                                    isBold: false,
                                                }}
                                                onClick={() =>
                                                    dispatch(selectPaddock(g.paddock_id))
                                                }
                                            />
                                        ))}
                                    </>
                                )}
                                {!zg.is_active &&
                                    zg.growths.map((g) => (
                                        <TableRow key={g.zone_name}>
                                            <TableCell style={{ padding: 0, paddingLeft: 6 }}>
                                                {g.paddock_name}
                                            </TableCell>
                                            <TableCell style={{ padding: 0 }} align="center" />
                                            <TableCell style={{ padding: 0 }} align="center" />
                                            <TableCell style={{ padding: 0 }} align="center" />
                                            <TableCell style={{ padding: 0 }} align="right" />
                                        </TableRow>
                                    ))}
                            </Fragment>
                        )
                    )}
                </TableBody>
            </Table>
        </div>
    );
}
