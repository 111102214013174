import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { FiltersState } from 'features/measurements/filters/filtersSlice';
import { middayUtcStr, fromStrISOToDateTime, DATE_FORMATS } from 'utils/DateUtil';
import { downloadParkMeasurements, getParkMeasurements } from '../api/parkMeasurementsApi';
import { ParkMeasurement } from '../types/parkMeasurementTypes';
import FileDownload from 'js-file-download';

export interface ParkMeasurementsState {
    measurements: ParkMeasurement[];
    downloadableMeasurements: any;
    fetchStatus: string;
    error: string;
}

export const initialState: ParkMeasurementsState = {
    measurements: [],
    downloadableMeasurements: [],
    fetchStatus: 'idle',
    error: '',
};

export const fetchParkMeasurements = createAsyncThunk(
    'parks/fetchParkMeasurements',
    async (filters: FiltersState): Promise<ParkMeasurement[]> => {
        return await getParkMeasurements(
            middayUtcStr(
                fromStrISOToDateTime(filters.from).toFormat(DATE_FORMATS.yearMonthDayShort),
                filters.farm.timeZone
            ),
            middayUtcStr(
                fromStrISOToDateTime(filters.to).toFormat(DATE_FORMATS.yearMonthDayShort),
                filters.farm.timeZone
            ),
            filters.farm.farmId
        );
    }
);

export const fetchDownloadParkMeasurements = createAsyncThunk(
    'paddocks/fetchDownloadPaddockMeasurements',
    async (filters: FiltersState): Promise<void> => {
        return await downloadParkMeasurements(
            middayUtcStr(
                fromStrISOToDateTime(filters.from).toFormat(DATE_FORMATS.yearMonthDayShort),
                filters.farm.timeZone
            ),
            middayUtcStr(
                fromStrISOToDateTime(filters.to).toFormat(DATE_FORMATS.yearMonthDayShort),
                filters.farm.timeZone
            ),
            filters.farm.farmId,
            filters.farm.farmName
        );
    }
);

export const parkMeasurementsSlice = createSlice({
    name: 'parkPaddocks',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchParkMeasurements.pending.type]: (state: ParkMeasurementsState) => {
            state.measurements = [];
            state.fetchStatus = 'loading';
        },
        [fetchParkMeasurements.fulfilled.type]: (
            state: ParkMeasurementsState,
            action: PayloadAction<ParkMeasurement[]>
        ) => {
            state.measurements = action.payload;
            state.fetchStatus = 'succeeded';
        },
        [fetchParkMeasurements.rejected.type]: (
            state: ParkMeasurementsState,
            action: PayloadAction<string>
        ) => {
            state.measurements = [];
            state.fetchStatus = 'failed';
            state.error = action.payload;
        },
        [fetchDownloadParkMeasurements.fulfilled.type]: (
            state: ParkMeasurementsState,
            action: PayloadAction<any>
        ) => {
            state.downloadableMeasurements = (action.payload as any).data;
            FileDownload((action.payload as any).data, (action.payload as any).fileName);
        },
    },
});

export const selectAllMeasurements = (state: RootState) => state.parkMeasurements.measurements;
export const selectMeasurementByDate = (state: RootState, date: string) =>
    selectAllMeasurements(state).find((m) => new Date(m.date)?.toISOString() === date);

export default parkMeasurementsSlice.reducer;
