import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Plotly from 'plotly.js-basic-dist';
import createPlotlyComponent from 'react-plotly.js/factory';
import { MultiWedgesModel } from '../measurements/types/multiWedgesModel';
import { getGraphColor } from '../../../utils/GraphColors';
import _ from 'lodash';
import { PaddockCover } from '../measurements/types/paddockMeasurementTypes';

const useStyles = makeStyles(() => ({
    root: {
        margin: 0,
        overflowX: 'scroll',
        width: '100%',
    },
}));

type MultiWedgesModelProps = MultiWedgesModel & {
    targetMin: number;
    targetMax: number;
};

export default function Index({ growths, targetMin, targetMax }: MultiWedgesModelProps) {
    const classes = useStyles();
    const Plot = createPlotlyComponent(Plotly);

    if (!growths) return <></>;

    const covers = _.map(growths, (g) => g.covers).reduce((p, n, i) => [...p, ...n]);

    const feedData = _.map(covers, (c) => createFeed(c)).sort((a, b) => b?.y - a?.y);

    let area = 0;
    const chartData = _.map(feedData, (c, i) => {
        const x = area + c.cover.area / 2;
        area += c.cover.area;
        return {
            text: c.cover.zone,
            name: c.cover.zoneName,
            y: c.y,
            x: x,
            w: c.cover.area,
        };
    });

    const xLine = _.map(chartData, (c) => c.x);
    const zones = _.groupBy(chartData, (f) => f.name);

    const linesMarker: any[] = [
        {
            type: 'scatter',
            mode: 'lines+markers',
            name: 'target',
            showlegend: false,
            x: [0, _.max(xLine)],
            y: [targetMax, targetMin],
            text: chartData,
            textposition: 'auto',
            hoverinfo: 'none',
            marker: { color: '#086105' },
        },
    ];
    let i = 0;
    const data: any[] = _.concat(
        linesMarker,
        _.map(zones, (z, p) => {
            let x: any[] = [];
            let y: any[] = [];
            let t: any[] = [];
            let w: any[] = [];

            _.forEach(z, (f) => {
                x.push(f.x);
                y.push(f.y);
                t.push(f.text);
                w.push(f.w);
            });

            return createBarChart(p, x, y, t, w, getGraphColor(i++).heading);
        })
    );

    return (
        <div className={classes.root}>
            <Plot
                data={data}
                layout={{
                    title: 'Paddock Groups',
                    showlegend: true,
                    xaxis: { visible: true, fixedrange: true },
                    legend: {
                        x: 1,
                        y: 1,
                        xanchor: 'right',
                    },
                    yaxis: { title: 'Cover (kgDM/ha)', fixedrange: true },
                    dragmode: 'pan',
                    width: 900,
                }}
                config={{
                    modeBarButtonsToRemove: ['lasso2d', 'select2d'],
                }}
            />
        </div>
    );
}

function createFeed(c: PaddockCover) {
    return {
        y: c.cover,
        x: c.area,
        cover: c,
    };
}

function createBarChart(p: string, x: any[], y: any[], t: any[], w: any[], color: string): any {
    return {
        type: 'bar',
        name: p,
        x: x,
        y: y,
        text: t,
        hovertext: t,
        width: w,
        showlegend: true,
        hoverinfo: 'text',
        marker: {
            color: color,
            line: {
                color: '#ffffff',
                width: 1,
            },
        },
    };
}
