import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Scene } from './Scene';
import { batchScenes, downloadScenes } from './scenesApi';

type ProgressState = {
    loading: boolean;
    status: string;
    error: string | null;
};

const defaultStatus: ProgressState = {
    loading: false,
    status: 'idle',
    error: null,
};

interface ScenesState {
    batchResponse: any;
    batchScenesStatus: ProgressState;
    showSnackbar: boolean;
}

const initialState: ScenesState = {
    batchResponse: null,
    batchScenesStatus: defaultStatus,
    showSnackbar: false,
};

export const downloadScene = createAsyncThunk(
    'scenes/downloadScene',
    async (scene: Scene): Promise<void> => await downloadScenes([scene])
);

type BatchScenesProps = {
    processIds: string[];
    wdrreWeight: number;
    wdrreLimit: number;
};

export const fetchBatchScenes = createAsyncThunk(
    'scenes/processBatch',
    async ({ processIds, wdrreWeight, wdrreLimit }: BatchScenesProps): Promise<void> =>
        await batchScenes(processIds, wdrreWeight, wdrreLimit)
);

export const scenesSlice = createSlice({
    name: 'scenes',
    initialState,
    reducers: {
        closeSnackbar(state) {
            state.showSnackbar = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBatchScenes.pending.type, (state: ScenesState) => {
                state.batchScenesStatus = {
                    loading: true,
                    status: 'loading',
                    error: null,
                };
                state.showSnackbar = false;
            })
            .addCase(
                fetchBatchScenes.fulfilled.type,
                (state: ScenesState, action: PayloadAction<any>) => {
                    state.batchScenesStatus = {
                        loading: false,
                        status: 'succeeded',
                        error: null,
                    };
                    state.batchResponse = action.payload;
                    state.showSnackbar = true;
                }
            )
            .addCase(
                fetchBatchScenes.rejected.type,
                (state: ScenesState, action: PayloadAction<any>) => {
                    state.batchScenesStatus = {
                        loading: false,
                        status: 'failed',
                        error: action.payload ?? 'Something went wrong!',
                    };
                    state.showSnackbar = true;
                }
            );
    },
});

export const { closeSnackbar } = scenesSlice.actions;

export default scenesSlice.reducer;
