import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

type Row = {
    name: string;
    cover: number | '-';
    availableCover: number | '-';
    availableFeed: number | '-' | undefined | string;
    dailyGrowthRate: number | '-' | undefined;
    growth3Days: number | '-';
    growth7Days: number | '-';
    effectiveArea: number | '-' | undefined;
    color: string;
    isBold: boolean;
};

type MeasurementRowMobileProps = {
    row: Row;
    onClick?: () => void;
};

export default function MeasurementRowMobile({ row, onClick }: MeasurementRowMobileProps) {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <TableRow style={{ backgroundColor: row.color }} onClick={onClick}>
                <TableCell style={{ padding: 0, paddingLeft: 6 }}>
                    {row.isBold ? <strong>{row.name}</strong> : row.name}
                </TableCell>
                <TableCell style={{ padding: 0 }} align="center">
                    {row.isBold ? <strong>{row.cover}</strong> : row.cover}
                </TableCell>
                <TableCell style={{ padding: 0 }} align="center">
                    {row.isBold ? <strong>{row.availableCover}</strong> : row.availableCover}
                </TableCell>
                <TableCell style={{ padding: 0 }} align="center">
                    {row.isBold ? <strong>{row.availableFeed}</strong> : row.availableFeed}
                </TableCell>
                <TableCell style={{ padding: 0 }} align="right">
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" colSpan={3}>
                                        <Typography variant="caption">Growth Rate</Typography>
                                    </TableCell>
                                    <TableCell align="right" rowSpan={2}>
                                        <Typography variant="caption">Effective Area</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="right">
                                        <Typography variant="caption">Daily</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography variant="caption">3 Days</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography variant="caption">7 Days</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="right">{row.dailyGrowthRate}</TableCell>
                                    <TableCell align="right">{row.growth3Days}</TableCell>
                                    <TableCell align="right">{row.growth7Days}</TableCell>
                                    <TableCell align="right">{row.effectiveArea}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
