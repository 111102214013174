import {
    CircularProgress,
    createStyles,
    IconButton,
    List,
    ListItem,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { useAppSelector } from 'app/hooks';
import { selectCurrentFarmId } from 'features/measurements/filters/filtersSlice';
import { orderBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { ActivityLogItem } from './ActivityLogItem';
import { getActivityLog } from './api/getActivityLog';
import { UserActivityLogModel } from './api/UserActivityLogModel';
import { mergeEvents } from './activityLogUtil';
import { EventType } from 'components/activity-log/EventType';
import { selectPaddocks } from 'features/paddocks/paddock.selectors';
import { selectPaddockGroups } from 'features/paddock-groups/paddockGroups.selectors';

const NoData = () => {
    return <div style={{ textAlign: 'center', padding: 30 }}>No records found</div>;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pagination: {
            textAlign: 'center',
        },
        loading: {
            textAlign: 'center',
            paddingTop: 50,
            paddingBottom: 50,
        },
    })
);

// const LIMIT = 10;

export const ActivityLogActionMenuContent = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [activityLogs, setActivityLogs] = useState<UserActivityLogModel<any>[]>();
    const currentFarmId = useAppSelector(selectCurrentFarmId);

    const paddocks = useAppSelector(selectPaddocks);
    const paddockGroups = useAppSelector(selectPaddockGroups);

    const getFriendlyDescription = useCallback(
        ({ eventType, payloadIdentifier, payload }: UserActivityLogModel<any>) => {
            const getPaddockNameFromZoneId = (zoneId: string) => {
                const paddock = paddocks?.find((p) =>
                    p.zones?.some((z) => z.zoneId.toString() === zoneId)
                );

                return paddock?.paddockName ?? `zone ${zoneId}`;
            };

            const getPaddockName = (paddockId: string) => {
                const paddock = paddocks?.find((p) => p.paddockId.toString() === paddockId);

                return paddock?.paddockName ?? `Paddock ${paddockId}`;
            };

            const getPaddockGroupName = (groupId: string) => {
                const pg = paddockGroups?.find((g) => g.groupId.toString() === groupId);

                return pg?.groupName ?? groupId;
            };

            switch (eventType) {
                case EventType.PaddockMoved:
                    return `${getPaddockName(payloadIdentifier)} moved to ${getPaddockGroupName(
                        payload.paddockGroupId
                    )} group`;
                case EventType.PaddockMoveDeleted:
                    return `Paddock moves deleted on ${getPaddockName(payloadIdentifier)}`;
                case EventType.ZoneGrazed:
                    return `Grazing reported on ${getPaddockNameFromZoneId(payload.zoneId)}`;
                case EventType.ZoneGrazeDeleted:
                    return `Grazing deleted on ${getPaddockNameFromZoneId(payload.zoneId)}`;
                default:
                    const parts = eventType.split('.');
                    return `${parts[parts.length - 1]} on ${payloadIdentifier}`;
            }
        },
        [paddocks, paddockGroups]
    );

    const hasPrevious = page > 1;
    // const hasNext = !!activityLogs && activityLogs.length >= LIMIT;

    useEffect(() => {
        const fetch = async () => {
            const data = await getActivityLog({ page, farmIds: [currentFarmId], limit: 10 });
            const mergedList = mergeEvents(data, getFriendlyDescription);
            const logs = orderBy(mergedList, ['eventTime', (p) => p.displayName], ['desc', 'asc']);
            setActivityLogs(logs);
            setLoading(false);
        };

        if (!currentFarmId) return;

        setLoading(true);
        fetch();
    }, [page, currentFarmId, getFriendlyDescription]);

    const handleNextClick = () => {
        setPage((p) => p + 1);
    };

    const handlePreviousClick = () => {
        setPage((p) => p - 1);
    };

    if (loading) {
        return (
            <div className={classes.loading}>
                <CircularProgress />
            </div>
        );
    }

    if (!activityLogs || activityLogs.length === 0) {
        return <NoData />;
    }

    return (
        <>
            <List>
                {activityLogs.map((item) => (
                    <ListItem key={item.id}>
                        <ActivityLogItem activityLog={item} />
                    </ListItem>
                ))}
            </List>

            <div className={classes.pagination}>
                {/* <IconButton disabled={!hasPrevious} onClick={handlePreviousClick}> */}
                <IconButton disabled={!hasPrevious} onClick={handlePreviousClick}>
                    <ArrowBack />
                </IconButton>
                {/* <IconButton disabled={!hasNext} onClick={handleNextClick}> */}
                <IconButton onClick={handleNextClick}>
                    <ArrowForward />
                </IconButton>
            </div>
        </>
    );
};
