import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import { DrawerItemType } from './DrawerItemType';
import DashboardIcon from '@material-ui/icons/Dashboard';
import NdviIcon from '@material-ui/icons/SatelliteSharp';
import OnboardingIcon from '@material-ui/icons/Power';
import { CompareArrows } from '@material-ui/icons';

export const Items: DrawerItemType[] = [
    {
        icon: <DashboardIcon />,
        text: 'Dashboard',
        link: '/',
        isAdmin: false,
    },
    {
        icon: <NdviIcon />,
        text: 'NDVI',
        link: '/ndvi/tracker',
        isAdmin: true,
    },
    {
        icon: <SettingsIcon />,
        text: 'Settings',
        link: '/settings',
        isAdmin: true,
    },
    {
        icon: <OnboardingIcon />,
        text: 'Onboarding',
        link: '/onboard',
        isAdmin: true,
    },
    {
        icon: <CompareArrows />,
        text: 'Mote Associations',
        link: '/mote/associations',
        isAdmin: true,
    },
];
