import React, { useState } from 'react';
import { useAppSelector } from 'app/hooks';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { selectFilters } from 'features/measurements/filters/filtersSlice';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getPaddockAsDictionary } from 'features/paddocks/paddock.selectors';
import { getPaddockGroupsAsDictionary } from 'features/paddock-groups/paddockGroups.selectors';
import { PaddockMoveHistoryContent } from 'features/paddock-history/PaddockMoveHistory';
import Add from './Add';
import Move from './Move';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: theme.spacing(2),
        },
    })
);

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`actions-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `actions-tab-${index}`,
        'aria-controls': `actions-tabpanel-${index}`,
    };
}

export default function PaddockGroups() {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [refreshPaddocks, setRefreshPaddocks] = useState(false);
    const { paddockMovedHistoryEnabled } = useFlags();

    const filters = useAppSelector(selectFilters);

    const farmPaddocks = useAppSelector(getPaddockAsDictionary);
    const groups = useAppSelector(getPaddockGroupsAsDictionary);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Move" {...a11yProps(0)} />
                <Tab label="Add" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Move refresh={refreshPaddocks} />
                {paddockMovedHistoryEnabled && (
                    <PaddockMoveHistoryContent
                        farmId={filters.farm.farmId}
                        paddocks={farmPaddocks}
                        paddockGroups={groups}
                        setRefreshPaddocks={setRefreshPaddocks}
                    />
                )}
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Add />
            </TabPanel>
        </div>
    );
}
