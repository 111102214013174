import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Farm } from './farmType';
import { getFarms } from './farmsApi';
import { RootState } from '../../app/store';

interface FarmsState {
    farms: Farm[];
    loading: boolean;
    status: string;
    error: string | null;
}

export const fetchFarmsByFarmGroup = createAsyncThunk(
    'farms/fetchAllFarms',
    async (farmGroupId: number) => await getFarms(farmGroupId)
);

const initialState: FarmsState = {
    farms: [],
    loading: false,
    status: 'idle',
    error: null,
};

export const farmsSlice = createSlice({
    name: 'farms',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchFarmsByFarmGroup.pending.type]: (state: FarmsState) => {
            state.loading = true;
            state.status = 'loading';
            state.error = null;
        },
        [fetchFarmsByFarmGroup.fulfilled.type]: (
            state: FarmsState,
            action: PayloadAction<Farm[]>
        ) => {
            state.farms = action.payload;
            state.loading = false;
            state.status = 'succeeded';
            state.error = null;
        },
        [fetchFarmsByFarmGroup.rejected.type]: (
            state: FarmsState,
            action: PayloadAction<string>
        ) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.payload;
        },
    },
});

export const selectFarmById = (state: RootState, farmId: number): Farm =>
    state.farms.farms.find((f) => f.farmId === farmId) ?? initialState.farms[0];

export default farmsSlice.reducer;
