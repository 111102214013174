import { fetch, del } from 'api/client';
import firebase from 'firebase/app';
import 'firebase/auth';

export const getPaddockHistoryByFarm = async <T>(req: {
    farmId: string;
    eventType: string;
    page: number;
    limit: number;
}): Promise<T> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    const queryParams = `?page=${req.page}&limit=${req.limit}&sortBy=-payload.date&filterBy={"$and":[{"payload.farmId":"${req.farmId}"}]}`;
    const request = {
        token: token!,
        baseApiUri: `${process.env.REACT_APP_UAL_API_URL}/api`,
        endpointUri: `paddock_move_history${queryParams}`,
    };

    const { result } = await fetch(request);
    return result;
};

export const deletePaddockMovesFrom = async <T>(req: {
    paddockId: string;
    groupId: string;
    from: string;
    farmId: string;
}): Promise<T> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    const { result } = await del(
        token!,
        `paddock-groups/paddock/${req.paddockId}/${req.groupId}/from/${req.from}/farm/${req.farmId}`,
        {}
    );
    return result;
};
