import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { StyledTab, StyledTabs } from '../../../../components/StyledTabs';
import { a11yProps, TabPanel } from '../../../../components/TabPanel';
import Divider from '@material-ui/core/Divider';
import { Paper } from '@material-ui/core';
import Input from './Input';
import Output from './Output';
import { fetchInput, fetchOutput } from './regressionSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { shallowEqual } from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginTop: theme.spacing(2),
        },
    })
);

type DataProps = {
    batchId: string;
};

export default function Data({ batchId }: DataProps) {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const [selectedTab, setSelectedTab] = useState(0);
    const wdrreWeight = 0.1;
    const wdrreLimit = 85;

    const { input, output } = useAppSelector((state) => {
        return {
            input: state.regression.input,
            output: state.regression.output,
        };
    }, shallowEqual);

    useEffect(() => {
        dispatch(
            fetchInput({ batchId: batchId, wdrreWeight: wdrreWeight, wdrreLimit: wdrreLimit })
        );
        dispatch(fetchOutput(batchId));
    }, [batchId, dispatch]);

    if (!batchId) {
        return null;
    }

    return (
        <Paper className={classes.paper}>
            <Box padding={1} paddingBottom={0}>
                <StyledTabs
                    value={selectedTab}
                    onChange={(_, newTab) => setSelectedTab(newTab)}
                    aria-label="disabled tabs example"
                >
                    <StyledTab label="Input" {...a11yProps(0)} />
                    <StyledTab label="Output" {...a11yProps(1)} />
                </StyledTabs>
            </Box>
            <Divider />
            <TabPanel value={selectedTab} index={0}>
                <Input input={input} />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
                <Output output={output} />
            </TabPanel>
        </Paper>
    );
}
