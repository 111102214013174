import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Mote } from './moteType';
import { getMotes, getAllMotes } from './motesApi';
import { RootState } from '../../app/store';

interface MotesState {
    motes: Mote[];
    loading: boolean;
    status: string;
    error: string | null;
}

type fetchMotesByFarmType = {
    farmGroupId: number;
    from: Date;
    to: Date;
};

type fetchMotesByFarmGroupIdType = {
    farmGroupId: number;
};

export const fetchMotesByFarmGroup = createAsyncThunk(
    'motes/fetchAllMotes',
    async ({ farmGroupId, from, to }: fetchMotesByFarmType) => await getMotes(farmGroupId, from, to)
);

export const fetchMotesByFarmGroupId = createAsyncThunk(
    'motes/fetchAllMotesByFarmGroupId',
    async ({ farmGroupId }: fetchMotesByFarmGroupIdType) => await getAllMotes(farmGroupId)
);

const initialState: MotesState = {
    motes: [],
    loading: false,
    status: 'idle',
    error: null,
};

export const motesSlice = createSlice({
    name: 'motes',
    initialState,
    reducers: {
        reset: (state) => {
            state.motes = [];
            state.loading = false;
            state.status = 'idle';
            state.error = null;
        },
    },
    extraReducers: {
        [fetchMotesByFarmGroup.pending.type]: (state: MotesState) => {
            state.loading = true;
            state.status = 'loading';
            state.error = null;
        },
        [fetchMotesByFarmGroup.fulfilled.type]: (
            state: MotesState,
            action: PayloadAction<Mote[]>
        ) => {
            state.motes = action.payload;
            state.loading = false;
            state.status = 'succeeded';
            state.error = null;
        },
        [fetchMotesByFarmGroup.rejected.type]: (
            state: MotesState,
            action: PayloadAction<string>
        ) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.payload;
        },
        [fetchMotesByFarmGroupId.pending.type]: (state: MotesState) => {
            state.loading = true;
            state.status = 'loading';
            state.error = null;
        },
        [fetchMotesByFarmGroupId.fulfilled.type]: (
            state: MotesState,
            action: PayloadAction<Mote[]>
        ) => {
            state.motes = action.payload;
            state.loading = false;
            state.status = 'succeeded';
            state.error = null;
        },
        [fetchMotesByFarmGroupId.rejected.type]: (
            state: MotesState,
            action: PayloadAction<string>
        ) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.payload;
        },
    },
});

export const selectMoteById = (state: RootState, moteId: number): Mote =>
    state.motes.motes.find((m) => m.moteId === moteId) ?? initialState.motes[0];
export const selectAllMotes = (state: RootState): Mote[] => state.motes.motes;
export const { reset } = motesSlice.actions;

export default motesSlice.reducer;
