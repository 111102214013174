import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ZoneGrazedEvent } from './grazingsHistoryType';
import { getActiveGrazingsHistoryByFarm } from './grazingsHistoryApi';
import { useFriendlyErrorHandler } from '../notifications/hooks';
import { STATUS } from 'utils/Store/Constants';
import { EventType } from 'components/activity-log/EventType';

interface Status {
    loading: boolean;
    status: string;
    error: string | null;
}

export interface GrazingsHistoryState {
    grazingReported: ZoneGrazedEvent[];
    fetchStatus: Status;
    deleteStatus: Status;
}

const initialStatus: Status = {
    loading: false,
    status: STATUS.idle,
    error: null,
};

export const initialState: GrazingsHistoryState = {
    grazingReported: [],
    fetchStatus: initialStatus,
    deleteStatus: initialStatus,
};

export type GrazingsHistoryArgs = {
    farmId: string;
    page: number;
    limit: number;
};

export const fetchGrazingsHistory = createAsyncThunk(
    'grazingsHistory/fetchGrazingsHistory',
    async ({ farmId, page, limit }: GrazingsHistoryArgs, { rejectWithValue, dispatch }) => {
        const callbackFnc = async () =>
            await getActiveGrazingsHistoryByFarm({
                farmId,
                page,
                limit,
                eventType: EventType.ZoneGrazed,
            }).catch((err) =>
                Promise.reject({ message: 'We were unable to retrieve the zone grazed history' })
            );

        return await useFriendlyErrorHandler(callbackFnc, rejectWithValue, dispatch, {
            severity: 'warning',
        });
    }
);

export const grazingsHistorySlice = createSlice({
    name: 'grazingsHistory',
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: {
        [fetchGrazingsHistory.pending.type]: (state: GrazingsHistoryState) => {
            state.fetchStatus = {
                loading: true,
                status: STATUS.loading,
                error: null,
            };
        },
        [fetchGrazingsHistory.fulfilled.type]: (
            state: GrazingsHistoryState,
            action: PayloadAction<ZoneGrazedEvent[]>
        ) => {
            state.grazingReported = action.payload;
            state.fetchStatus = {
                loading: false,
                status: STATUS.succeeded,
                error: null,
            };
        },
        [fetchGrazingsHistory.rejected.type]: (
            state: GrazingsHistoryState,
            action: PayloadAction<string>
        ) => {
            state.fetchStatus = {
                loading: false,
                status: STATUS.failed,
                error: action.payload,
            };
        },
    },
});
export const { reset } = grazingsHistorySlice.actions;
export default grazingsHistorySlice.reducer;
