import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useAppSelector } from '../../../../app/hooks';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import green from '@material-ui/core/colors/green';
import Icon from '@material-ui/core/Icon';
import Indigo from '@material-ui/core/colors/indigo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: Indigo[50],
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    })
);

export default function DownloadProgress() {
    const classes = useStyles();

    useFirestoreConnect([
        {
            collection: 'ndvi-process-tracking',
            storeAs: 'scenesInProgress',
            where: [['has_ndvi_run', '==', false]],
        },
    ]);
    const scenesInProgress = useAppSelector(
        (state) => (state.firestore as any).data?.scenesInProgress
    );

    if (Object.entries(scenesInProgress ?? {}).length === 0) {
        return null;
    }

    return (
        <Accordion className={classes.root}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Box display="flex" gridColumnGap={8}>
                    <Typography className={classes.heading}>Download & NDVI in Progress</Typography>
                    <CircularProgress size={20} />
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box display="flex" flexWrap="wrap" flexDirection="column" gridRowGap={8}>
                    {Object.entries(scenesInProgress ?? {}).map(([sid, s]: [string, any]) => (
                        <>
                            <Box>
                                <Typography variant="subtitle2">
                                    <strong>
                                        {sid} - {s.farm_name}
                                    </strong>
                                </Typography>
                            </Box>
                            <Box>
                                {Object.entries(s.status ?? {}).map(([k, v]) => (
                                    <Box display="flex" gridColumnGap={8}>
                                        <Icon style={{ color: green[500] }} fontSize="small">
                                            check
                                        </Icon>
                                        <Typography variant="caption">
                                            {[...k?.split('/')].pop()}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </>
                    ))}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}
