import React from 'react';
import Page from '../../components/Page';
import PphProcessor from '../pph-processor';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import AutoMappings from '../auto-mapping';
import Grid from '@material-ui/core/Grid';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'} variant={'body2'}>
                        {children}
                    </Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `vertical-tab-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100%',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

export default function Settings() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Page title="Settings">
            <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                style={{ height: '100%' }}
            >
                <Grid item xs={3} md={2} style={{ backgroundColor: '#e8eaf6' }}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        className={classes.tabs}
                    >
                        <Tab label="PPH PROCESSOR" {...a11yProps(0)} />
                        <Tab label="AUTO MAPPING" {...a11yProps(1)} />
                    </Tabs>
                </Grid>
                <Grid item xs={9} md={10}>
                    <TabPanel value={value} index={0}>
                        <PphProcessor />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <AutoMappings />
                    </TabPanel>
                </Grid>
            </Grid>
        </Page>
    );
}
