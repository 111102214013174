import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { shallowEqual } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { fetchAllFarmGroups } from './farmGroupsSlice';
import { FarmGroup } from './farmGroupType';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Sizes } from 'utils/BaseStyles';
import { STATUS } from 'utils/Store/Constants';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: Sizes.width,
        },
    })
);

const build = (name: string): FarmGroup => {
    return {
        farmGroupId: 0,
        farmGroupName: name,
    };
};

type ParamProps = {
    refresh?: boolean;
    allowAdd?: boolean;
    preSelectedFarmGroup: FarmGroup | null;
    onSelect: (farmGroup: FarmGroup) => void;
    afterLoad?: () => void;
};

export default function FarmGroupsAutoComplete({
    refresh,
    allowAdd = false,
    preSelectedFarmGroup,
    afterLoad,
    onSelect,
}: ParamProps) {
    const classes = styles();
    const dispatch = useAppDispatch();

    const { loading, status, data } = useAppSelector((state) => {
        return {
            loading: state.farmGroups.loading,
            status: state.farmGroups.status,
            data: state.farmGroups.farmGroups,
        };
    }, shallowEqual);

    // OnMount
    useEffect(() => {
        if (status === STATUS.idle && data?.length === 0) {
            dispatch(fetchAllFarmGroups());
        }
    }, [afterLoad, dispatch, data, status]);

    useEffect(() => {
        if (refresh) {
            dispatch(fetchAllFarmGroups());
            if (afterLoad) afterLoad();
        }
    }, [dispatch, refresh, afterLoad]);

    const [value, setValue] = React.useState<FarmGroup | null | undefined>(null);
    useEffect(() => {
        setValue(preSelectedFarmGroup);
    }, [preSelectedFarmGroup]);

    if (allowAdd) {
        const optionsfilter = createFilterOptions<FarmGroup>();
        return (
            <Autocomplete
                id="farm-groups-add"
                options={data}
                loadingText="loading..."
                loading={loading}
                freeSolo
                selectOnFocus
                clearOnBlur
                openOnFocus
                handleHomeEndKeys
                className={classes.root}
                renderOption={(option) => option.farmGroupName}
                renderInput={(params) => (
                    <TextField {...params} label="FarmGroup" variant="outlined" />
                )}
                value={value}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    return option.farmGroupName;
                }}
                onChange={(_, newValue) => {
                    if (!newValue) return;

                    if (typeof newValue === 'string') {
                        onSelect(build(newValue));
                    } else {
                        onSelect(newValue);
                    }
                }}
                filterOptions={(options: FarmGroup[], params) => {
                    const filtered = optionsfilter(options, params);
                    if (params.inputValue) {
                        filtered.unshift(build(params.inputValue));
                    }
                    return filtered;
                }}
            />
        );
    }

    return (
        <Autocomplete
            id="farm-groups"
            loadingText="loading..."
            loading={loading}
            options={data}
            getOptionSelected={(a, b) => a.farmGroupId === b.farmGroupId}
            getOptionLabel={(option) => option.farmGroupName}
            renderInput={(params) => <TextField {...params} label="FarmGroup" variant="outlined" />}
            value={value}
            onChange={(event: any, newValue: any) => {
                setValue(newValue);
                onSelect(newValue);
            }}
            className={classes.root}
        />
    );
}
