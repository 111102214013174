import { Mote } from '../../../motes/moteType';

export enum AdjustmentTypes {
    EXCLUSION = 'Report Issue',
    GRAZING = 'Set New PGR',
    PREVIOUS_GRAZING = 'Reset to Previous PGR',
    NONE = 'Select One',
}

export const ReasonTypes = [
    'Mote or Spring is damaged',
    'Worn areas around Mote',
    'Uneven Pasture',
    'Other',
];

export type Adjustment = {
    mote: Mote;
    adjustment: AdjustmentTypes;
    reason: string;
    notes: string;
    begin: string;
    end: string;
    enteredPgr?: string;
    previousPgr?: string;
};
