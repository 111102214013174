import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { approveBatch, deleteBatch, estimateBatch, reprocessBatch } from './batchesApi';

type ProgressState = {
    loading: boolean;
    status: string;
    error: string | null;
};

interface BatchesState {
    selectedBatchId: string | null;
    showRegression: boolean;
    isApproved: boolean;
    approveStatus: ProgressState;
    isEstimateQueued: boolean;
    estimateStatus: ProgressState;
    isDeleted: boolean;
    deleteStatus: ProgressState;
    reprocessStatus: ProgressState;
}

const defaultStatus: ProgressState = {
    loading: false,
    status: 'idle',
    error: null,
};

const initialState: BatchesState = {
    selectedBatchId: null,
    showRegression: false,
    isApproved: false,
    approveStatus: defaultStatus,
    isEstimateQueued: false,
    estimateStatus: defaultStatus,
    isDeleted: false,
    deleteStatus: defaultStatus,
    reprocessStatus: defaultStatus,
};

export const fetchApproveBatch = createAsyncThunk(
    'batches/approveBatch',
    async ({
        batchId,
        type,
    }: {
        batchId: string;
        type: 'NDRE' | 'NDVI' | 'WDRRE';
    }): Promise<{ isApproved: boolean }> => await approveBatch(batchId, type)
);

export const fetchEstimateBatch = createAsyncThunk(
    'batches/estimateBatch',
    async (batchId: string): Promise<{ isQueued: boolean }> => await estimateBatch(batchId)
);

export const fetchDeleteBatch = createAsyncThunk(
    'batches/deleteBatch',
    async (batchId: string): Promise<{ isDeleted: boolean }> => await deleteBatch(batchId)
);

type ReprocessBatchProps = {
    batchId: string;
    wdrreWeight: number;
    wdrreLimit: number;
};

export const fetchReprocessBatch = createAsyncThunk(
    'batches/reprocessBatch',
    async ({
        batchId,
        wdrreWeight,
        wdrreLimit,
    }: ReprocessBatchProps): Promise<{ isQueued: boolean }> =>
        await reprocessBatch(batchId, wdrreWeight, wdrreLimit)
);

export const batchesSlice = createSlice({
    name: 'batches',
    initialState,
    reducers: {
        selectBatchId(state, action: PayloadAction<string | null>) {
            state.selectedBatchId = action.payload;
            state.showRegression = !!action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchApproveBatch.pending.type, (state: BatchesState) => {
                state.approveStatus = {
                    loading: true,
                    status: 'loading',
                    error: null,
                };
            })
            .addCase(
                fetchApproveBatch.fulfilled.type,
                (state: BatchesState, action: PayloadAction<{ isApproved: boolean }>) => {
                    state.approveStatus = {
                        loading: false,
                        status: 'succeeded',
                        error: null,
                    };
                    state.isApproved = action.payload?.isApproved;
                }
            )
            .addCase(
                fetchApproveBatch.rejected.type,
                (state: BatchesState, action: PayloadAction<string>) => {
                    state.approveStatus = {
                        loading: false,
                        status: 'failed',
                        error: action.payload ?? 'Something went wrong!',
                    };
                    state.isApproved = false;
                }
            )
            .addCase(fetchEstimateBatch.pending.type, (state: BatchesState) => {
                state.estimateStatus = {
                    loading: true,
                    status: 'loading',
                    error: null,
                };
            })
            .addCase(
                fetchEstimateBatch.fulfilled.type,
                (state: BatchesState, action: PayloadAction<{ isQueued: boolean }>) => {
                    state.estimateStatus = {
                        loading: false,
                        status: 'succeeded',
                        error: null,
                    };
                    state.isEstimateQueued = action.payload?.isQueued;
                }
            )
            .addCase(
                fetchEstimateBatch.rejected.type,
                (state: BatchesState, action: PayloadAction<string>) => {
                    state.estimateStatus = {
                        loading: false,
                        status: 'failed',
                        error: action.payload ?? 'Something went wrong!',
                    };
                    state.isEstimateQueued = false;
                }
            )
            .addCase(fetchDeleteBatch.pending.type, (state: BatchesState) => {
                state.deleteStatus = {
                    loading: true,
                    status: 'loading',
                    error: null,
                };
            })
            .addCase(
                fetchDeleteBatch.fulfilled.type,
                (state: BatchesState, action: PayloadAction<{ isDeleted: boolean }>) => {
                    state.deleteStatus = {
                        loading: false,
                        status: 'succeeded',
                        error: null,
                    };
                    state.isDeleted = action.payload?.isDeleted;
                }
            )
            .addCase(
                fetchDeleteBatch.rejected.type,
                (state: BatchesState, action: PayloadAction<string>) => {
                    state.deleteStatus = {
                        loading: false,
                        status: 'failed',
                        error: action.payload ?? 'Something went wrong!',
                    };
                    state.isDeleted = false;
                }
            )
            .addCase(fetchReprocessBatch.pending.type, (state: BatchesState) => {
                state.reprocessStatus = {
                    loading: true,
                    status: 'loading',
                    error: null,
                };
            })
            .addCase(
                fetchReprocessBatch.fulfilled.type,
                (state: BatchesState, action: PayloadAction<{ isQueued: boolean }>) => {
                    state.reprocessStatus = {
                        loading: false,
                        status: 'succeeded',
                        error: null,
                    };
                    state.isEstimateQueued = action.payload?.isQueued;
                }
            )
            .addCase(
                fetchReprocessBatch.rejected.type,
                (state: BatchesState, action: PayloadAction<string>) => {
                    state.reprocessStatus = {
                        loading: false,
                        status: 'failed',
                        error: action.payload ?? 'Something went wrong!',
                    };
                    state.isEstimateQueued = false;
                }
            );
    },
});

export const { selectBatchId } = batchesSlice.actions;

export const selectedBatchId = (state: RootState): string | null => state.batches.selectedBatchId;
export const showRegression = (state: RootState): boolean => state.batches.showRegression;

export default batchesSlice.reducer;
