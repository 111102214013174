import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    FarmOnboard,
    OnboardFarmBoundaryRequest,
    OnboardFarmBoundaryResponse,
    OnboardFarmResponse,
    OnboardMoveInactiveRequest,
    OnboardZoneBoundariesRequest,
    OnboardZoneBoundariesResponse,
    ZoneBoundariesRequest,
} from './onboardTypes';
import {
    getAllFarms,
    getZoneBoundaries,
    onboardFarmBoundary,
    onboardFarmCheck,
    onboardMoveInactiveZones,
    onboardZoneBoundaries,
} from './onboardApi';
import { STATUS } from 'utils/Store/Constants';
import { RootState } from 'app/store';

const genericErrorMsg = 'Something went wrong!';

type ProgressState = {
    loading: boolean;
    status: string;
    error: string | null;
};

export interface FarmOnboardState extends ProgressState {
    farms: FarmOnboard[];
}

const defaultStatus: ProgressState = {
    loading: false,
    status: STATUS.idle,
    error: null,
};

const initialFarmsStatus: FarmOnboardState = {
    farms: [],
    loading: false,
    status: STATUS.idle,
    error: null,
};

interface OnboardState {
    onboardResponse: OnboardFarmResponse | null;
    onboardResponseStatus: ProgressState;
    onboardBoundaryResponse: OnboardFarmBoundaryResponse | null;
    onboardBoundaryResponseStatus: ProgressState;
    onboardZoneBoundariesResponse: OnboardZoneBoundariesResponse | null;
    onboardZoneBoundariesResponseStatus: ProgressState;
    zoneBoundariesResponseStatus: ProgressState;
    zoneBoundaries: any;
    allFarms: FarmOnboardState;
}

const initialState: OnboardState = {
    onboardResponse: null,
    onboardResponseStatus: defaultStatus,
    onboardBoundaryResponse: null,
    onboardBoundaryResponseStatus: defaultStatus,
    onboardZoneBoundariesResponse: null,
    onboardZoneBoundariesResponseStatus: defaultStatus,
    zoneBoundariesResponseStatus: defaultStatus,
    zoneBoundaries: null,
    allFarms: initialFarmsStatus,
};

export const fetchAllFarms = createAsyncThunk(
    'farms/fetchAllFarms',
    async () => await getAllFarms()
);

export const fetchFarmOnboardCheck = createAsyncThunk(
    'onboard/farm/check',
    async (farmId: number): Promise<OnboardFarmResponse> => await onboardFarmCheck(farmId)
);

export const fetchOnboardFarmBoundary = createAsyncThunk(
    'onboard/farm-boundary',
    async (req: OnboardFarmBoundaryRequest): Promise<OnboardFarmBoundaryResponse> =>
        await onboardFarmBoundary(req)
);

export const fetchOnboardZoneBoundaries = createAsyncThunk(
    'onboard/zone-boundaries',
    async (req: OnboardZoneBoundariesRequest): Promise<OnboardZoneBoundariesResponse> =>
        await onboardZoneBoundaries(req)
);

export const fetchZoneBoundaries = createAsyncThunk(
    'onboard/zone-boundaries-get',
    async (req: ZoneBoundariesRequest): Promise<any> => await getZoneBoundaries(req)
);

export const fetchMoveInactiveZones = createAsyncThunk(
    'onboard/move/inactive',
    async (req: OnboardMoveInactiveRequest): Promise<OnboardZoneBoundariesResponse> =>
        await onboardMoveInactiveZones(req)
);

export const onboardSlice = createSlice({
    name: 'onboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFarmOnboardCheck.pending.type, (state: OnboardState) => {
                state.onboardResponseStatus = {
                    loading: true,
                    status: STATUS.loading,
                    error: null,
                };
                state.onboardResponse = null;
            })
            .addCase(
                fetchFarmOnboardCheck.fulfilled.type,
                (state: OnboardState, action: PayloadAction<OnboardFarmResponse>) => {
                    state.onboardResponseStatus = {
                        loading: false,
                        status: STATUS.succeeded,
                        error: null,
                    };
                    state.onboardResponse = action.payload;
                }
            )
            .addCase(
                fetchFarmOnboardCheck.rejected.type,
                (state: OnboardState, action: PayloadAction<string>) => {
                    state.onboardResponseStatus = {
                        loading: false,
                        status: STATUS.failed,
                        error: action.payload ?? genericErrorMsg,
                    };
                }
            )
            .addCase(fetchOnboardFarmBoundary.pending.type, (state: OnboardState) => {
                state.onboardBoundaryResponseStatus = {
                    loading: true,
                    status: STATUS.loading,
                    error: null,
                };
                state.onboardBoundaryResponse = null;
            })
            .addCase(
                fetchOnboardFarmBoundary.fulfilled.type,
                (state: OnboardState, action: PayloadAction<OnboardFarmBoundaryResponse>) => {
                    state.onboardBoundaryResponseStatus = {
                        loading: false,
                        status: STATUS.succeeded,
                        error: null,
                    };
                    state.onboardBoundaryResponse = action.payload;
                }
            )
            .addCase(
                fetchOnboardFarmBoundary.rejected.type,
                (state: OnboardState, action: PayloadAction<string>) => {
                    state.onboardBoundaryResponseStatus = {
                        loading: false,
                        status: STATUS.failed,
                        error: action.payload ?? genericErrorMsg,
                    };
                }
            )
            .addCase(fetchOnboardZoneBoundaries.pending.type, (state: OnboardState) => {
                state.onboardZoneBoundariesResponseStatus = {
                    loading: true,
                    status: STATUS.loading,
                    error: null,
                };
                state.onboardZoneBoundariesResponse = null;
            })
            .addCase(
                fetchOnboardZoneBoundaries.fulfilled.type,
                (state: OnboardState, action: PayloadAction<OnboardZoneBoundariesResponse>) => {
                    state.onboardZoneBoundariesResponseStatus = {
                        loading: false,
                        status: STATUS.succeeded,
                        error: null,
                    };
                    state.onboardZoneBoundariesResponse = action.payload;
                }
            )
            .addCase(
                fetchOnboardZoneBoundaries.rejected.type,
                (state: OnboardState, action: PayloadAction<string>) => {
                    state.onboardZoneBoundariesResponseStatus = {
                        loading: false,
                        status: STATUS.failed,
                        error: action.payload ?? genericErrorMsg,
                    };
                }
            )
            .addCase(fetchMoveInactiveZones.pending.type, (state: OnboardState) => {
                state.onboardZoneBoundariesResponseStatus = {
                    loading: true,
                    status: STATUS.loading,
                    error: null,
                };
                state.onboardZoneBoundariesResponse = null;
            })
            .addCase(
                fetchMoveInactiveZones.fulfilled.type,
                (state: OnboardState, action: PayloadAction<OnboardZoneBoundariesResponse>) => {
                    state.onboardZoneBoundariesResponseStatus = {
                        loading: false,
                        status: STATUS.succeeded,
                        error: null,
                    };
                    state.onboardZoneBoundariesResponse = action.payload;
                }
            )
            .addCase(
                fetchMoveInactiveZones.rejected.type,
                (state: OnboardState, action: PayloadAction<string>) => {
                    state.onboardZoneBoundariesResponseStatus = {
                        loading: false,
                        status: STATUS.failed,
                        error: action.payload ?? genericErrorMsg,
                    };
                }
            )

            .addCase(fetchZoneBoundaries.pending.type, (state: OnboardState) => {
                state.zoneBoundariesResponseStatus = {
                    loading: true,
                    status: STATUS.loading,
                    error: null,
                };
                state.zoneBoundaries = null;
            })
            .addCase(
                fetchZoneBoundaries.fulfilled.type,
                (state: OnboardState, action: PayloadAction<any>) => {
                    state.zoneBoundariesResponseStatus = {
                        loading: false,
                        status: STATUS.succeeded,
                        error: null,
                    };
                    state.zoneBoundaries = action.payload;
                }
            )
            .addCase(
                fetchZoneBoundaries.rejected.type,
                (state: OnboardState, action: PayloadAction<string>) => {
                    state.zoneBoundariesResponseStatus = {
                        loading: false,
                        status: STATUS.failed,
                        error: action.payload ?? genericErrorMsg,
                    };
                }
            )

            // FARMS
            .addCase(fetchAllFarms.pending.type, (state: OnboardState) => {
                state.allFarms = {
                    farms: [],
                    loading: true,
                    status: STATUS.loading,
                    error: null,
                };
                state.zoneBoundaries = null;
            })
            .addCase(
                fetchAllFarms.fulfilled.type,
                (state: OnboardState, action: PayloadAction<any>) => {
                    state.allFarms = {
                        loading: false,
                        status: STATUS.succeeded,
                        error: null,
                        farms: action.payload,
                    };
                }
            )
            .addCase(
                fetchAllFarms.rejected.type,
                (state: OnboardState, action: PayloadAction<string>) => {
                    state.allFarms = {
                        farms: [],
                        loading: false,
                        status: STATUS.failed,
                        error: action.payload ?? genericErrorMsg,
                    };
                }
            );
    },
});

export const selectOnboardFarmById = (
    state: RootState,
    farmId: number | null
): OnboardFarmResponse | null => {
    if (state.onboarding.onboardResponse?.farmId === farmId) {
        return state.onboarding.onboardResponse;
    }
    return initialState.onboardResponse;
};

export default onboardSlice.reducer;
