import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaddockGroup } from './paddockGroupType';
import { getPaddockGroups, createNewPaddockGroup, movePaddockToGroup } from './paddockGroupsApi';
import { middayUtcStr } from '../../utils/DateUtil';
import { useFriendlyErrorHandler } from '../notifications/hooks';
import { STATUS } from 'utils/Store/Constants';

interface Status {
    loading: boolean;
    status: string;
    error: string | null;
}

export interface PaddockGroupsState {
    groups: PaddockGroup[];
    fetchStatus: Status;
    moveStatus: Status;
}

const initialStatus: Status = {
    loading: false,
    status: STATUS.idle,
    error: null,
};

export const initialState: PaddockGroupsState = {
    groups: [],
    fetchStatus: initialStatus,
    moveStatus: initialStatus,
};

export const fetchPaddockGroups = createAsyncThunk(
    'paddockGroups/fetchPaddockGroups',
    async (farmId: number): Promise<PaddockGroup[]> => {
        return await getPaddockGroups(farmId);
    }
);

export const createPaddockGroup = createAsyncThunk(
    'paddockGroups/movePaddock',
    async ({
        paddockGroupName,
        farmId,
        isCuttingGroup,
    }: {
        paddockGroupName: string;
        farmId: number;
        isCuttingGroup: boolean;
    }): Promise<void> => {
        return await createNewPaddockGroup(paddockGroupName, farmId, isCuttingGroup);
    }
);

type MovePaddockArgs = {
    paddockId: number;
    groupId: number;
    asOf: Date;
    farmId: number;
    farmLocalTimeZone: string;
};

export const movePaddock = createAsyncThunk(
    'paddockGroups/movePaddock',
    async (
        { paddockId, groupId, asOf, farmId, farmLocalTimeZone }: MovePaddockArgs,
        { rejectWithValue, dispatch }
    ) => {
        const movePaddockFnc = async () =>
            await movePaddockToGroup(
                paddockId,
                groupId,
                middayUtcStr(asOf.toISOString(), farmLocalTimeZone),
                farmId
            );
        return await useFriendlyErrorHandler(movePaddockFnc, rejectWithValue, dispatch);
    }
);

export const paddockGroupsSlice = createSlice({
    name: 'paddockGroups',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchPaddockGroups.pending.type]: (state: PaddockGroupsState) => {
            state.fetchStatus = {
                loading: true,
                status: STATUS.loading,
                error: null,
            };
        },
        [fetchPaddockGroups.fulfilled.type]: (
            state: PaddockGroupsState,
            action: PayloadAction<PaddockGroup[]>
        ) => {
            state.groups = action.payload;
            state.fetchStatus = {
                loading: false,
                status: STATUS.succeeded,
                error: null,
            };
        },
        [fetchPaddockGroups.rejected.type]: (
            state: PaddockGroupsState,
            action: PayloadAction<string>
        ) => {
            state.fetchStatus = {
                loading: false,
                status: STATUS.failed,
                error: action.payload,
            };
        },
        [movePaddock.pending.type]: (state: PaddockGroupsState) => {
            state.moveStatus = {
                loading: true,
                status: STATUS.loading,
                error: null,
            };
        },
        [movePaddock.fulfilled.type]: (state: PaddockGroupsState) => {
            state.moveStatus = {
                loading: false,
                status: STATUS.succeeded,
                error: null,
            };
        },
        [movePaddock.rejected.type]: (state: PaddockGroupsState, action: PayloadAction<string>) => {
            state.moveStatus = {
                loading: false,
                status: STATUS.failed,
                error: action.payload,
            };
        },
    },
});

export default paddockGroupsSlice.reducer;
