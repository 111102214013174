import React, { useState } from 'react';
import Page from '../../../components/Page';
import Container from '@material-ui/core/Container';
import Details from './Details';
import Paper from '@material-ui/core/Paper';
import { StyledTab, StyledTabs } from '../../../components/StyledTabs';
import { a11yProps, TabPanel } from '../../../components/TabPanel';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import NewZoneBoundaries from './NewZoneBoundaries';
import ManageFarm from './ManageFarm';
import { Farm } from 'features/farms/farmType';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(2),
        },
    })
);

export default function Onboard() {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedFarm, setSelectedFarm] = useState<Farm | null>(null);

    return (
        <Page title="Farm Onboarding">
            <Container maxWidth="lg">
                <Paper className={classes.root}>
                    <StyledTabs
                        value={selectedTab}
                        onChange={(_, newTab) => {
                            setSelectedTab(newTab);
                        }}
                        aria-label="Onboarding tabs"
                    >
                        <StyledTab label="Manage Farm" {...a11yProps(0)} />
                        <StyledTab label="Farm Zones boundaries" {...a11yProps(1)} />
                        <StyledTab label="Farm and Zones Details" {...a11yProps(2)} />
                    </StyledTabs>
                    <Divider />
                    <TabPanel value={selectedTab} index={0}>
                        <ManageFarm />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <NewZoneBoundaries
                            selectedFarm={selectedFarm}
                            setSelectedFarm={setSelectedFarm}
                        />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                        <Details selectedFarm={selectedFarm} setSelectedFarm={setSelectedFarm} />
                    </TabPanel>
                </Paper>
            </Container>
        </Page>
    );
}
