import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getFarmGroups } from './farmGroupsApi';
import { FarmGroup } from './farmGroupType';
interface FarmGroupsState {
    farmGroups: FarmGroup[];
    loading: boolean;
    status: string;
    error: string | null;
}

export const fetchAllFarmGroups = createAsyncThunk(
    'farm-groups',
    async () => await getFarmGroups()
);

const initialState: FarmGroupsState = {
    farmGroups: [],
    loading: false,
    status: 'idle',
    error: null,
};

export const farmGroupsSlice = createSlice({
    name: 'farm-groups',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchAllFarmGroups.pending.type]: (state: FarmGroupsState) => {
            state.loading = true;
            state.status = 'loading';
            state.error = null;
        },
        [fetchAllFarmGroups.fulfilled.type]: (
            state: FarmGroupsState,
            action: PayloadAction<FarmGroup[]>
        ) => {
            state.farmGroups = action.payload;
            state.loading = false;
            state.status = 'succeeded';
            state.error = null;
        },
        [fetchAllFarmGroups.rejected.type]: (
            state: FarmGroupsState,
            action: PayloadAction<string>
        ) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.payload;
        },
    },
});

export default farmGroupsSlice.reducer;
