import { red } from '@material-ui/core/colors';
import { createTheme, Theme } from '@material-ui/core/styles';

const buildTheme = (paletteType: 'light' | 'dark'): Theme =>
    createTheme({
        palette: {
            type: paletteType,
            primary: {
                main: '#EE4D9B',
            },
            secondary: {
                main: '#383C56',
            },
            error: {
                main: red.A400,
            },
        },
    });

export { buildTheme };
