import firebase from 'firebase/app';
import { fetch } from 'api/client';
import { UserActivityLogModel } from './UserActivityLogModel';

type getActivityLogParams = {
    page?: number;
    limit?: number;
    sortBy?: string;
    farmIds: number[];
    eventTypes?: string[];
};

const getActivityLogDefaults = {
    page: 1,
    limit: 15,
    sortBy: '-eventTime',
};

export const getActivityLog = async (
    params: getActivityLogParams
): Promise<UserActivityLogModel[]> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    const { page, limit, sortBy, eventTypes, farmIds } = { ...getActivityLogDefaults, ...params };
    const eventTypeFilter = eventTypes?.join(',') || '';
    const farmIdFilter = JSON.stringify(farmIds.map((i) => i.toString()));

    const queryParams = `?page=${page}&limit=${limit}&eventType=${eventTypeFilter}&sortBy=${sortBy}&filterBy={"$and":[{"payload.farmId": {"$in":${farmIdFilter}}} ]}`;
    const request = {
        token: token!,
        baseApiUri: `${process.env.REACT_APP_UAL_API_URL}/api`,
        endpointUri: `user_activity_log${queryParams}`,
    };

    const { result } = await fetch(request);
    return result;
};
