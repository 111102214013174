import firebase from 'firebase/app';
import { get, post } from '../../../../../api/client';

export const transferMote = async (body: any): Promise<any[]> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
        return [];
    }
    return await post(token, 'associations/transfer/', body);
};

export const batchTransferMotes = async (body: any): Promise<any[]> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
        return [];
    }
    return await post(token, 'associations/transfer/batch/', body);
};

export const fetchAssociations = async (body: any): Promise<any[]> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
        return [];
    }
    return await get(token, `associations/history/mote/${body.moteId}`);
};
