import React, { useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useAppSelector } from '../../../../app/hooks';
import { selectedBatchId } from './batchesSlice';
import { shallowEqual } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default function EstimateToaster() {
    const selectedBId = useAppSelector(selectedBatchId);

    const { estimateBatchLoading, estimateBatchStatus, estimateBatchError, isEstimateQueued } =
        useAppSelector((state) => {
            return {
                estimateBatchLoading: state.batches.estimateStatus.loading,
                estimateBatchStatus: state.batches.estimateStatus.status,
                estimateBatchError: state.batches.estimateStatus.error,
                isEstimateQueued: state.batches.isEstimateQueued,
            };
        }, shallowEqual);

    const [toasterType, setToasterType] = useState<'error' | 'success' | 'none'>('none');
    useEffect(() => {
        if (estimateBatchStatus === 'idle' || estimateBatchLoading) {
            setToasterType('none');
        } else if (isEstimateQueued) {
            setToasterType('success');
        } else if (estimateBatchError) {
            setToasterType('error');
        }
    }, [estimateBatchStatus, estimateBatchLoading, isEstimateQueued, estimateBatchError]);

    const snackBar = (message: string, type: 'error' | 'success') => (
        <Snackbar
            open={toasterType !== 'none'}
            onClose={() => setToasterType('none')}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                severity={type}
                action={
                    <>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={() => setToasterType('none')}
                            style={{ padding: 4 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </>
                }
            >
                {message}
            </Alert>
        </Snackbar>
    );

    return (
        <>
            {toasterType === 'error' &&
                snackBar(`${estimateBatchError}. Batch ${selectedBId} is not estimated!`, 'error')}
            {toasterType === 'success' &&
                snackBar(`Batch ${selectedBId} is queued to be estimated!`, 'success')}
        </>
    );
}
