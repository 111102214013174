import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { middayUtcStr } from '../../utils/DateUtil';
import { runPphProcessor } from './pphProcessorApi';

export interface PphProcessorSlice {
    response: any;
    loading: boolean;
    status: string;
    error: string | null;
}

export const initialState: PphProcessorSlice = {
    response: null,
    loading: false,
    status: 'idle',
    error: null,
};

type PphProcessorProps = {
    farmId: number;
    farmLocalTimeZone: string;
    since: Date;
    until: Date;
    type: string;
};

export const doRunPphProcessor = createAsyncThunk(
    'pphProcessor/run',
    async ({ farmId, farmLocalTimeZone, since, until, type }: PphProcessorProps): Promise<any> => {
        return await runPphProcessor(
            farmId,
            middayUtcStr(since.toISOString(), farmLocalTimeZone),
            middayUtcStr(until.toISOString(), farmLocalTimeZone),
            type
        );
    }
);

export const pphProcessorSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: {
        [doRunPphProcessor.pending.type]: (state: PphProcessorSlice) => {
            state.response = null;
            state.loading = true;
            state.status = 'loading';
            state.error = null;
        },
        [doRunPphProcessor.fulfilled.type]: (
            state: PphProcessorSlice,
            action: PayloadAction<any>
        ) => {
            state.response = action.payload;
            state.loading = false;
            state.status = 'succeeded';
            state.error = null;
        },
        [doRunPphProcessor.rejected.type]: (
            state: PphProcessorSlice,
            action: PayloadAction<any>
        ) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.payload?.error;
        },
    },
});

export const { reset } = pphProcessorSlice.actions;

export default pphProcessorSlice.reducer;
