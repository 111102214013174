import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { shallowEqual } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { FarmCollective } from './farmCollectivesType';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { STATUS } from 'utils/Store/Constants';
import { fetchFarmsCollectives } from './farmCollectivesSlice';
import { Str } from 'utils/StringConstants';
import { Sizes } from 'utils/BaseStyles';
import _find from 'lodash/find';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: Sizes.width,
        },
    })
);

const build = (name: string): FarmCollective => {
    return {
        farmCollectiveId: 0,
        farmCollectiveName: name,
        epsg: null,
        startDate: Str.empty,
    };
};

type ParamProps = {
    refresh?: boolean;
    allowAdd?: boolean;
    preSelectedValue: FarmCollective | null;
    onSelect: (value: FarmCollective) => void;
};

export default function FarmCollectiveAutoComplete({
    preSelectedValue,
    allowAdd = false,
    refresh,
    onSelect,
}: ParamProps) {
    const classes = styles();
    const dispatch = useAppDispatch();

    const { loading, status, data } = useAppSelector((state) => {
        return {
            loading: state.farmCollectives.loading,
            status: state.farmCollectives.status,
            data: state.farmCollectives.collectives,
        };
    }, shallowEqual);

    // OnMount
    useEffect(() => {
        if (status === STATUS.idle && data?.length === 0) {
            dispatch(fetchFarmsCollectives());
        }
    }, [dispatch, data, status]);

    useEffect(() => {
        if (refresh) {
            dispatch(fetchFarmsCollectives());
        }
    }, [dispatch, refresh]);

    const [value, setValue] = useState<FarmCollective | null>(null);
    useEffect(() => {
        const collective = _find(
            data,
            (p) => p.farmCollectiveId === preSelectedValue?.farmCollectiveId
        );
        setValue(collective || preSelectedValue);
    }, [data, preSelectedValue]);

    if (allowAdd) {
        const optionsfilter = createFilterOptions<FarmCollective>();

        return (
            <Autocomplete
                id="farm-collectives-add"
                options={data}
                loadingText="loading..."
                loading={loading}
                freeSolo
                selectOnFocus
                clearOnBlur
                openOnFocus
                handleHomeEndKeys
                className={classes.root}
                renderOption={(option) => option.farmCollectiveName}
                renderInput={(params) => (
                    <TextField {...params} label="Farm Collectives" variant="outlined" />
                )}
                value={value}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    return option.farmCollectiveName;
                }}
                onChange={(_, newValue) => {
                    if (!newValue) return;

                    if (typeof newValue === 'string') {
                        onSelect(build(newValue));
                    } else {
                        onSelect(newValue);
                    }
                }}
                filterOptions={(options: FarmCollective[], params) => {
                    const filtered = optionsfilter(options, params);
                    if (params.inputValue) {
                        filtered.unshift(build(params.inputValue));
                    }
                    return filtered;
                }}
            />
        );
    }

    return (
        <Autocomplete
            id="farm-collectives"
            loadingText="loading..."
            loading={loading}
            options={data}
            getOptionSelected={(a, b) => a.farmCollectiveId === b.farmCollectiveId}
            getOptionLabel={(option) => option.farmCollectiveName}
            renderInput={(params) => (
                <TextField {...params} label="Farm Collectives" variant="outlined" />
            )}
            value={value}
            onChange={(event: any, newValue: any) => {
                setValue(newValue);
                onSelect(newValue);
            }}
            className={classes.root}
        />
    );
}
