import { Action, Unsubscribe } from '@reduxjs/toolkit';
import { actionTypes } from 'react-redux-firebase';
import { AppListenerEffectAPI, AppStartListening } from 'app/store';
import { LDClient } from 'launchdarkly-js-client-sdk';

const onLogin = (ldClient: LDClient) => (action: any, api: AppListenerEffectAPI) => {
    ldClient.identify({
        key: action.auth.uid,
        email: action.auth.email,
        name: action.auth.displayName,
    });
};

const onLoadProfile = (ldClient: LDClient) => (action: any, api: AppListenerEffectAPI) => {
    const { key, email, name } = ldClient.getUser();
    ldClient.identify({
        key,
        email,
        name,
        custom: {
            role: action.profile.role,
            farmGroupId: action.profile.farmGroupId,
        },
    });
};

const onLogout = (ldClient: LDClient) => (action: Action) => {
    ldClient.flush();
    ldClient.identify({
        anonymous: true,
    });
};

export const setupLaunchdarklyListeners = (
    startListening: AppStartListening,
    ldClient: LDClient
): Unsubscribe => {
    const subscriptions = [
        startListening({
            type: actionTypes.LOGIN,
            effect: onLogin(ldClient),
        }),
        startListening({
            type: actionTypes.SET_PROFILE,
            effect: onLoadProfile(ldClient),
        }),
        startListening({
            type: actionTypes.LOGOUT,
            effect: onLogout(ldClient),
        }),
    ];

    return () => {
        subscriptions.forEach((unsubscribe) => unsubscribe());
    };
};
