import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Adjustment } from './adjustmentTypes';
import { reportMoteAdjustments } from './adjustmentsApi';

interface AdjustmentsState {
    loading: boolean;
    status: string;
    error: string | null;
}

type reportAdjustmentsProps = {
    farmId: number;
    farmName: string;
    adjustments: Adjustment[];
};

export const fetchReportAdjustments = createAsyncThunk(
    'adjustments/fetchReportAdjustments',
    async (props: reportAdjustmentsProps) => {
        await reportMoteAdjustments(props.farmId, props.farmName, props.adjustments);
    }
);

const initialState: AdjustmentsState = {
    loading: false,
    status: 'idle',
    error: null,
};

export const adjustmentsSlice = createSlice({
    name: 'adjustments',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchReportAdjustments.pending.type]: (state: AdjustmentsState) => {
            state.loading = true;
            state.status = 'loading';
            state.error = null;
        },
        [fetchReportAdjustments.fulfilled.type]: (state: AdjustmentsState) => {
            state.loading = false;
            state.status = 'succeeded';
            state.error = null;
        },
        [fetchReportAdjustments.rejected.type]: (
            state: AdjustmentsState,
            action: PayloadAction<string>
        ) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.payload;
        },
    },
});

export default adjustmentsSlice.reducer;
