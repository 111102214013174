import firebase from 'firebase/app';
import { get, post } from '../../../../api/client';
import { PaddockGrazing } from './grazingType';
import 'firebase/auth';

export const getPaddockGrazingsByFarm = async (
    fromUtc: string,
    toUtc: string,
    farmId: number
): Promise<PaddockGrazing[]> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
        return [];
    }
    const response = await get(token, `paddocks/farm/${farmId}/from/${fromUtc}/to/${toUtc}`);
    return response as PaddockGrazing[];
};

export const reportPaddockGrazings = async (
    farmId: number,
    farmName: string,
    grazings: any
): Promise<void> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
        return;
    }
    await post(token, `zone-grazing/farm/${farmId}`, { reportedGrazings: grazings });
};

export const getLatestGrazing = async (moteId: number, before: string): Promise<string> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
        return '';
    }
    const response = await get(token, `zone-grazing/mote/${moteId}/before/${before}`);
    return response as string;
};
