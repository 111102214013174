import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { UserActivityLogModel } from './api/UserActivityLogModel';
import { DateTime } from 'luxon';
import { UserAvatar } from 'components/UserAvatar';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        log: {
            fontSize: theme.typography.body2.fontSize,
            marginLeft: theme.spacing(1),
        },
        title: {
            fontWeight: 'bold',
        },
    })
);

type ActivityLogItemProps = {
    activityLog: UserActivityLogModel<any>;
};

export const ActivityLogItem = ({ activityLog }: ActivityLogItemProps) => {
    const classes = useStyles();

    const { payload, eventTime, displayName } = activityLog;

    return (
        <>
            <UserAvatar email={payload?.userId} />

            <div className={classes.log}>
                <div className={classes.title}>{displayName}</div>
                <div>
                    {payload?.userId} {DateTime.fromISO(eventTime).toRelative()}
                </div>
            </div>
        </>
    );
};
