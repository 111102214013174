import React, { useState } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { shallowEqual } from 'react-redux';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Box from '@material-ui/core/Box';
import GraphIcon from '@material-ui/icons/AssessmentSharp';
import InputGraph from './InputGraph';
import TableIcon from '@material-ui/icons/ViewListSharp';

const columns: GridColDef[] = [
    { field: 'farm_name', headerName: 'Farm', width: 160 },
    { field: 'mote_name', headerName: 'Mote', width: 120 },
    { field: 'mean_mote_wdrre', headerName: 'Mean WDRRE', width: 140 },
    { field: 'mean_mote_ndre', headerName: 'Mean NDRE', width: 140 },
    { field: 'mean_mote_ndvi', headerName: 'Mean NDVI', width: 120 },
    { field: 'hapgr', headerName: 'HAPGR', width: 140 },
    { field: 'exclusion_reason', headerName: 'Exclusion Reason', width: 300 },
    { field: 'scene_id', headerName: 'Scene ID', width: 260 },
];

type InputProps = {
    input: any;
};

export default function Input({ input }: InputProps) {
    const { inputLoading } = useAppSelector((state) => {
        return {
            inputLoading: state.regression.inputState.loading,
            inputError: state.regression.inputState.error,
        };
    }, shallowEqual);

    const [showGraph, setShowGraph] = useState<boolean>(false);
    const toggleGraphClick = () => setShowGraph(!showGraph);

    const rows = input.map((i: any) => ({ id: i.mote_id, ...i }));

    return (
        <Box display="flex" flexDirection="column">
            <Box alignSelf="flex-end">
                {showGraph ? (
                    <TableIcon color="secondary" onClick={toggleGraphClick} cursor="pointer" />
                ) : (
                    <GraphIcon color="secondary" onClick={toggleGraphClick} cursor="pointer" />
                )}
            </Box>
            {showGraph ? (
                <InputGraph input={input} />
            ) : (
                <Box height={400} width="100%">
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10, 50, 100]}
                        disableSelectionOnClick
                        loading={inputLoading}
                    />
                </Box>
            )}
        </Box>
    );
}
