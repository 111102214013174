import { makeStyles, Theme, createStyles, Avatar } from '@material-ui/core';
import ColorHash from 'color-hash';

type UserAvatarProps = {
    email: string;
};

const colorHash = new ColorHash();

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            textTransform: 'uppercase',
        },
    })
);

export const UserAvatar = ({ email }: UserAvatarProps) => {
    const classes = useStyles();

    return (
        <Avatar className={classes.avatar} style={{ background: colorHash.hex(email) }}>
            {email && email[0]}
        </Avatar>
    );
};
