import React from 'react';
import FeedWedge from '../../../feedWedge';
import FeedWedgeGrouped from '../../../feedWedgeGrouped';
import { getGraphColor } from '../../../../../utils/GraphColors';
import { MultiWedgesModel } from '../../types/multiWedgesModel';
import { selectFilters } from '../../../../measurements/filters/filtersSlice';
import { useAppSelector } from '../../../../../app/hooks';

function MultiFeedWedges({ growths }: MultiWedgesModel) {
    const filters = useAppSelector(selectFilters);

    if (!growths) return <></>;

    return (
        <>
            {growths.length > 1 && (
                <FeedWedgeGrouped
                    growths={growths}
                    targetMax={filters.targets.preGrazingTarget}
                    targetMin={filters.targets.postGrazingTarget}
                />
            )}
            {growths.map((g, i) => (
                <>
                    {g.covers && g.covers[0] && (
                        <FeedWedge
                            zoneGroup={g.covers[0]?.zoneName}
                            color={getGraphColor(i)?.heading}
                            data={g.feedWedgeData}
                            key={g.covers[0]?.zoneName}
                        />
                    )}
                </>
            ))}
        </>
    );
}

export default MultiFeedWedges;
