import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel, Paper, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectFilters } from '../../measurements/filters/filtersSlice';
import { createPaddockGroup } from '../paddockGroupsSlice';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: theme.spacing(1),
        },
        paper: {
            marginTop: theme.spacing(1),
            padding: theme.spacing(2),
        },
        wrapper: {
            display: 'flex',
            columnGap: '1.5rem',
            rowGap: '1rem',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        textField: {
            minWidth: '12rem',
        },
    })
);

export default function Add() {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const filters = useAppSelector(selectFilters);

    const [groupName, setGroupName] = useState<string>('');
    const handleGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setGroupName(e.target.value);
    };

    const [isCuttingGroup, setIsCuttingGroup] = useState<boolean>(false);
    const handleIsCuttingGroupChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsCuttingGroup(e.target.checked);
    };

    const clearFields = () => {
        setGroupName('');
        setIsCuttingGroup(false);
    };

    const handleAddClick = () => {
        dispatch(
            createPaddockGroup({
                farmId: filters.farm.farmId,
                isCuttingGroup,
                paddockGroupName: groupName,
            })
        );
        clearFields();
    };

    return (
        <div className={classes.root}>
            <Typography variant="body2">Add a new Paddock Group</Typography>
            <Paper className={classes.paper}>
                <div className={classes.wrapper}>
                    <TextField
                        label="Group Name"
                        variant="outlined"
                        className={classes.textField}
                        onChange={handleGroupNameChange}
                        value={groupName}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="cuttingGroup"
                                checked={isCuttingGroup}
                                size="small"
                                onChange={handleIsCuttingGroupChange}
                            />
                        }
                        label={<Typography variant="caption">Cutting Group</Typography>}
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={handleAddClick}
                    >
                        Add
                    </Button>
                </div>
            </Paper>
        </div>
    );
}
