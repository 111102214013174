import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import NotBatchedScenes from './NotBatchedScenes';
import { a11yProps, TabPanel } from '../../../../components/TabPanel';
import ArchivedScenes from './ArchivedScenes';
import BatchedScenes from './BatchedScenes';
import { StyledTab, StyledTabs } from '../../../../components/StyledTabs';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginTop: theme.spacing(2),
        },
    })
);

export default function Scenes() {
    const classes = useStyles();

    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <Paper className={classes.paper}>
            <Box padding={1} paddingBottom={0}>
                <StyledTabs
                    value={selectedTab}
                    onChange={(_, newTab) => setSelectedTab(newTab)}
                    aria-label="disabled tabs example"
                >
                    <StyledTab label="Not Batched" {...a11yProps(0)} />
                    <StyledTab label="Batched" {...a11yProps(1)} />
                    <StyledTab label="Archived" {...a11yProps(2)} />
                </StyledTabs>
            </Box>
            <Divider />
            <TabPanel value={selectedTab} index={0}>
                <NotBatchedScenes />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
                <BatchedScenes />
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
                <ArchivedScenes />
            </TabPanel>
        </Paper>
    );
}
