import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// @ts-ignore
import mapboxgl, { Map, GeoJSONSource } from 'mapbox-gl/dist/mapbox-gl-csp';
// @ts-ignore
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import GeoJsonSelection from './GeoJsonSelection';
import PaddocksToDeploy from './PaddocksToDeploy';
import Box from '@material-ui/core/Box';
import { PaddockChanges } from './onboardTypes';
import {
    addNewSourceToMap,
    addOldSourceToMap,
    loadMap,
    removeNewSourceFromMap,
    removeOldSourceFromMap,
} from './mapContainerUtil';
mapboxgl.workerClass = MapboxWorker; // Wire up loaded worker to be used instead of the default

mapboxgl.accessToken =
    'pk.eyJ1IjoicmFtLWZhcm1vdGUiLCJhIjoiY2t2MHlwcjlvMHIxajJ2cjJveWJoM2VudSJ9.wT5EzJVsZiOV3ESRaRzUUw';

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        map: {
            height: 500,
        },
    })
);

type NewZonesMapProps = {
    geoJsonOld: any;
    geoJsonNew: any;
    setPaddocksChanges: (paddocksChanges: PaddockChanges[]) => void;
};

export default function NewZonesMap({
    geoJsonOld = {},
    geoJsonNew = {},
    setPaddocksChanges,
}: NewZonesMapProps) {
    const classes = useStyles();

    const mapContainer = useRef(null);
    const map = useRef<Map>(null);

    const [styleLoaded, setStyleLoaded] = useState(false);
    const [selectedGeoJsons, setSelectedGeoJsons] = React.useState({
        showExisting: true,
        showNewUpload: true,
    });

    const addOldSource = useCallback(() => {
        addOldSourceToMap(map, styleLoaded, geoJsonOld);
    }, [geoJsonOld, styleLoaded]);

    const addNewSource = useCallback(() => {
        addNewSourceToMap(map, styleLoaded, geoJsonNew);
    }, [geoJsonNew, styleLoaded]);

    const removeOldSource = useCallback(() => {
        removeOldSourceFromMap(map, styleLoaded);
    }, [styleLoaded]);

    const removeNewSource = useCallback(() => {
        removeNewSourceFromMap(map, styleLoaded);
    }, [styleLoaded]);

    useEffect(() => {
        if (!loadMap(mapContainer, map)) {
            return;
        }

        map.current.on('load', () => {
            if (map.current) {
                addOldSource();
                addNewSource();
            }
        });

        map.current.on('styledata', () => {
            setStyleLoaded(true);
        });
    });

    useEffect(() => {
        removeOldSource();
        addOldSource();
    }, [addOldSource, geoJsonOld, removeOldSource]);

    useEffect(() => {
        removeNewSource();
        addNewSource();
    }, [addNewSource, geoJsonNew, removeNewSource]);

    useEffect(() => {
        if (map.current && styleLoaded) {
            if (selectedGeoJsons.showExisting) {
                addOldSource();
            } else {
                removeOldSource();
            }
        }
    }, [addOldSource, removeOldSource, selectedGeoJsons.showExisting, styleLoaded]);

    useEffect(() => {
        if (map.current && styleLoaded) {
            const source = map.current.getSource('new-source') as GeoJSONSource;

            if (selectedGeoJsons.showNewUpload) {
                addNewSource();
            } else if (source) {
                removeNewSource();
            }
        }
    }, [addNewSource, removeNewSource, selectedGeoJsons.showNewUpload, styleLoaded]);

    return (
        <div ref={mapContainer} className={classes.map}>
            <Box position="absolute" right={4} top={4} zIndex={999} width="fit-content">
                <GeoJsonSelection
                    setSelections={(showExisting, showNewUpload) =>
                        setSelectedGeoJsons({
                            showExisting: showExisting,
                            showNewUpload: showNewUpload,
                        })
                    }
                />
                {geoJsonNew?.features && (
                    <PaddocksToDeploy
                        paddocks={geoJsonNew?.features?.map(
                            (f: any) =>
                                ({
                                    name: f.properties?.ZONE_NAME,
                                    hide: f.properties?.ACTIVE === 0,
                                } as PaddockChanges)
                        )}
                        onPaddocksChange={setPaddocksChanges}
                    />
                )}
            </Box>
        </div>
    );
}
