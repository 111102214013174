import React from 'react';
import Button from '@material-ui/core/Button';

type CtaSmallProps = {
    label: string;
    onClick: () => void;
    isDisabled?: boolean;
};

const Cta = ({ label, onClick, isDisabled }: CtaSmallProps) => (
    <Button
        color="primary"
        variant="contained"
        size="small"
        onClick={onClick}
        disabled={isDisabled}
    >
        {label}
    </Button>
);

export default Cta;
