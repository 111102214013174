import { RootState } from 'app/store';
import { createSelector } from '@reduxjs/toolkit';
import { PaddockDictionary } from './paddockType';
import _each from 'lodash/each';

const paddockSlice = (state: RootState) => state.paddocks;

const paddockDictionary: PaddockDictionary = {};
export const getPaddockAsDictionary = createSelector(paddockSlice, (state): PaddockDictionary => {
    if (
        Object.keys(paddockDictionary).length > 0 &&
        paddockDictionary[0]?.paddockId === state.paddocks[0]?.paddockId
    ) {
        return paddockDictionary;
    }
    _each(state?.paddocks, (p) => (paddockDictionary[`${p.paddockId}`] = p));
    return paddockDictionary;
});

const zonesAsDictionary: PaddockDictionary = {};
export const getPaddockZonesAsDictionary = createSelector(
    paddockSlice,
    (state): PaddockDictionary => {
        if (
            Object.keys(zonesAsDictionary).length > 0 &&
            zonesAsDictionary[0]?.paddockId === state.paddocks[0]?.paddockId
        ) {
            return zonesAsDictionary;
        }
        _each(state?.paddocks, (p) => {
            const z = p?.zones[0];
            if (z) zonesAsDictionary[`${z.zoneId}`] = p;
        });
        return zonesAsDictionary;
    }
);

export const selectPaddocks = createSelector(paddockSlice, (state) => state.paddocks);
