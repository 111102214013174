import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../app/hooks';
import { selectMeasurementByDate } from '../reducers/parkMeasurementsSlice';
import { Measurement } from '../types/parkMeasurementTypes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: 200,
            overflowX: 'scroll',
        },
        lastCell: {
            maxWidth: 20,
        },
        button: {
            margin: theme.spacing(1),
        },
        inactiveRow: {
            backgroundColor: '#eeeeee',
        },
        checkButtonCell: {
            paddingRight: 0,
        },
    })
);

type SingleDayMeasurementsProps = {
    forDate: Date;
};

export default function SingleDayMeasurementsForPark({ forDate }: SingleDayMeasurementsProps) {
    const classes = useStyles();

    const measurement = useAppSelector((state) =>
        selectMeasurementByDate(state, forDate?.toISOString())
    );

    if (!measurement) {
        return <div>No Measurment Found for the given Date</div>;
    }

    return (
        <div className={classes.root}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            style={{ padding: 0 }}
                        />
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            style={{ padding: 0, paddingLeft: 6 }}
                        ></TableCell>
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            style={{ padding: 0, paddingLeft: 6 }}
                        ></TableCell>
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            colSpan={3}
                            style={{ padding: 0, paddingLeft: 6 }}
                        >
                            <Typography variant="caption">Growth Rate</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            align="right"
                            className={classes.lastCell}
                            style={{ padding: 0 }}
                        />
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            style={{ padding: 0 }}
                        >
                            <Typography variant="caption">Gross</Typography>
                        </TableCell>
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            style={{ padding: 0 }}
                        >
                            <Typography variant="caption">Net</Typography>
                        </TableCell>
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            style={{ padding: 0 }}
                        >
                            <Typography variant="caption">3 Days</Typography>
                        </TableCell>
                        <TableCell
                            align="center"
                            className={classes.lastCell}
                            style={{ padding: 0 }}
                        >
                            <Typography variant="caption">7 Days</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {measurement.measurements.map((g: Measurement) => (
                        <TableRow
                            key={g.name}
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            <TableCell align="right">{g.name}</TableCell>
                            <TableCell align="center">{g.gross}</TableCell>
                            <TableCell align="center">{g.net}</TableCell>
                            <TableCell align="center">{g.growth_3_days}</TableCell>
                            <TableCell align="center">{g.growth_7_days}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}
