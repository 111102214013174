import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { fetchApproveBatch, fetchEstimateBatch, selectBatchId } from '../batches/batchesSlice';
import { LinearProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ApproveToaster from '../batches/ApproveToaster';
import EstimateToaster from '../batches/EstimateToaster';
import CtaGroup from '../../../../components/CtaGroup';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(4),
        },
        textField: {
            minWidth: '24ch',
        },
        disableBox: {
            pointerEvents: 'none',
            opacity: 0.4,
        },
    })
);

type SearchPanelProps = {
    batchId: string;
};

export default function SearchPanel({ batchId }: SearchPanelProps) {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const approveBatchLoading = useAppSelector((state) => state.batches.approveStatus.loading);

    const [enteredBatchId, setEnteredBatchId] = useState<string>(batchId);
    const handleBatchIdChange = (e: { target: { value: any } }) => {
        setEnteredBatchId(e.target.value);
    };

    const handleShowRegressionInputClick = () => dispatch(selectBatchId(enteredBatchId));
    const handleApproveClick = (type: 'NDRE' | 'NDVI' | 'WDRRE') =>
        dispatch(fetchApproveBatch({ batchId: enteredBatchId, type: type }));
    const handleEstimateClick = () => dispatch(fetchEstimateBatch(enteredBatchId));

    const handleCtaClick = (selectedCtaIndex: number) => {
        switch (selectedCtaIndex) {
            case 0:
                handleShowRegressionInputClick();
                return;
            case 1:
                handleApproveClick('WDRRE');
                return;
            case 2:
                handleApproveClick('NDRE');
                return;
            case 3:
                handleApproveClick('NDVI');
                return;
            case 4:
                handleEstimateClick();
                return;
            default:
                return;
        }
    };

    return (
        <>
            <ApproveToaster />
            <EstimateToaster />
            {approveBatchLoading && (
                <Alert severity="info">Please wait while we approve the batch</Alert>
            )}
            {approveBatchLoading && <LinearProgress />}
            <Paper className={classes.root}>
                <Box
                    display="flex"
                    flexDirection="row"
                    gridColumnGap={16}
                    alignItems="center"
                    className={approveBatchLoading ? classes.disableBox : ''}
                >
                    <TextField
                        variant="outlined"
                        label="Batch ID"
                        placeholder="e.g.WIwoapIMscDzCqTkCfBr "
                        value={enteredBatchId}
                        onChange={handleBatchIdChange}
                        className={classes.textField}
                    />
                    <CtaGroup
                        options={[
                            'Show',
                            'Approve WDRRE',
                            'Approve NDRE',
                            'Approve NDVI',
                            'Estimate',
                        ]}
                        onClick={handleCtaClick}
                    />
                </Box>
            </Paper>
        </>
    );
}
