import React from 'react';
import Box from '@material-ui/core/Box';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from 'plotly.js-basic-dist';
import Divider from '@material-ui/core/Divider';
import { RegressionOutput } from './RegressionOutput';

const chart = (
    type: string,
    fnZoneValue: (i: RegressionOutput) => number,
    fnMean: (i: RegressionOutput) => number,
    output: RegressionOutput[]
) => {
    const Plot = createPlotlyComponent(Plotly);

    const xs = output.map(fnZoneValue);
    const ys = output.map(fnMean);
    const chartData = {
        scatter: { x: xs, y: ys, text: `${type} Regression Output` },
    };

    return (
        <Box>
            <Plot
                data={[
                    {
                        type: 'scatter',
                        mode: 'markers',
                        name: 'Linear Regression',
                        x: chartData.scatter.x,
                        y: chartData.scatter.y,
                        // trendline: 'ols',
                        text: chartData.scatter.text,
                        textposition: 'auto',
                        hoverinfo: 'none',
                    },
                ]}
                layout={{
                    title: `${type} Regression Output`,
                    showlegend: false,
                    xaxis: { title: `Zone ${type}` },
                    yaxis: { title: 'HAPGR Mean' },
                    dragmode: 'pan',
                }}
                config={{
                    modeBarButtonsToRemove: ['lasso2d', 'select2d'],
                }}
            />
        </Box>
    );
};

type OutputGraphProps = {
    output: RegressionOutput[];
};

export default function OutputGraph({ output }: OutputGraphProps) {
    return (
        <Box display="flex" flexDirection="column" gridColumnGap={16}>
            {chart(
                'NDRE',
                (i) => i.zone_ndre,
                (i) => i.hapgr_mean_from_ndre,
                output
            )}
            <Divider />
            {chart(
                'NDVI',
                (i) => i.zone_ndvi,
                (i) => i.hapgr_mean_from_ndvi,
                output
            )}
        </Box>
    );
}
