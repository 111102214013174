import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// @ts-ignore
import mapboxgl, { Map } from 'mapbox-gl/dist/mapbox-gl-csp';
// @ts-ignore
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import PaddocksToDeploy from './PaddocksToDeploy';
import Box from '@material-ui/core/Box';
import {
    addOldSourceToMap,
    loadMap,
    MAPBOX_ACCESS_TOKEN,
    removeOldSourceFromMap,
} from './mapContainerUtil';
import { PaddockChanges } from './onboardTypes';
import Button from '@material-ui/core/Button';
import { useAppDispatch } from '../../../app/hooks';
import { fetchMoveInactiveZones } from './onboardSlice';
mapboxgl.workerClass = MapboxWorker; // Wire up loaded worker to be used instead of the default

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        map: {
            height: 500,
        },
    })
);

type ExistingZonesMapProps = {
    farmId: number;
    geoJsonOld: any;
    asOf: string;
};

export default function ExistingZonesMap({ farmId, geoJsonOld = {}, asOf }: ExistingZonesMapProps) {
    const classes = useStyles();

    const dispatch = useAppDispatch();

    const mapContainer = useRef(null);
    const map = useRef<Map>(null);
    const [styleLoaded, setStyleLoaded] = useState(false);

    const [zonesChanges, setZonesChanges] = useState<PaddockChanges[]>([]);

    const addOldSource = useCallback(() => {
        addOldSourceToMap(map, styleLoaded, geoJsonOld);
    }, [geoJsonOld, styleLoaded]);

    const removeOldSource = useCallback(() => {
        removeOldSourceFromMap(map, styleLoaded);
    }, [styleLoaded]);

    useEffect(() => {
        if (!loadMap(mapContainer, map)) {
            return;
        }

        map.current.on('load', () => {
            if (map.current) {
                addOldSource();
            }
        });

        map.current.on('styledata', () => {
            setStyleLoaded(true);
        });
    });

    useEffect(() => {
        removeOldSource();
        addOldSource();
    }, [addOldSource, geoJsonOld, removeOldSource]);

    useEffect(() => {
        if (map.current && styleLoaded) {
            addOldSource();
        }
    }, [addOldSource, styleLoaded]);

    const handleSave = () => {
        dispatch(
            fetchMoveInactiveZones({
                farmId: farmId,
                zonesChanges: zonesChanges,
                asOf: asOf,
            })
        );
    };

    return (
        <div ref={mapContainer} className={classes.map}>
            <Box position="absolute" right={4} top={4} zIndex={999} width="fit-content">
                <PaddocksToDeploy
                    paddocks={geoJsonOld?.features?.map(
                        (f: any) =>
                            ({
                                name: f.properties?.name,
                                hide: f.properties?.hide,
                            } as PaddockChanges)
                    )}
                    onPaddocksChange={setZonesChanges}
                />
                <Button size="small" variant="contained" color="primary" onClick={handleSave}>
                    Save
                </Button>
            </Box>
        </div>
    );
}
