import firebase from 'firebase/app';
import { get } from '../../../../api/client';
import 'firebase/auth';

type FarmVegetationIndexImageResponse = {
    exists: boolean;
    location?: string;
};
const vegetationIndexImageNotAvailable = { exists: false };

export const getFarmVegetationIndexImage = async (
    farmId: number,
    date: string
): Promise<FarmVegetationIndexImageResponse> => {
    const token = await firebase.auth().currentUser?.getIdToken();

    if (!token) {
        return vegetationIndexImageNotAvailable;
    }

    return await get(token, `vi-image/${farmId}/asof/${date}`)
        .then((r) => ({ exists: true, ...r }))
        .catch((r) => vegetationIndexImageNotAvailable);
};
