import _ from 'lodash';
import { UserActivityLogModel } from './api/UserActivityLogModel';

export const mergeEvents = (
    events: UserActivityLogModel[],
    getDisplayName: (log: UserActivityLogModel) => string
): UserActivityLogModel[] =>
    _.reduce(
        events,
        (acc, curr) => {
            const { eventId, payload, traceParent, payloadIdentifier } = curr;
            const { begin, end } = payload as any;
            const existingEvent = _.find(
                acc,
                (e) => e.traceParent === traceParent && e.payloadIdentifier === payloadIdentifier
            );

            if (existingEvent) {
                existingEvent.eventId =
                    existingEvent.payload.begin < begin ? existingEvent.eventId : eventId;
                existingEvent.payload.begin =
                    existingEvent.payload.begin < begin ? existingEvent.payload.begin : begin;
                existingEvent.payload.end =
                    existingEvent.payload.end > end ? existingEvent.payload.end : end;
            } else {
                acc.push({ ...curr, displayName: getDisplayName(curr) });
            }

            return acc;
        },
        [] as UserActivityLogModel[]
    );
