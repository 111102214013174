import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    makeStyles,
    Theme,
    useMediaQuery,
    useTheme,
    createStyles,
} from '@material-ui/core';
import { Close as CloseIcon, CloudDownload as DownloadIcon } from '@material-ui/icons';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        img: {
            maxWidth: '100%',
            maxHeight: '80vh',
            display: 'block',
            marginRight: 'auto',
            marginLeft: 'auto',
        },
        imgContainer: {
            paddingTop: 0,
        },
        closeBtn: {
            position: 'absolute',
            right: 8,
            top: 8,
        },
    })
);

type VegetationIndexImageDialogProps = {
    imageLocation: string;
    title: string;
    onClose: () => void;
};

export const VegetationIndexImageDialog = ({
    imageLocation,
    title,
    onClose,
}: VegetationIndexImageDialogProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
    const [loaded, setLoaded] = useState(false);

    return (
        <Dialog
            fullScreen={!largeScreen}
            fullWidth={true}
            maxWidth="md"
            open={true}
            onClose={onClose}
        >
            <DialogTitle>
                {title}
                <div className={classes.closeBtn}>
                    <IconButton href={imageLocation} download>
                        <DownloadIcon />
                    </IconButton>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent className={classes.imgContainer}>
                {!loaded && <>Loading...</>}
                <img
                    src={imageLocation}
                    alt="Farm vegetation index"
                    className={classes.img}
                    onLoad={() => setLoaded(true)}
                />
            </DialogContent>
        </Dialog>
    );
};
