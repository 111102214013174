import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Paddock } from './paddockType';
import { getPaddocks } from './paddocksApi';
import { RootState } from '../../app/store';
import { middayUtcStr } from '../../utils/DateUtil';

interface PaddocksState {
    paddocks: Paddock[];
    loading: boolean;
    status: string;
    error: string | null;
}

export const fetchPaddocks = createAsyncThunk(
    'paddockGroups/fetchPaddocks',
    async ({
        farmId,
        farmLocalTimeZone,
        from,
        to,
    }: {
        farmId: number;
        farmLocalTimeZone: string;
        from: Date;
        to: Date;
    }): Promise<Paddock[]> => {
        return await getPaddocks(
            farmId,
            middayUtcStr(from.toISOString(), farmLocalTimeZone),
            middayUtcStr(to.toISOString(), farmLocalTimeZone)
        );
    }
);

const initialState: PaddocksState = {
    paddocks: [],
    loading: false,
    status: 'idle',
    error: null,
};

export const paddocksSlice = createSlice({
    name: 'paddocks',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchPaddocks.pending.type]: (state: PaddocksState) => {
            state.paddocks = [];
            state.loading = true;
            state.status = 'loading';
            state.error = null;
        },
        [fetchPaddocks.fulfilled.type]: (
            state: PaddocksState,
            action: PayloadAction<Paddock[]>
        ) => {
            state.paddocks = action.payload;
            state.loading = false;
            state.status = 'succeeded';
            state.error = null;
        },
        [fetchPaddocks.rejected.type]: (state: PaddocksState, action: PayloadAction<string>) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.payload;
        },
    },
});

export const selectPaddockById = (state: RootState, paddockId: number): Paddock =>
    state.paddocks.paddocks.find((p) => p.paddockId === paddockId) ?? initialState.paddocks[0];

export default paddocksSlice.reducer;
