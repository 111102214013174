import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import DetailsSaveResponse from './DetailsSaveResponse';
import FarmsAutoComplete from '../../farms/FarmsAutoComplete';
import { Farm } from '../../farms/farmType';
import { fetchFarmOnboardCheck } from './onboardSlice';
import ExistingZoneBoundaries from './ExistingZoneBoundaries';

type DetailsProps = {
    selectedFarm: Farm | null;
    setSelectedFarm: (farm: Farm | null) => void;
};

export default function Details({ selectedFarm, setSelectedFarm }: DetailsProps) {
    const dispatch = useAppDispatch();
    const farmGroupId = useAppSelector((state) => (state.firebase as any).profile?.farmGroupId);

    const [refresh, setRefresh] = useState(false);

    const handleFarmSelect = (farm: Farm) => {
        if (!farm) return;
        setSelectedFarm(farm);
        dispatch(fetchFarmOnboardCheck(farm.farmId));
    };

    return (
        <>
            {farmGroupId ? (
                <FarmsAutoComplete
                    farmGroupId={farmGroupId}
                    onSelect={handleFarmSelect}
                    preSelectedFarm={selectedFarm || null}
                    refresh={refresh}
                    afterLoad={() => setRefresh(false)}
                />
            ) : (
                <div>Loading Farms ...</div>
            )}
            <Box mt={2} />
            {selectedFarm && (
                <>
                    <DetailsSaveResponse farmId={selectedFarm.farmId} />
                    <Divider />
                    <ExistingZoneBoundaries
                        farmId={selectedFarm.farmId}
                        farmLocalTimeZone={selectedFarm.timeZone}
                    />
                </>
            )}
        </>
    );
}
