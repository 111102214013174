import firebase from 'firebase/app';
import { post } from '../../../../api/client';
import { Scene } from './Scene';
import 'firebase/auth';

export const downloadScenes = async (scenes: Scene[]): Promise<void> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    await post(token!, `ndvi-process-tracking`, scenes);
};

export const batchScenes = async (
    processIds: string[],
    wdrreWeight: number,
    wdrreLimit: number
): Promise<any> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    return await post(
        token!,
        `ndvi-process-batch/weight/${wdrreWeight}/limit/${wdrreLimit}`,
        processIds
    );
};
