import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { shallowEqual } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { fetchMotesByFarmGroup } from '../../../motes/motesSlice';
import { DateTime } from 'luxon';
import { MoteAssociation } from './interfaces/FarmMotesAssociation.d';
import { startOfDay, startOfDayDT } from '../../../../utils/DateUtil';
import { selectFilters } from '../../../../features/measurements/filters/filtersSlice';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: '17rem',
        },
    })
);

type MotesAutoCompleteProps = {
    selectedFarmGroupId: number;
    preSelectedMotes: MoteAssociation[] | null;
    dateFrom: DateTime;
    clearValues: boolean;
    onSelect: (motes: MoteAssociation[]) => void;
};

export default function MultiMotesAutoComplete({
    selectedFarmGroupId = -1,
    preSelectedMotes,
    dateFrom,
    clearValues,
    onSelect,
}: MotesAutoCompleteProps) {
    const classes = styles();
    const dispatch = useAppDispatch();
    const profileFarmGroupId = useAppSelector(
        (state) => (state.firebase as any).profile?.farmGroupId
    );
    const farmGroupId = selectedFarmGroupId !== -1 ? selectedFarmGroupId : profileFarmGroupId;
    const filters = useAppSelector(selectFilters);
    const { motesLoading, motes } = useAppSelector((state) => {
        return {
            motesLoading: state.motes.loading,
            motesError: state.motes.error,
            motes: farmGroupId > -1 ? state.motes.motes : [],
        };
    }, shallowEqual);

    const [values, setValues] = React.useState<MoteAssociation[]>([]);
    useEffect(() => {
        const startDate = startOfDayDT(dateFrom, filters.farm.timeZone).toUTC().toJSDate();
        const endDate = startOfDay(dateFrom, filters.farm.timeZone)
            .toUTC()
            .plus({ days: 1 })
            .toJSDate();

        if (farmGroupId > 0) {
            dispatch(
                fetchMotesByFarmGroup({ farmGroupId: farmGroupId, from: startDate, to: endDate })
            );
        }
    }, [dateFrom, dispatch, farmGroupId, filters.farm.timeZone]);

    useEffect(() => {
        if (clearValues) setValues([]);
    }, [clearValues]);

    return (
        <Autocomplete
            id="motes"
            multiple
            loading={motesLoading}
            options={motes}
            getOptionDisabled={(m) =>
                preSelectedMotes != null && preSelectedMotes.some((v) => v.moteId === m.moteId)
            }
            value={values}
            getOptionLabel={(option) => option.moteName ?? option.devEui}
            renderInput={(params) => <TextField {...params} label="Mote" variant="outlined" />}
            onChange={(event: any, newValue: any) => {
                onSelect(newValue);
                setValues(newValue);
            }}
            className={classes.root}
        />
    );
}
