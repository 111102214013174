import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { Box, CircularProgress } from '@material-ui/core';
import { ArrowDropDownCircle } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            textAlign: 'center',
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        },
    })
);

type Props = {
    hasMore: boolean;
    loading: boolean;
    skip: number;
    fetchMore: (n: number) => void;
};
export const FetchNext = (props: Props) => {
    const classes = useStyles();
    const { hasMore, loading, skip, fetchMore } = props;

    const fetchClickHandler = () => {
        fetchMore(skip + 1);
    };

    const mainContent = loading ? (
        <CircularProgress />
    ) : (
        <IconButton disabled={!hasMore} onClick={fetchClickHandler}>
            <ArrowDropDownCircle />
        </IconButton>
    );
    return <Box className={classes.root}>{mainContent}</Box>;
};
