import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STATUS } from '../../../../../utils/Store/Constants';
import { transferMote, fetchAssociations } from './associationsApi';

export interface AssociationTransferSlice {
    response: any;
    loading: boolean;
    status: string;
    error: string | null;
}

const initialStatus: Status = {
    response: null,
    loading: false,
    status: STATUS.idle,
    error: null,
};

export const initialState: AssociationsState = {
    fetchStatus: initialStatus,
    transferStatus: initialStatus,
};

type AssociationTransferProps = {
    moteId: number;
    farmGroupId: number;
    date: string;
};

interface Status {
    response: any;
    loading: boolean;
    status: string;
    error: string | null;
}

export interface AssociationsState {
    fetchStatus: Status;
    transferStatus: Status;
}

export const transferMoteAssociations = createAsyncThunk(
    'motes/transferAssociation',
    async ({ moteId, farmGroupId, date }: AssociationTransferProps): Promise<any> => {
        return await transferMote({ moteId: moteId, farmGroupId: farmGroupId, selectedDate: date });
    }
);

export const fetchMoteAssociations = createAsyncThunk(
    'motes/fetchAssociations',
    async ({ moteId }: any): Promise<any> => {
        return await fetchAssociations({ moteId: moteId });
    }
);

export const associationsSlice = createSlice({
    name: 'transfer',
    initialState,
    reducers: {
        reset(state) {
            const transferAssociationResponse = state.transferStatus.response;
            return {
                ...initialState,
                transferStatus: {
                    ...initialState.transferStatus,
                    response: transferAssociationResponse,
                },
            };
        },
        clearTransferAssociations: () => initialState,
    },
    extraReducers: {
        [transferMoteAssociations.pending.type]: (state: AssociationsState) => {
            state.transferStatus.response = null;
            state.transferStatus.loading = true;
            state.transferStatus.status = STATUS.loading;
            state.transferStatus.error = null;
        },
        [transferMoteAssociations.fulfilled.type]: (
            state: AssociationsState,
            action: PayloadAction<any>
        ) => {
            state.transferStatus.response = action.payload;
            state.transferStatus.loading = false;
            state.transferStatus.status = STATUS.succeeded;
            state.transferStatus.error = null;
        },
        [transferMoteAssociations.rejected.type]: (
            state: AssociationsState,
            action: PayloadAction<any>
        ) => {
            state.transferStatus.loading = false;
            state.transferStatus.status = STATUS.failed;
            state.transferStatus.error = action.payload?.error;
        },
        [fetchMoteAssociations.pending.type]: (state: AssociationsState) => {
            state.fetchStatus.response = null;
            state.fetchStatus.loading = true;
            state.fetchStatus.status = STATUS.loading;
            state.fetchStatus.error = null;
        },
        [fetchMoteAssociations.fulfilled.type]: (
            state: AssociationsState,
            action: PayloadAction<any>
        ) => {
            state.fetchStatus.response = action.payload;
            state.fetchStatus.loading = false;
            state.fetchStatus.status = STATUS.succeeded;
            state.fetchStatus.error = null;
        },
        [fetchMoteAssociations.rejected.type]: (
            state: AssociationsState,
            action: PayloadAction<any>
        ) => {
            state.fetchStatus.loading = false;
            state.fetchStatus.status = STATUS.failed;
            state.fetchStatus.error = action.payload?.error;
        },
    },
});

export const { reset, clearTransferAssociations } = associationsSlice.actions;

export default associationsSlice.reducer;
