import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPaddockMeasurements, downloadPaddockMeasurements } from '../api/paddockMeasurementsApi';
import { PaddockMeasurementGroup } from '../types/paddockMeasurementTypes';
import { FiltersState } from '../../../measurements/filters/filtersSlice';
import { DATE_FORMATS, fromStrISOToDateTime, middayUtcStr } from '../../../../utils/DateUtil';
import { RootState } from '../../../../app/store';
import { buildChartData, getPaddockCovers } from '../../feedWedge/feedWedgeBuilder';
import FileDownload from 'js-file-download';

export interface PaddockMeasurementsState {
    measurements: PaddockMeasurementGroup[];
    selectedPaddockId: number;
    downloadableMeasurements: any;
    fetchStatus: string;
    error: string;
}

export const initialState: PaddockMeasurementsState = {
    measurements: [],
    selectedPaddockId: 0,
    downloadableMeasurements: [],
    fetchStatus: 'idle',
    error: '',
};

export const fetchPaddockMeasurements = createAsyncThunk(
    'paddocks/fetchPaddockMeasurements',
    async (filters: FiltersState): Promise<PaddockMeasurementGroup[]> => {
        return await getPaddockMeasurements(
            middayUtcStr(
                fromStrISOToDateTime(filters.from).toFormat(DATE_FORMATS.yearMonthDayShort),
                filters.farm.timeZone
            ),
            middayUtcStr(
                fromStrISOToDateTime(filters.to).toFormat(DATE_FORMATS.yearMonthDayShort),
                filters.farm.timeZone
            ),
            filters.farm.farmId,
            filters.targets.postGrazingTarget
        );
    }
);

export const fetchDownloadPaddockMeasurements = createAsyncThunk(
    'paddocks/fetchDownloadPaddockMeasurements',
    async (filters: FiltersState): Promise<void> => {
        return await downloadPaddockMeasurements(
            middayUtcStr(
                fromStrISOToDateTime(filters.from).toFormat(DATE_FORMATS.yearMonthDayShort),
                filters.farm.timeZone
            ),
            middayUtcStr(
                fromStrISOToDateTime(filters.to).toFormat(DATE_FORMATS.yearMonthDayShort),
                filters.farm.timeZone
            ),
            filters.farm.farmId,
            filters.farm.farmName,
            filters.targets.postGrazingTarget
        );
    }
);

export const paddockMeasurementsSlice = createSlice({
    name: 'paddocks',
    initialState,
    reducers: {
        selectPaddock: (state, action: PayloadAction<number>) => {
            state.selectedPaddockId = action.payload;
        },
    },
    extraReducers: {
        [fetchPaddockMeasurements.pending.type]: (state: PaddockMeasurementsState) => {
            state.measurements = [];
            state.fetchStatus = 'loading';
        },
        [fetchPaddockMeasurements.fulfilled.type]: (
            state: PaddockMeasurementsState,
            action: PayloadAction<PaddockMeasurementGroup[]>
        ) => {
            state.measurements = action.payload;
            state.fetchStatus = 'succeeded';
        },
        [fetchPaddockMeasurements.rejected.type]: (
            state: PaddockMeasurementsState,
            action: PayloadAction<string>
        ) => {
            state.measurements = [];
            state.fetchStatus = 'failed';
            state.error = action.payload;
        },
        [fetchDownloadPaddockMeasurements.fulfilled.type]: (
            state: PaddockMeasurementsState,
            action: PayloadAction<any>
        ) => {
            state.downloadableMeasurements = (action.payload as any).data;
            FileDownload((action.payload as any).data, (action.payload as any).fileName);
        },
    },
});

export const selectAllMeasurements = (state: RootState) => state.paddockMeasurements.measurements;
export const selectMeasurementByDate = (state: RootState, date: string) =>
    selectAllMeasurements(state).find((m) => new Date(m.date)?.toISOString() === date);

export const selectActiveZoneGroupGrowths = (state: RootState, date: Date | undefined) => {
    let latestMeasurement;
    if (!date) {
        [latestMeasurement] = selectAllMeasurements(state);
    } else {
        latestMeasurement = selectMeasurementByDate(state, date?.toISOString());
    }
    const activeZoneGroups = latestMeasurement?.zone_groups?.filter((zg) => zg.is_active);
    return activeZoneGroups?.map((zg) => {
        const covers = getPaddockCovers(zg, state.filters);
        const feedWedgeData = buildChartData(covers);
        return { covers, feedWedgeData };
    });
};
export const { selectPaddock } = paddockMeasurementsSlice.actions;

export default paddockMeasurementsSlice.reducer;
