import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';
import MissingIcon from '@material-ui/icons/Close';
import indigo from '@material-ui/core/colors/indigo';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import { OnboardFarmResponse } from './onboardTypes';
import clsx from 'clsx';
import { fromStrISOToDateTime } from 'utils/DateUtil';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        code: {
            backgroundColor: indigo[100],
            padding: theme.spacing(1),
        },
        missing: {
            backgroundColor: red[100],
        },
        doneIcon: {
            color: green[500],
        },
        missingIcon: {
            color: red[500],
        },
    })
);

type FarmOnboardChecklistProps = {
    response: OnboardFarmResponse;
};

export default function FarmOnboardChecklist({ response }: FarmOnboardChecklistProps) {
    const classes = useStyles();

    const headerCell = (name: string, hasMissing: boolean) => (
        <TableCell>
            <Box display="flex" flexDirection="row" gridColumnGap={8} alignItems="center">
                {hasMissing ? (
                    <MissingIcon className={classes.missingIcon} />
                ) : (
                    <DoneIcon className={classes.doneIcon} />
                )}{' '}
                {name}
            </Box>
        </TableCell>
    );

    const code = (label: any) => (
        <code className={clsx(classes.code, !label && classes.missing)}>
            <strong>{label}</strong>
        </code>
    );

    return (
        <Table>
            <TableBody>
                <TableRow>
                    {headerCell(
                        'Farm',
                        !response.farmId ||
                            !response.farmName ||
                            !response.farmStartDate ||
                            !response.address
                    )}
                    <TableCell>
                        {code(response.farmId)} {code(response.farmName)} at{' '}
                        {code(response.address)}
                        on{' '}
                        {code(
                            fromStrISOToDateTime(
                                response.farmStartDate,
                                response.farmTimeZone
                            ).toString()
                        )}{' '}
                    </TableCell>
                </TableRow>
                <TableRow>
                    {headerCell(
                        'Farm Group',
                        !response.farmGroupId ||
                            !response.farmGroupName ||
                            !response.farmGroupStartDate
                    )}
                    <TableCell>
                        {code(response.farmGroupId)} {code(response.farmGroupName)} on{' '}
                        {code(response.farmGroupStartDate)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    {headerCell(
                        'Farm Collective',
                        !response.farmCollectiveId ||
                            !response.farmCollectiveName ||
                            !response.farmCollectiveStartDate
                    )}
                    <TableCell>
                        {code(response.farmCollectiveId)} {code(response.farmCollectiveName)} on{' '}
                        {code(response.farmCollectiveStartDate)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    {headerCell(
                        'Zone Groups',
                        !response.zoneGroups || response.zoneGroups.length === 0
                    )}
                    <TableCell>{code(response.zoneGroups.join(' , '))}</TableCell>
                </TableRow>
                <TableRow>
                    {headerCell('Net / Gross', false)}
                    <TableCell>
                        {code(response.farmNetStartDate ? 'Net' : 'Gross')}{' '}
                        {response.farmNetStartDate && `from `}{' '}
                        {response.farmNetStartDate && code(response.farmNetStartDate)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    {headerCell('Time zone', false)}
                    <TableCell>
                        {code(response.farmTimeZone)} ESPG: {code(response.epsg)}
                    </TableCell>
                </TableRow>
                {response.farmActiveArea && (
                    <TableRow>
                        {headerCell('Farm Active Area', false)}
                        <TableCell>{code(response.farmActiveArea)}</TableCell>
                    </TableRow>
                )}
                {response.farmTotalArea && (
                    <TableRow>
                        {headerCell('Farm Total Area', false)}
                        <TableCell>{code(response.farmTotalArea)}</TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
}
