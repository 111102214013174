import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Farm } from '../../farms/farmType';
import FarmsAutoComplete from '../../farms/FarmsAutoComplete';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { apply, FiltersState, initialState } from './filtersSlice';
import DateRangePicker from '../../../components/DateRangePicker';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import CloseIcon from '@material-ui/icons/ExpandLess';
import { DATE_FORMATS, fromStrISOToDateTime } from '../../../utils/DateUtil';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 20,
            width: 'fit-content',
            margin: '0 auto',
            alignItems: 'center',
        },
        datePicker: {
            whiteSpace: 'pre-line',
            maxWidth: 180,
        },
        buttonLeft: {
            marginRight: theme.spacing(1),
        },
        formControl: {
            width: 180,
        },
        close: {
            position: 'absolute',
            top: 10,
            right: 10,
        },
    })
);

type FiltersProps = {
    onApply: () => void;
    onCancel: () => void;
};

export default function Filters({ onApply, onCancel }: FiltersProps) {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const farmGroupId = useAppSelector((state) => (state.firebase as any).profile?.farmGroupId);
    const farms = useAppSelector((state) => state.farms.farms);
    const filters = useAppSelector((state) => state.filters);

    const uid = useAppSelector((state) => (state.firebase as any).auth?.uid);
    const firestore = useFirestore();
    const filtersWritable = firestore.collection('dashboard-filters').doc(uid);
    useFirestoreConnect([
        {
            collection: 'dashboard-filters',
            doc: uid,
            storeAs: 'filters',
        },
    ]);
    const filtersReadable = useAppSelector((state) => (state.firestore as any)?.data?.filters);

    const handleSave = async (objName: string, objValue: any) => {
        await filtersWritable.set({
            ...filtersReadable,
            [objName]: objValue,
        });
    };

    useEffect(() => {
        if (filtersReadable && filtersReadable.farm) {
            dispatch(
                apply({
                    ...initialState,
                    farm: filtersReadable.farm,
                    targets: filtersReadable.targets ?? initialState.targets,
                })
            );
            handleFarmSelect(filtersReadable.farm);
        } else if (farms && farms.length === 1) {
            const farm = farms[0];
            dispatch(
                apply({
                    ...initialState,
                    farm,
                })
            );
            handleFarmSelect(farm);
        } else {
            handleFarmSelect(filters.farm);
        }
    }, [dispatch, farms, filters.farm, filtersReadable]);

    const [dateRange, setDateRange] = React.useState([
        fromStrISOToDateTime(filters.from),
        fromStrISOToDateTime(filters.to),
    ]);
    const [areFieldsCleared, setAreFieldsCleared] = useState(false);
    const handleAfterReset = () => setAreFieldsCleared(false);

    const [selectedFarm, setSelectedFarm] = useState<Farm>(initialState.farm);
    const handleFarmSelect = (farm: Farm) => {
        setSelectedFarm(farm);
    };

    const modifiedFilters: FiltersState = {
        ...filters,
        farm: selectedFarm ?? { farmId: 0, farmName: '', timeZone: DateTime.local().zoneName },
        timeZone: selectedFarm?.timeZone ?? DateTime.local().zoneName,
        from: dateRange[0].toISO(),
        to: dateRange[1].toISO(),
    };

    const handleApply = () => {
        dispatch(apply(modifiedFilters));
        onApply();
        handleSave('farm', modifiedFilters.farm);
    };

    const clearFields = () => {
        setSelectedFarm(filters.farm);
        setDateRange([fromStrISOToDateTime(filters.from), fromStrISOToDateTime(filters.to)]);
    };

    const handleCancel = () => {
        clearFields();
        onCancel();
    };

    if (!farmGroupId) {
        return <div>Loading...</div>;
    }

    return (
        <div className={classes.root}>
            <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="center"
                gridRowGap={16}
                gridColumnGap={16}
            >
                <DateRangePicker
                    value={dateRange}
                    placeholder="Select a date range"
                    onChange={(values: any) => {
                        setDateRange([values.begin, values.end]);
                    }}
                    autoOk={true}
                    format={DATE_FORMATS.dayMonthYearShort}
                    reset={areFieldsCleared}
                    onAfterReset={handleAfterReset}
                />
                <FarmsAutoComplete
                    farmGroupId={farmGroupId}
                    preSelectedFarm={selectedFarm}
                    onSelect={handleFarmSelect}
                />
                <CloseIcon className={classes.close} color="secondary" onClick={handleCancel} />
            </Box>
            <Box paddingTop={2} textAlign="right">
                <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={handleApply}
                    disabled={filters === modifiedFilters}
                >
                    Search
                </Button>
            </Box>
        </div>
    );
}
