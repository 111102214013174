import Page from '../../../../components/Page';
import MoteAssociationTransfer from './moteAssociationTransfer';

export default function MoteAssociations() {
    return (
        <Page title="Mote Associations">
            <MoteAssociationTransfer />
        </Page>
    );
}
