import firebase from 'firebase/app';
import { post } from '../../api/client';
import 'firebase/auth';
import 'firebase/database';

export const moteAutoMapping = async (
    farmGroupId: number,
    since: string,
    until: string
): Promise<any> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    return await post(
        token!,
        `queue/zone-mote-mapping/farmGroup/${farmGroupId}/since/${since}/until/${until}`,
        {}
    );
};
