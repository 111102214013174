import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useFirestoreConnect } from 'react-redux-firebase';
import ScenesDataGrid from './ScenesDataGrid';
import Box from '@material-ui/core/Box';
import Cta from '../../../../components/Cta';
import { closeSnackbar, fetchBatchScenes } from './scenesSlice';
import BatchScenesToaster from './BatchScenesToaster';
import { Alert } from '@material-ui/lab';
import { LinearProgress } from '@material-ui/core';

export default function NotBatchedScenes() {
    const dispatch = useAppDispatch();
    useFirestoreConnect([
        {
            collection: 'ndvi-process-tracking',
            storeAs: 'scenes',
            orderBy: [
                ['date_reported_str', 'desc'],
                ['farm_name', 'asc'],
            ],
            where: [
                ['is_batched', '==', false],
                ['has_ndvi_run', '==', true],
                ['is_archived', '==', false],
            ],
        },
    ]);
    const scenes = useAppSelector((state) => (state.firestore as any).data?.scenes);
    const { batchScenesLoading, showSnackbar, error, isSuccessful } = useAppSelector((state) => ({
        batchScenesLoading: state.scenes.batchScenesStatus.loading,
        error: state.scenes.batchScenesStatus.error,
        isSuccessful: state.scenes.batchScenesStatus.status === 'succeeded',
        showSnackbar: state.scenes.showSnackbar,
    }));

    const [selectedScenes, setSelectedScenes] = useState<string[]>([]);
    const wdrreWeight = 0.1;
    const wdrreLimit = 85;

    const handleBatchScenes = () => {
        dispatch(
            fetchBatchScenes({
                processIds: selectedScenes,
                wdrreWeight: wdrreWeight,
                wdrreLimit: wdrreLimit,
            })
        );
        setSelectedScenes([]);
        setTimeout(() => dispatch(closeSnackbar()), 12000);
    };

    return (
        <>
            <BatchScenesToaster
                open={showSnackbar}
                isSuccessful={isSuccessful}
                successMessage="Batch is created!"
                errorMessage={`Error creating the batch! ${error ?? ''}`}
            />
            {batchScenesLoading && <Alert severity="info">Creating batch ...</Alert>}
            {batchScenesLoading && <LinearProgress />}
            <ScenesDataGrid scenes={scenes} enableSelection onSelect={setSelectedScenes} />
            <Box marginTop={2} textAlign="right">
                <Cta
                    label="Batch Scenes"
                    onClick={handleBatchScenes}
                    isDisabled={selectedScenes.length === 0}
                />
            </Box>
        </>
    );
}
