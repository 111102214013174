import { ZoneGrazedEvent, ZoneGrazedModel, ZoneGrazedPayload } from './grazingsHistoryType';
import _ from 'lodash';

export const mergeEvents = (
    events: ZoneGrazedEvent[],
    getDisplayName: (l: ZoneGrazedEvent) => string
): ZoneGrazedModel[] =>
    _.reduce(
        events,
        (acc, curr) => {
            const { eventId, eventTime, payload, traceParent, payloadIdentifier } = curr;
            const { begin, end, userId, zoneId, grazedGroupId } = payload as ZoneGrazedPayload;
            const existingEvent = _.find(
                acc,
                (e) => e.traceParent === traceParent && e.payloadIdentifier === payloadIdentifier
            );

            if (existingEvent) {
                existingEvent.eventId =
                    existingEvent.begin < begin ? existingEvent.eventId : eventId;
                existingEvent.begin = existingEvent.begin < begin ? existingEvent.begin : begin;
                existingEvent.end = existingEvent.end > end ? existingEvent.end : end;
            } else {
                acc.push({
                    eventId,
                    eventTime,
                    displayName: getDisplayName(curr),
                    begin,
                    end,
                    user: userId,
                    zoneId,
                    grazedGroupId,
                    traceParent,
                    payloadIdentifier,
                });
            }

            return acc;
        },
        [] as ZoneGrazedModel[]
    );
