import firebase from 'firebase/app';
import { get, post, del } from '../../../../api/client';
import 'firebase/auth';

export const deleteBatch = async (batchId: string): Promise<{ isDeleted: boolean }> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    return await del(token!, `regression/${batchId}`, {});
};

export const approveBatch = async (
    batchId: string,
    type: 'NDRE' | 'NDVI' | 'WDRRE'
): Promise<{ isApproved: boolean }> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    return await post(token!, `regression/approve/${batchId}/type/${type}`, {});
};

export const estimateBatch = async (batchId: string): Promise<{ isQueued: boolean }> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    return await post(token!, `pphs/estimate/batch/${batchId}`, {});
};

export const reprocessBatch = async (
    batchId: string,
    wdrreWeight: number,
    wdrreLimit: number
): Promise<{ isQueued: boolean }> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    return await get(
        token!,
        `regression/reprocess/${batchId}/weight/${wdrreWeight}/limit/${wdrreLimit}`
    );
};
