import React from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { useFirestoreConnect } from 'react-redux-firebase';
import BatchesDataGrid from './BatchesDataGrid';

export default function NotApprovedBatches() {
    useFirestoreConnect([
        {
            collection: 'ndvi-process-batch',
            storeAs: 'batches',
            orderBy: ['date_reported_str', 'desc'],
            where: [
                ['is_approved', '==', false],
                ['is_archived', '==', false],
            ],
        },
    ]);
    const batches = useAppSelector((state) => (state.firestore as any).data?.batches);

    return (
        <BatchesDataGrid batches={batches} enableSelection={false} enableDeletion showRegression />
    );
}
