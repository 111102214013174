import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface Notification {
    id: string;
    message: string;
    severity?: 'success' | 'info' | 'warning' | 'error';
    autoHideDuration?: number;
}

interface NotificationsState {
    notifications: Notification[];
}

const initialState: NotificationsState = {
    notifications: [],
};

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addNotification(state, action: PayloadAction<Omit<Notification, 'id'>>) {
            const id = Math.floor(Math.random() * 10000).toString();
            const notifications = [...state.notifications, { ...action.payload, id }];

            return { notifications };
        },
        dismissNotification(state, action: PayloadAction<string>) {
            const notifications = state.notifications.filter((n) => n.id !== action.payload);

            return { notifications };
        },
    },
});

export const { addNotification, dismissNotification } = notificationsSlice.actions;
export const selectNotifications = (state: RootState) => state.notifications.notifications;
export default notificationsSlice.reducer;
