import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { moteAutoMapping } from './mappingsApi';

interface MappingsSlice {
    response: any;
    loading: boolean;
    status: 'idle' | 'failed' | 'loading' | 'succeeded';
    error: string | null;
}

type AutoMappingProps = {
    farmGroupId: number;
    since: Date;
    until: Date;
};

export const autoMapping = createAsyncThunk(
    'mappings/autoMapping',
    async ({ farmGroupId, since, until }: AutoMappingProps, { rejectWithValue }): Promise<any> => {
        try {
            return await moteAutoMapping(
                farmGroupId,
                since.toLocaleDateString('fr-CA'),
                until.toLocaleDateString('fr-CA')
            );
        } catch (err: any) {
            return rejectWithValue(err.message ?? err.details);
        }
    }
);

const initialState: MappingsSlice = {
    response: null,
    status: 'idle',
    loading: false,
    error: null,
};

export const mappingsSlice = createSlice({
    name: 'mappings',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: {
        [autoMapping.pending.type]: (state: MappingsSlice) => {
            state.loading = true;
            state.status = 'loading';
            state.error = null;
        },
        [autoMapping.fulfilled.type]: (state: MappingsSlice, action: PayloadAction<any[]>) => {
            state.response = action.payload;
            state.loading = false;
            state.status = 'succeeded';
            state.error = null;
        },
        [autoMapping.rejected.type]: (state: MappingsSlice, action: PayloadAction<string>) => {
            state.loading = false;
            state.status = 'failed';
            state.error = action.payload;
        },
    },
});

export const { reset } = mappingsSlice.actions;

export default mappingsSlice.reducer;
