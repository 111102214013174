import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DATE_FORMATS } from '../utils/DateUtil';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        datePicker: {
            whiteSpace: 'pre-line',
            maxWidth: '12rem',
        },
    })
);

type DateSelectorProps = {
    label: string;
    onChange: (date: Date) => void;
    reset?: boolean;
    onAfterReset: () => void;
    disableFuture?: boolean;
    date?: Date;
};

export default function DateSelector({
    label,
    onChange,
    reset,
    onAfterReset,
    disableFuture = true,
    date = new Date(),
}: DateSelectorProps) {
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    const [selectedDate, setSelectedDate] = useState<Date>(date);
    const handleOnChange = (date: MaterialUiPickersDate) => {
        if (date) {
            setSelectedDate(date.toJSDate());
            onChange(date.toJSDate());
        }
    };

    useEffect(() => {
        if (reset) {
            setSelectedDate(date);
            onAfterReset();
        }
    }, [reset, onAfterReset, date]);

    return (
        <div>
            <KeyboardDatePicker
                autoOk
                variant={isDesktop ? 'inline' : 'dialog'}
                inputVariant="outlined"
                label={label}
                format={DATE_FORMATS.dayMonthYearShort}
                value={selectedDate}
                minDate={new Date('01/01/2018')}
                disableFuture={disableFuture}
                InputAdornmentProps={{ position: 'start' }}
                onChange={handleOnChange}
                className={classes.datePicker}
                fullWidth
            />
        </div>
    );
}
