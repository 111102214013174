import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        checkboxWrapper: {
            backgroundColor: '#ffffff',
            paddingLeft: 4,
        },
        label: {
            fontSize: 12,
        },
    })
);

type GeoJsonSelectionProps = {
    setSelections: (showExisting: boolean, showNewUpload: boolean) => void;
};

export default function GeoJsonSelection({ setSelections }: GeoJsonSelectionProps) {
    const classes = useStyles();

    const [selectedGeoJsons, setSelectedGeoJsons] = React.useState({
        showExisting: true,
        showNewUpload: true,
    });

    const { showExisting, showNewUpload } = selectedGeoJsons;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedJsons = { ...selectedGeoJsons, [event.target.name]: event.target.checked };
        setSelectedGeoJsons(selectedJsons);
        setSelections(selectedJsons.showExisting, selectedJsons.showNewUpload);
    };

    return (
        <div className={classes.checkboxWrapper}>
            <FormControl component="fieldset">
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showExisting}
                                onChange={handleChange}
                                name="showExisting"
                                size="small"
                            />
                        }
                        label="Existing"
                        classes={{ label: classes.label }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showNewUpload}
                                onChange={handleChange}
                                name="showNewUpload"
                                size="small"
                            />
                        }
                        label="New"
                        classes={{ label: classes.label }}
                    />
                </FormGroup>
            </FormControl>
        </div>
    );
}
