import firebase from 'firebase/app';
import { get, post } from '../../api/client';
import { PaddockGroup } from './paddockGroupType';
import 'firebase/auth';

export const getPaddockGroups = async (farmId: number): Promise<PaddockGroup[]> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    return await get(token!, `paddock-groups/farm/${farmId}`);
};

export const createNewPaddockGroup = async (
    paddockGroupName: string,
    farmId: number,
    isCuttingGroup: boolean
): Promise<void> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    return await post(token!, `paddock-groups/new`, {
        zone_group_name: paddockGroupName,
        farm_id: farmId,
        is_cutting_group: isCuttingGroup,
        active: true,
    });
};

export const movePaddockToGroup = async (
    paddockId: number,
    groupId: number,
    asOf: string,
    farmId: number
): Promise<void> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    return await get(
        token!,
        `paddock-groups/move/paddock/${paddockId}/to/group/${groupId}/asof/${asOf}/farm/${farmId}`
    );
};
