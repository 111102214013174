import React from 'react';
import { isEmpty, isLoaded, useFirebase } from 'react-redux-firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import bg from '../../resources/cows-mote-1200x628.jpg';
import logo from '../../resources/Farmote-Company-Logo.png';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            backgroundImage: `url(${bg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            height: '100vh',
        },
        login: {
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 50%)',
            padding: theme.spacing(2),
            backgroundColor: 'rgba(255,255,255,0.3)',
        },
        logo: {
            width: '25rem',
        },
    })
);

function Login() {
    const firebase: any = useFirebase();
    const history = useHistory();
    const location = useLocation();
    const { from } = location.state || ({ from: { pathname: '/' } } as any);
    const auth = useAppSelector((state) => (state.firebase as any).auth);
    const classes = useStyles();

    if (!isLoaded(auth)) {
        return <span>Loading...</span>;
    }

    if (!isEmpty(auth)) {
        history.replace(from);
    }

    return (
        <div className={classes.root}>
            <div className={classes.login}>
                <Box width="100%" display="flex" justifyContent="center">
                    <img src={logo} alt="logo" className={classes.logo} />
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                    <Typography variant="body1">Precise Pasture Management</Typography>
                </Box>
                <StyledFirebaseAuth
                    uiConfig={{
                        signInFlow: 'redirect',
                        signInSuccessUrl: '/',
                        signInOptions: [
                            firebase.auth.EmailAuthProvider.PROVIDER_ID,
                            // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                            // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                        ],
                        callbacks: {
                            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                                firebase.handleRedirectResult(authResult).then(() => {
                                    history.push(redirectUrl ?? '/');
                                });
                                return false;
                            },
                        },
                    }}
                    firebaseAuth={firebase.auth()}
                />
            </div>
        </div>
    );
}

export default Login;
