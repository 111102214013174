import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    deletePaddockMovesFromDate,
    fetchPaddockMoveHistory,
    PaddockMoveHistoryArgs,
    reset,
} from './paddockMoveHistorySlice';
import { EventType } from 'components/activity-log/EventType';
import { PaddockMoved } from './paddockMoveHistoryType';
import { STATUS } from 'utils/Store/Constants';
import { addNotification } from 'features/notifications/notificationsSlice';
import PaddockTable from './data-table/Index';
import { BoxCenter } from 'components/BoxCenter';
import { PaddockDictionary } from 'features/paddocks/paddockType';
import { PaddockGroupsDictionary } from 'features/paddock-groups/paddockGroupType';
import _filter from 'lodash/filter';

type ContentProps = {
    farmId: number;
    paddocks: PaddockDictionary;
    paddockGroups: PaddockGroupsDictionary;
    setRefreshPaddocks: (value: boolean) => void;
};
export function PaddockMoveHistoryContent(props: ContentProps): JSX.Element {
    const { farmId, paddocks, paddockGroups, setRefreshPaddocks } = props;

    if (!Object.keys(paddocks).length || !Object.keys(paddockGroups).length) {
        return <BoxCenter description="Loading..." />;
    }

    return (
        <PaddockMoveHistory
            farmId={`${farmId}`}
            paddocks={paddocks}
            paddockGroups={paddockGroups}
            setRefreshPaddocks={setRefreshPaddocks}
        ></PaddockMoveHistory>
    );
}

type Props = {
    farmId: string;
    paddocks: PaddockDictionary;
    paddockGroups: PaddockGroupsDictionary;
    setRefreshPaddocks: (value: boolean) => void;
};
export default function PaddockMoveHistory(props: Props) {
    const dispatch = useAppDispatch();
    const { farmId, paddocks, paddockGroups, setRefreshPaddocks } = props;

    const [rowsPerPage] = useState<number>(100);
    const [ualPage, setPage] = useState<number>(1);
    const [data, setData] = useState<PaddockMoved[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);

    const { fetchLoading, paddockMoved } = useAppSelector((state) => {
        return {
            fetchLoading: state.paddockHistory.fetchStatus.loading,
            paddockMoved: state.paddockHistory.paddockMoved,
        };
    });

    const { deleteLoading, deleteStatus, movesDeleted } = useAppSelector((state) => {
        return {
            deleteLoading: state.paddockHistory.deleteStatus.loading,
            deleteStatus: state.paddockHistory.deleteStatus.status,
            movesDeleted: state.paddockHistory.paddockMovesDelete,
        };
    });

    const requestData = useMemo(() => {
        const req: PaddockMoveHistoryArgs = {
            farmId: farmId,
            page: ualPage,
            limit: rowsPerPage,
            eventType: EventType.PaddockMoved,
        };
        return req;
    }, [farmId, rowsPerPage, ualPage]);

    useEffect(() => {
        dispatch(fetchPaddockMoveHistory(requestData));
        return () => {
            dispatch(reset());
        };
    }, [dispatch, requestData]);

    useEffect(() => {
        if (movesDeleted?.length > 0) {
            setData((p) => _filter(p, (v) => !movesDeleted.includes(v.payload.reference, 0)));
            setRefresh(true);
            return;
        }
        setData(paddockMoved);
    }, [paddockMoved, movesDeleted]);

    useEffect(() => {
        if (deleteStatus === STATUS.succeeded) {
            dispatch(addNotification({ message: 'Paddock move deleted', severity: 'success' }));
        }
    }, [deleteStatus, dispatch]);

    const onItemDeleted = (paddockId: string, groupId: string, date: string) => {
        dispatch(deletePaddockMovesFromDate({ paddockId, groupId, from: date, farmId }));
        setRefresh(false);
        setRefreshPaddocks(true);
    };

    return (
        <PaddockTable
            setPage={setPage}
            page={ualPage}
            rowsPerPage={rowsPerPage}
            data={data}
            refresh={refresh}
            loading={fetchLoading || deleteLoading}
            paddocks={paddocks}
            paddockGroups={paddockGroups}
            onItemDeleted={onItemDeleted}
        />
    );
}
