import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { fetchGrazingsHistory, GrazingsHistoryArgs, reset } from './grazingsHistorySlice';
import { deleteZoneGrazed } from './grazingsHistoryApi';
import { ZoneGrazedEvent } from './grazingsHistoryType';
import GrazingsHistoryTable from './data-table/Index';
import { getPaddockZonesAsDictionary } from 'features/paddocks/paddock.selectors';
import { addNotification } from 'features/notifications/notificationsSlice';
import _reject from 'lodash/reject';

type Props = {
    farmId: string;
    collectionModified: boolean;
};

export default function GrazingHistory({ farmId, collectionModified }: Props) {
    const dispatch = useAppDispatch();

    const [rowsPerPage] = useState<number>(100);
    const [ualPage, setPage] = useState<number>(1);
    const [data, setData] = useState<ZoneGrazedEvent[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);

    const zones = useAppSelector(getPaddockZonesAsDictionary);

    const { fetchLoading, grazingReported } = useAppSelector((state) => {
        return {
            fetchLoading: state.grazingsHistory.fetchStatus.loading,
            grazingReported: state.grazingsHistory.grazingReported,
        };
    });

    const requestData = useMemo(() => {
        const req: GrazingsHistoryArgs = {
            farmId: `${farmId}`,
            page: ualPage,
            limit: rowsPerPage,
        };
        return req;
    }, [farmId, rowsPerPage, ualPage]);

    const fetchData = useCallback(() => {
        dispatch(fetchGrazingsHistory(requestData));
    }, [dispatch, requestData]);

    useEffect(() => {
        fetchData();
        return () => {
            dispatch(reset());
        };
    }, [dispatch, fetchData]);

    useEffect(() => {
        setData(grazingReported);
    }, [grazingReported]);

    const onItemDeleted = (zoneId: string, grazedGroupId: string) => {
        deleteZoneGrazed({ farmId, zoneId, grazedGroupId })
            .then(() => {
                dispatch(addNotification({ message: 'Grazing deleted', severity: 'success' }));
                const exclusion = (f: ZoneGrazedEvent) =>
                    f.payload.zoneId === zoneId && f.payload.grazedGroupId === grazedGroupId;
                setData((r) => _reject(r, exclusion));
                setRefresh(true);
            })
            .catch(() => {
                dispatch(
                    addNotification({
                        message: 'We were unable to delete the grazing',
                        severity: 'error',
                    })
                );
            });
    };

    return (
        <GrazingsHistoryTable
            setPage={setPage}
            page={ualPage}
            rowsPerPage={rowsPerPage}
            data={data}
            loading={fetchLoading}
            zones={zones}
            refresh={refresh}
            onIconRefreshClicked={fetchData}
            onItemDeleted={onItemDeleted}
            showRefreshIcon={collectionModified}
        />
    );
}
