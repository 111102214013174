import { isLoaded } from 'react-redux-firebase';
import Login from './Login';
import { useAppSelector } from '../../app/hooks';

export default function AuthIsLoaded({ children }: any) {
    const auth = useAppSelector((state) => (state.firebase as any).auth);
    if (!isLoaded(auth)) {
        return <Login />;
    }
    return children;
}
