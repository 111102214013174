import {
    configureStore,
    ThunkAction,
    Action,
    createListenerMiddleware,
    TypedStartListening,
    addListener,
    TypedAddListener,
    ListenerEffectAPI,
    getDefaultMiddleware,
} from '@reduxjs/toolkit';
import farmsReducer from '../features/farms/farmsSlice';
import farmCollectivesReducer from '../features/farm-collectives/farmCollectivesSlice';
import farmGroupsReducer from '../features/farm-groups/farmGroupsSlice';
import paddocksReducer from '../features/paddocks/paddocksSlice';
import paddockGroupsReducer from '../features/paddock-groups/paddockGroupsSlice';
import paddockHistoryReducer from 'features/paddock-history/paddockMoveHistorySlice';
import grazingsHistoryReducer from 'features/grazings-history/grazingsHistorySlice';
import motesReducer from '../features/motes/motesSlice';
import grazingsReducer from '../features/paddocks/actions/grazings/grazingsSlice';
import filtersReducer from '../features/measurements/filters/filtersSlice';
import paddockMeasurementsReducer from '../features/paddocks/measurements/reducers/paddockMeasurementsSlice';
import parkMeasurementsReducer from '../features/paddocks/measurements/reducers/parkMeasurementsSlice';
import scenesReducer from '../features/ndvi/tracker/scenes/scenesSlice';
import regressionReducer from '../features/ndvi/tracker/regression/regressionSlice';
import batchesReducer from '../features/ndvi/tracker/batches/batchesSlice';
import pphProcessorReducer from '../features/pph-processor/pphProcessorSlice';
import mappingsReducer from '../features/auto-mapping/mappingsSlice';
import associationsReducer from '../features/admin/motes/associations/store/associationsSlice';
import batchAssociationsReducer from '../features/admin/motes/associations/store/batchAssociationsSlice';
import { actionTypes, firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import onboardingReducer from '../features/admin/onboard/onboardSlice';
import notificationsReducer from '../features/notifications/notificationsSlice';

const listenerMiddlewareInstance = createListenerMiddleware({
    onError: () => console.error,
});

export const store = configureStore({
    reducer: {
        farms: farmsReducer,
        farmCollectives: farmCollectivesReducer,
        farmGroups: farmGroupsReducer,
        associations: associationsReducer,
        batchAssociations: batchAssociationsReducer,
        paddocks: paddocksReducer,
        paddockGroups: paddockGroupsReducer,
        paddockHistory: paddockHistoryReducer,
        grazingsHistory: grazingsHistoryReducer,
        motes: motesReducer,
        mappings: mappingsReducer,
        filters: filtersReducer,
        paddockMeasurements: paddockMeasurementsReducer,
        parkMeasurements: parkMeasurementsReducer,
        scenes: scenesReducer,
        regression: regressionReducer,
        batches: batchesReducer,
        grazings: grazingsReducer,
        pphProcessor: pphProcessorReducer,
        firebase: firebaseReducer,
        firestore: firestoreReducer,
        onboarding: onboardingReducer,
        notifications: notificationsReducer,
    },
    middleware: () =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [actionTypes.LOGIN],
            },
        }).prepend(listenerMiddlewareInstance.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export type AppListenerEffectAPI = ListenerEffectAPI<RootState, AppDispatch>;
export type AppStartListening = TypedStartListening<RootState, AppDispatch>;
export type AppAddListener = TypedAddListener<RootState, AppDispatch>;
export const startAppListening = listenerMiddlewareInstance.startListening as AppStartListening;
export const addAppListener = addListener as AppAddListener;
