import { RootState } from 'app/store';
import { createSelector } from '@reduxjs/toolkit';
import { PaddockGroupsDictionary } from './paddockGroupType';
import _each from 'lodash/each';

const paddockGroupsSlice = (state: RootState) => state.paddockGroups;

const pg: PaddockGroupsDictionary = {};
export const getPaddockGroupsAsDictionary = createSelector(
    paddockGroupsSlice,
    (state): PaddockGroupsDictionary => {
        if (Object.keys(pg).length > 0) return pg;
        _each(state?.groups, (g) => (pg[`${g.groupId}`] = g));
        return pg;
    }
);

export const selectPaddockGroups = createSelector(paddockGroupsSlice, (state) => state.groups);
