import {
    FarmOnboard,
    OnboardFarmBoundaryRequest,
    OnboardFarmBoundaryResponse,
    OnboardFarmRequest,
    OnboardFarmResponse,
    OnboardMoveInactiveRequest,
    OnboardZoneBoundariesRequest,
    OnboardZoneBoundariesResponse,
    ZoneBoundariesRequest,
} from './onboardTypes';
import firebase from 'firebase/app';
import { get, post } from '../../../api/client';
import 'firebase/auth';

export const getAllFarms = async (): Promise<FarmOnboard[]> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
        return [];
    }
    return await get(token, `onboard/farms`);
};

export async function onboaringNewFarm(req: OnboardFarmRequest): Promise<OnboardFarmResponse> {
    const token = await firebase.auth().currentUser?.getIdToken();
    return await post(token!, `onboard/farm`, req);
}

export async function onboardFarmCheck(farmId: number): Promise<OnboardFarmResponse> {
    const token = await firebase.auth().currentUser?.getIdToken();
    return await get(token!, `onboard/farm/check/${farmId}`);
}

export async function onboardFarmBoundary(
    req: OnboardFarmBoundaryRequest
): Promise<OnboardFarmBoundaryResponse> {
    const token = await firebase.auth().currentUser?.getIdToken();
    return await post(token!, `onboard/farm/boundary`, req);
}

export async function onboardZoneBoundaries(
    req: OnboardZoneBoundariesRequest
): Promise<OnboardZoneBoundariesResponse> {
    const token = await firebase.auth().currentUser?.getIdToken();
    return await post(token!, `onboard/zone/boundary`, req);
}

export async function onboardMoveInactiveZones(
    req: OnboardMoveInactiveRequest
): Promise<OnboardZoneBoundariesResponse> {
    const token = await firebase.auth().currentUser?.getIdToken();
    return await post(token!, `zones/move/inactive/farm/${req.farmId}/asof/${req.asOf}`, req);
}

export async function getZoneBoundaries(req: ZoneBoundariesRequest): Promise<any> {
    const token = await firebase.auth().currentUser?.getIdToken();
    return await get(token!, `zones/boundaries/farm/${req.farmId}/asof/${req.asOf}`);
}
