import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    })
);

type LoadingProps = {
    label: string;
    size: 'small' | 'medium' | 'large' | undefined;
    loading?: boolean;
    children?: React.ReactNode;
};

export default function LoadingButton({ label, loading, children, size }: LoadingProps) {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            {!loading && children}
            {loading && (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        size={size || 'small'}
                        disabled={loading}
                    >
                        {label}
                    </Button>
                    <CircularProgress size={24} className={classes.buttonProgress} />
                </>
            )}
        </div>
    );
}
