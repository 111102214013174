import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';

type NotificationProps = {
    notification: {
        id: string;
        message: string;
        severity?: 'success' | 'info' | 'warning' | 'error';
        autoHideDuration?: number;
    };
    onDismiss: (id: string) => void;
};

export const Notification = ({
    notification: { id, message, severity, autoHideDuration = 6000 },
    onDismiss,
}: NotificationProps) => {
    const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        onDismiss(id);
    };

    return (
        <Snackbar
            open={true}
            autoHideDuration={autoHideDuration}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleClose}
        >
            <Alert severity={severity} variant="filled" onClose={handleClose}>
                {message}
            </Alert>
        </Snackbar>
    );
};
