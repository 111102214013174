import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/FilterListSharp';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { DateTime } from 'luxon';
import { DATE_FORMATS } from '../../../utils/DateUtil';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            padding: theme.spacing(1),
            cursor: 'pointer',
        },
        icon: {
            marginRight: theme.spacing(2),
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
                color: '#ffffff',
            },
        },
    })
);

type FiltersSummaryProps = {
    from: DateTime;
    to: DateTime;
    appliedTo: string;
    timeZone: string;
    onEdit: () => void;
};

export default function FiltersSummary({
    from,
    to,
    appliedTo,
    timeZone,
    onEdit,
}: FiltersSummaryProps) {
    const classes = useStyles();

    return (
        <div className={classes.root} onClick={onEdit}>
            <Box textAlign="center" flexGrow={1}>
                <Typography variant="body2">
                    <strong>{appliedTo}</strong> from{' '}
                    <strong>{from?.toFormat(DATE_FORMATS.yearMonthDayShort)}</strong> to{' '}
                    <strong>{to?.toFormat(DATE_FORMATS.yearMonthDayShort)}</strong> in{' '}
                    <strong>{timeZone}</strong>
                </Typography>
            </Box>
            <IconButton aria-label="edit-filters" size="small" className={classes.icon}>
                <EditIcon fontSize="small" />
            </IconButton>
        </div>
    );
}
