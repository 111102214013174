import React, { useCallback, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Drawer, TextField } from '@material-ui/core';
import Indigo from '@material-ui/core/colors/indigo';
import Box from '@material-ui/core/Box';
import Cta from '../../components/Cta';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { apply, selectFilters } from '../measurements/filters/filtersSlice';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { selectFarmById } from '../farms/farmsSlice';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import _ from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            width: '30%',
            [theme.breakpoints.down('md')]: {
                width: '50%',
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        toolbar: {
            backgroundColor: Indigo[50],
        },
        title: {
            flexGrow: 1,
        },
        formControl: {
            width: 180,
        },
    })
);

declare interface GrazingOptionType {
    inputValue?: string;
    title: string;
    target: number;
}
declare interface Grazings {
    preGrazing: number;
    postGrazing: number;
    preCutting: number;
}

type PaddockTargetsProps = {
    openDrawer: boolean;
    onClose: () => void;
};

// TODO: Func should be delete once we a form validation such as Formik
//  _.isNumber only works with numbers, not a string that contains number.
//  parseInt() returns 43 for a value like this: "43s"
//  Because of ts type check, isNaN doesn't accept string, which it does in js
export function isParseableToNumber(value: any): Boolean {
    if (!value) return false;
    return !isNaN(Number(value));
}

function grazingOptionSorting(a: GrazingOptionType, b: GrazingOptionType): number {
    return a.target - b.target;
}

function convertArrayToGrazingOptionsType(options: any[]): GrazingOptionType[] {
    return _.uniq(options.map((v) => Number(v)))
        .map<GrazingOptionType>((v) => CreateGazingOption(v))
        .sort(grazingOptionSorting);
}

function CreateGazingOption(value: string | number): GrazingOptionType {
    return {
        title: value?.toString(),
        inputValue: value?.toString(),
        target: Number(value),
    };
}

export default function PaddockTargets({ openDrawer, onClose }: PaddockTargetsProps) {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const filters = useAppSelector(selectFilters);
    const farm = useAppSelector((state) => selectFarmById(state, filters.farm.farmId));

    const optionsfilter = createFilterOptions<GrazingOptionType>();

    const [targetValues, setTargetValues] = useState<Grazings | null>({
        preGrazing: filters.targets.preGrazingTarget,
        postGrazing: filters.targets.postGrazingTarget,
        preCutting: filters.targets.preCuttingTarget,
    });

    const [isSaveButtonEnabled, setSaveButtonEnabled] = useState<boolean>(false);
    const [preGrazingValues] = useState<GrazingOptionType[]>(
        convertArrayToGrazingOptionsType([
            filters.targets.preGrazingTarget,
            '2400',
            '2550',
            '2700',
            '2850',
            '3000',
            '3150',
            '3300',
        ])
    );
    const [postGrazingValues] = useState<GrazingOptionType[]>(
        convertArrayToGrazingOptionsType([
            filters.targets.postGrazingTarget,
            '1050',
            '1200',
            '1350',
            '1500',
            '1650',
            '1800',
            '1950',
        ])
    );
    const [preCuttingValues] = useState<GrazingOptionType[]>(
        convertArrayToGrazingOptionsType([
            filters.targets.preCuttingTarget,
            '4000',
            '4250',
            '4500',
            '4750',
            '5000',
            '5250',
            '5500',
            '5750',
            '6000',
        ])
    );

    const uid = useAppSelector((state) => (state.firebase as any).auth?.uid);

    const firestore = useFirestore();
    const filtersWritable = firestore.collection('dashboard-filters').doc(uid);

    useFirestoreConnect([
        {
            collection: 'dashboard-filters',
            doc: uid,
            storeAs: 'filters',
        },
    ]);
    const filtersReadable = useAppSelector((state) => (state.firestore as any)?.data?.filters);

    const hasGrazingTargetsChanged = useCallback(
        (source: any): boolean => {
            return (
                source &&
                source.targets &&
                filters.targets.postGrazingTarget !== source.targets.postGrazingTarget &&
                filters.targets.preGrazingTarget !== source.targets.preGrazingTarget &&
                filters.targets.preCuttingTarget !== source.targets.preCuttingTarget
            );
        },
        [
            filters.targets.postGrazingTarget,
            filters.targets.preCuttingTarget,
            filters.targets.preGrazingTarget,
        ]
    );

    const saveFiltersToDB = async (objName: string, objValue: any) => {
        await filtersWritable.set({
            ...filtersReadable,
            [objName]: objValue,
        });
    };

    const handleSaveClick = () => {
        const targets = {
            preGrazingTarget: targetValues?.preGrazing ?? 0,
            postGrazingTarget: targetValues?.postGrazing ?? 0,
            preCuttingTarget: targetValues?.preCutting ?? 0,
        };
        const modifiedFilters = {
            ...filters,
            targets,
        };

        dispatch(apply(modifiedFilters));
        saveFiltersToDB('targets', targets).then(onClose);
    };

    useEffect(() => {
        if (hasGrazingTargetsChanged(filtersReadable)) {
            const modifiedFilters = {
                ...filters,
                targets: filtersReadable.targets,
            };
            dispatch(apply(modifiedFilters));

            setTargetValues({
                preGrazing: filtersReadable.targets.preGrazingTarget,
                postGrazing: filtersReadable.targets.postGrazingTarget,
                preCutting: filtersReadable.targets.preCuttingTarget,
            });
        }
    }, [filtersReadable, dispatch, filters, hasGrazingTargetsChanged]);

    useEffect(() => {
        let targetValuesHaveChanged =
            filters.targets.postGrazingTarget !== targetValues?.postGrazing ||
            filters.targets.preGrazingTarget !== targetValues?.preGrazing ||
            filters.targets.preCuttingTarget !== targetValues?.preCutting;

        setSaveButtonEnabled(targetValuesHaveChanged);
    }, [
        filters.targets.postGrazingTarget,
        filters.targets.preCuttingTarget,
        filters.targets.preGrazingTarget,
        targetValues,
    ]);

    const targetSelect = (options: GrazingOptionType[], name: string, label: string) => (
        <Autocomplete
            options={options}
            freeSolo
            selectOnFocus
            openOnFocus
            handleHomeEndKeys
            className={classes.formControl}
            renderOption={(option) => option.title}
            renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
            value={(targetValues as any)[name].toString()}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option;
                }
                if (option.inputValue) {
                    return option.inputValue;
                }
                return option.title;
            }}
            onChange={(event, newValue) => {
                if (!newValue) return;

                let value = typeof newValue === 'string' ? newValue : newValue?.target;

                if (!isParseableToNumber(value)) {
                    return;
                }

                if (targetValues) {
                    setTargetValues({ ...targetValues, [name]: Number(value) });
                }
            }}
            filterOptions={(options: GrazingOptionType[], params) => {
                const filtered = optionsfilter(options, params);
                if (isParseableToNumber(params.inputValue)) {
                    let newOption = {
                        inputValue: params.inputValue,
                        title: `Add "${params.inputValue}"`,
                        target: Number(params.inputValue),
                    };
                    filtered.unshift(newOption);
                }
                return filtered;
            }}
        />
    );

    return (
        <Drawer
            anchor="right"
            open={openDrawer}
            onClose={onClose}
            classes={{
                paper: classes.drawer,
            }}
        >
            <Toolbar className={classes.toolbar}>
                <Typography variant="h6" className={classes.title}>
                    Targets
                </Typography>
                <IconButton aria-label="close drawer" onClick={onClose} edge="start">
                    <CloseIcon />
                </IconButton>
            </Toolbar>
            <Box display="flex" flexDirection="column" margin={2}>
                <Box padding={1}>
                    {targetSelect(preGrazingValues, 'preGrazing', 'Pre Grazing Target')}
                </Box>
                <Box padding={1}>
                    {targetSelect(postGrazingValues, 'postGrazing', 'Post Grazing Target')}
                </Box>
                {farm?.hasCuttingGroup && (
                    <Box padding={1}>
                        {targetSelect(preCuttingValues, 'preCutting', 'Pre Cutting Target')}
                    </Box>
                )}
                <Box padding={1} className={classes.formControl} textAlign="right">
                    <Cta isDisabled={!isSaveButtonEnabled} label="Save" onClick={handleSaveClick} />
                </Box>
            </Box>
        </Drawer>
    );
}
