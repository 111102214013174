import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from '../features/auth/Login';
import PrivateRoute from './PrivateRoute';
import Paddocks from '../features/paddocks/measurements';
import NdviTracker from '../features/ndvi/tracker';
import Settings from '../features/settings';
import Onboard from '../features/admin/onboard';
import MoteAssociations from '../features/admin/motes/associations';

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/">
                <Paddocks />
            </PrivateRoute>
            <PrivateRoute exact path="/ndvi/tracker/regression/:batchId">
                <NdviTracker />
            </PrivateRoute>
            <PrivateRoute exact path="/ndvi/tracker">
                <NdviTracker />
            </PrivateRoute>
            <PrivateRoute exact path="/settings">
                <Settings />
            </PrivateRoute>
            <PrivateRoute exact path="/onboard">
                <Onboard />
            </PrivateRoute>
            <PrivateRoute exact path="/mote/associations">
                <MoteAssociations />
            </PrivateRoute>
        </Switch>
    );
}
