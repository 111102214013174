import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaddockMoved } from './paddockMoveHistoryType';
import { deletePaddockMovesFrom, getPaddockHistoryByFarm } from './paddockMoveHistoryApi';
import { useFriendlyErrorHandler } from '../notifications/hooks';
import { STATUS } from 'utils/Store/Constants';

interface Status {
    loading: boolean;
    status: string;
    error: string | null;
}

export interface PaddockHistoryState {
    paddockMoved: PaddockMoved[];
    fetchStatus: Status;
    paddockMovesDelete: string[];
    deleteStatus: Status;
}

const initialStatus: Status = {
    loading: false,
    status: STATUS.idle,
    error: null,
};

export const initialState: PaddockHistoryState = {
    paddockMoved: [],
    fetchStatus: initialStatus,
    paddockMovesDelete: [],
    deleteStatus: initialStatus,
};

export type PaddockMoveHistoryArgs = {
    farmId: string;
    eventType: string;
    page: number;
    limit: number;
};

export type DeletePaddockMoveArgs = {
    paddockId: string;
    groupId: string;
    from: string;
    farmId: string;
};

export const fetchPaddockMoveHistory = createAsyncThunk(
    'paddockHistory/fetchPaddockMove',
    async (
        { farmId, eventType, page, limit }: PaddockMoveHistoryArgs,
        { rejectWithValue, dispatch }
    ) => {
        const callbackFnc = async () =>
            await getPaddockHistoryByFarm<PaddockMoved[]>({
                farmId,
                eventType,
                page,
                limit,
            }).catch((err) =>
                Promise.reject({ message: 'We were unable to retrieve the paddock moves' })
            );

        return await useFriendlyErrorHandler(callbackFnc, rejectWithValue, dispatch, {
            severity: 'warning',
        });
    }
);

export const deletePaddockMovesFromDate = createAsyncThunk(
    'paddockHistory/DeletePaddockMovesFrom',
    async (
        { paddockId, groupId, from, farmId }: DeletePaddockMoveArgs,
        { rejectWithValue, dispatch }
    ) => {
        const callbackFnc = async () =>
            await deletePaddockMovesFrom<string[]>({ paddockId, groupId, from, farmId }).catch(
                (err) => Promise.reject({ message: 'We were unable to delete the paddock move' })
            );

        return await useFriendlyErrorHandler(callbackFnc, rejectWithValue, dispatch);
    }
);

export const paddockHistorySlice = createSlice({
    name: 'paddockMoveHistory',
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: {
        [fetchPaddockMoveHistory.pending.type]: (state: PaddockHistoryState) => {
            state.fetchStatus = {
                loading: true,
                status: STATUS.loading,
                error: null,
            };
        },
        [fetchPaddockMoveHistory.fulfilled.type]: (
            state: PaddockHistoryState,
            action: PayloadAction<PaddockMoved[]>
        ) => {
            state.paddockMoved = action.payload;
            state.fetchStatus = {
                loading: false,
                status: STATUS.succeeded,
                error: null,
            };
        },
        [fetchPaddockMoveHistory.rejected.type]: (
            state: PaddockHistoryState,
            action: PayloadAction<string>
        ) => {
            state.fetchStatus = {
                loading: false,
                status: STATUS.failed,
                error: action.payload,
            };
        },

        [deletePaddockMovesFromDate.pending.type]: (state: PaddockHistoryState) => {
            state.deleteStatus = {
                loading: true,
                status: STATUS.loading,
                error: null,
            };
        },
        [deletePaddockMovesFromDate.fulfilled.type]: (
            state: PaddockHistoryState,
            action: PayloadAction<string[]>
        ) => {
            state.paddockMovesDelete = action.payload;
            state.deleteStatus = {
                loading: false,
                status: STATUS.succeeded,
                error: null,
            };
        },
        [deletePaddockMovesFromDate.rejected.type]: (
            state: PaddockHistoryState,
            action: PayloadAction<string>
        ) => {
            state.deleteStatus = {
                loading: false,
                status: STATUS.failed,
                error: action.payload,
            };
        },
    },
});
export const { reset } = paddockHistorySlice.actions;
export default paddockHistorySlice.reducer;
