import firebase from 'firebase/app';
import { post } from '../../../../api/client';
import { Adjustment, AdjustmentTypes } from './adjustmentTypes';
import 'firebase/auth';

export const reportMoteAdjustments = async (
    farmId: number,
    farmName: string,
    adjustments: Adjustment[]
): Promise<void> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
        return;
    }

    const adjustmentsDto = adjustments.map((a) => ({
        mote_id: a.mote.moteId,
        mote_name: a.mote.moteName,
        type: Object.keys(AdjustmentTypes)[Object.values(AdjustmentTypes).indexOf(a.adjustment)],
        begin: a.begin,
        end: a.end,
        reason: a.reason,
        notes: a.notes,
        pgr: a.enteredPgr,
    }));

    await post(token, `mote-adjustment/farm/${farmId}/${farmName}`, adjustmentsDto);
};
