import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Paper, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FarmsAutoComplete from '../../../farms/FarmsAutoComplete';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { Farm } from '../../../farms/farmType';
import Cta from '../../../../components/Cta';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Alert, Skeleton } from '@material-ui/lab';
import { downloadScene } from './scenesSlice';
import { useFirestoreConnect } from 'react-redux-firebase';
import DownloadProgress from './DownloadProgress';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginTop: theme.spacing(2),
        },
        textField: {
            minWidth: '30ch',
        },
        flexWrap: {
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'flex-start',
            },
        },
    })
);

export default function Downloader() {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const farmGroupId = useAppSelector((state) => (state.firebase as any).profile?.farmGroupId);

    const [sceneId, setSceneId] = useState<string>();
    const handleSceneIdChange = (e: { target: { value: any } }) => {
        setSceneId(e.target.value);
    };

    const [selectedFarm, setSelectedFarm] = useState<Farm | null>(null);
    const handleFarmSelect = (farm: Farm) => {
        setSelectedFarm(farm);
    };

    useFirestoreConnect([
        {
            collection: 'ndvi-process-tracking',
            storeAs: 'sceneToDownload',
            where: [
                ['scene_id', '==', sceneId ?? ''],
                ['farm_id', '==', selectedFarm?.farmId ?? ''],
                ['is_archived', '==', false],
            ],
        },
    ]);
    const sceneFromDB = useAppSelector((state) => (state.firestore as any).data?.sceneToDownload);

    const isCtaDisabled = sceneId?.length === 0 || !selectedFarm || sceneFromDB;

    const handleSceneAddClick = () => {
        dispatch(
            downloadScene({
                sceneId: sceneId!,
                farmId: selectedFarm?.farmId!,
            })
        );
        setSceneId('');
        setSelectedFarm(null);
    };

    return (
        <Paper className={classes.paper}>
            {sceneFromDB && (
                <Alert severity="error">Scene already exists {Object.keys(sceneFromDB)[0]}</Alert>
            )}
            <Box padding={1}>
                <Typography variant="subtitle1">Download Scenes</Typography>
            </Box>
            <Divider />
            <Box width="fit-content" margin="0 auto" padding={2}>
                <Box display="flex" flexDirection="column" gridRowGap={16}>
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        gridColumnGap={16}
                        gridRowGap={16}
                        justifyContent="center"
                        className={classes.flexWrap}
                    >
                        <TextField
                            variant="outlined"
                            label="Scene ID"
                            placeholder="e.g. 4549699_3163124_2021-06-04_2408"
                            value={sceneId}
                            onChange={handleSceneIdChange}
                            className={classes.textField}
                        />
                        {farmGroupId ? (
                            <FarmsAutoComplete
                                farmGroupId={farmGroupId}
                                preSelectedFarm={selectedFarm}
                                onSelect={handleFarmSelect}
                            />
                        ) : (
                            <Skeleton variant="rect" width={210} height={55} />
                        )}
                    </Box>
                    <Box textAlign="right">
                        <Cta
                            label="Download"
                            onClick={handleSceneAddClick}
                            isDisabled={isCtaDisabled}
                        />
                    </Box>
                </Box>
            </Box>
            <DownloadProgress />
        </Paper>
    );
}
