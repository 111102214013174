import React from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { useFirestoreConnect } from 'react-redux-firebase';
import ScenesDataGrid from './ScenesDataGrid';

export default function ArchivedScenes() {
    useFirestoreConnect([
        {
            collection: 'ndvi-process-tracking',
            storeAs: 'scenes',
            orderBy: [
                // ['date_archived', 'desc'],
                ['date_reported_str', 'desc'],
            ],
            where: ['is_archived', '==', true],
        },
    ]);
    const scenes = useAppSelector((state) => (state.firestore as any).data?.scenes);

    return <ScenesDataGrid scenes={scenes} enableSelection={false} />;
}
