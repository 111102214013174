import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { LinearProgress, Paper, Snackbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { shallowEqual } from 'react-redux';
import { fetchZoneBoundaries } from './onboardSlice';
import { Alert } from '@material-ui/lab';
import ReactJson from 'react-json-view';
import ExistingZonesMap from './ExistingZonesMap';
import DateSelector from '../../../components/DateSelector';
import { middayUtcStr } from '../../../utils/DateUtil';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(2),
        },
        textField: {
            width: '25ch',
        },
    })
);

export default function ExistingZoneBoundaries({
    farmId,
    farmLocalTimeZone,
}: {
    farmId: number;
    farmLocalTimeZone: string;
}) {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [dateOnboarded, setDateOnboarded] = useState<Date>(new Date());
    const [areFieldsCleared, setAreFieldsCleared] = useState(false);
    const handleAfterReset = () => setAreFieldsCleared(false);

    useEffect(() => {
        if (farmId && dateOnboarded) {
            dispatch(
                fetchZoneBoundaries({
                    farmId: farmId,
                    asOf: middayUtcStr(dateOnboarded.toISOString(), farmLocalTimeZone),
                })
            );
        }
    }, [farmId, dateOnboarded, dispatch, farmLocalTimeZone]);

    const { loading, error, response, zoneBoundaries } = useAppSelector((state) => {
        return {
            loading: state.onboarding.onboardZoneBoundariesResponseStatus.loading,
            error: state.onboarding.onboardZoneBoundariesResponseStatus.error,
            response: state.onboarding.onboardZoneBoundariesResponse,
            zoneBoundaries: state.onboarding.zoneBoundaries,
        };
    }, shallowEqual);

    return (
        <Paper className={classes.root}>
            <Box padding={2}>
                <Typography variant="subtitle1">Zone Boundaries</Typography>
                <DateSelector
                    label="Onboarded on"
                    onChange={(value: Date) => {
                        setDateOnboarded(value);
                    }}
                    reset={areFieldsCleared}
                    onAfterReset={handleAfterReset}
                />
            </Box>
            <Divider />
            {loading && <LinearProgress />}
            <ExistingZonesMap
                farmId={farmId}
                geoJsonOld={zoneBoundaries}
                asOf={dateOnboarded?.toISOString()}
            />
            {error && (
                <Snackbar>
                    <Alert severity="error">{error}</Alert>
                </Snackbar>
            )}
            {response && (
                <>
                    <Divider />
                    <Box padding={4}>
                        <ReactJson
                            src={response}
                            name="Response"
                            iconStyle="square"
                            displayDataTypes={false}
                        />
                    </Box>
                </>
            )}
        </Paper>
    );
}
