import firebase from 'firebase/app';
import { get } from '../../api/client';
import { FarmCollective } from './farmCollectivesType';
import 'firebase/auth';

export const getFarmCollectives = async (): Promise<FarmCollective[]> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
        return [];
    }
    return await get(token, `farmcollectives`);
};
