export const getEpsg = (timeZone: string): number => {
    switch (timeZone) {
        case 'Pacific/Auckland':
            return 2193;
        case 'Europe/Amsterdam':
            return 28991;
        default:
            return -1;
    }
};
