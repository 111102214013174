import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../app/hooks';
import PaddockMeasurements from './components/PaddockMeasurements';
import ParkMeasurements from './parks';

const farmIdsOfParks = [123];

export default function Paddocks() {
    const filters = useAppSelector((state) => state.filters, shallowEqual);

    const [isPark, setIsPark] = useState<boolean>(false);

    useEffect(() => {
        let farmId = filters?.farm?.farmId;
        setIsPark(farmIdsOfParks.includes(farmId));
    }, [filters]);

    return isPark ? <ParkMeasurements /> : <PaddockMeasurements />;
}
