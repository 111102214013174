import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useAppSelector } from '../../../app/hooks';
import { Items } from './Items';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        secondaryText: {
            maxWidth: 200,
        },
        listItemIcon: {
            color: '#ffffff',
        },
    })
);

function ListItemLink({ icon, primary, to, selected }: any) {
    const classes = useStyles();
    const CustomLink = React.forwardRef((props, ref) => <Link to={to} {...props}></Link>);

    return (
        <li>
            <ListItem button component={CustomLink} selected={selected}>
                <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
}

export default function NavDrawerItems() {
    const role = useAppSelector((state) => (state.firebase as any).profile?.role);

    const items = role === 'super-admin' ? Items : Items.filter((i) => !i.isAdmin);

    const [selectedItemText, setSelectedItemText] = useState<string>(Items[0].text);

    return (
        <List>
            {items.map((item) => (
                <ListItemLink
                    icon={item.icon}
                    primary={item.text}
                    to={item.link}
                    selected={item.text === selectedItemText}
                    onClick={() => setSelectedItemText(item.text)}
                    key={item.text}
                />
            ))}
        </List>
    );
}
