import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Plotly from 'plotly.js-basic-dist';
import createPlotlyComponent from 'react-plotly.js/factory';

const useStyles = makeStyles(() => ({
    root: {
        margin: 20,
        overflowX: 'scroll',
    },
}));

export type ChartData = {
    bar: BarChart;
    line: LineChart;
};

type Chart = {
    x: number[];
    y: number[];
    text: string[];
};

type BarChart = Chart & {
    widths: number[];
};

type LineChart = Chart;

type FeedWedgeProps = {
    zoneGroup: string;
    data: ChartData;
    color: string;
};

function Index({ zoneGroup, color, data }: FeedWedgeProps) {
    const classes = useStyles();
    const Plot = createPlotlyComponent(Plotly);

    return (
        <div className={classes.root}>
            <Plot
                data={[
                    {
                        type: 'scatter',
                        mode: 'lines+markers',
                        name: 'target',
                        x: data.line.x,
                        y: data.line.y,
                        text: data.line.text,
                        textposition: 'auto',
                        hoverinfo: 'none',
                        marker: { color: '#086105' },
                    },
                    {
                        type: 'bar',
                        name: 'cover',
                        x: data.bar.x,
                        y: data.bar.y,
                        text: data.bar.text,
                        textposition: 'auto',
                        width: data.bar.widths,
                        hoverinfo: 'text',
                        marker: {
                            color: color,
                            line: {
                                color: '#ffffff',
                                width: 1,
                            },
                        },
                    },
                ]}
                layout={{
                    title: `Paddock Group: ${zoneGroup}`,
                    showlegend: false,
                    xaxis: { visible: false, fixedrange: true },
                    yaxis: { title: 'Cover (kgDM/ha)', fixedrange: true },
                    dragmode: 'pan',
                    width: 900,
                }}
                config={{
                    modeBarButtonsToRemove: ['lasso2d', 'select2d'],
                }}
            />
        </div>
    );
}

export default Index;
