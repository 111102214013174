import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import DateRangePicker from '../../../../components/DateRangePicker';
import { DateTime } from 'luxon';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import Cta from '../../../../components/Cta';
import { Grazing } from './grazingType';
import AddedGrazings from './AddedGrazings';
import { fetchReportGrazings, reset } from './grazingsSlice';
import PaddocksAutoComplete from '../../PaddocksAutoComplete';
import { Paddock } from '../../paddockType';
import { DATE_FORMATS, fromStrISOToDateTime } from '../../../../utils/DateUtil';
import { useFlags } from 'launchdarkly-react-client-sdk';
import GrazingHistory from 'features/grazings-history/grazingsHistory';
import _ from 'lodash';
import { STATUS } from 'utils/Store/Constants';
import { addNotification } from 'features/notifications/notificationsSlice';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                margin: theme.spacing(4),
            },
        },
        wrapper: {
            display: 'flex',
            columnGap: '1.5rem',
            rowGap: '1rem',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginTop: theme.spacing(1),
            padding: theme.spacing(2),
        },
        autoComplete: {
            width: '12rem',
        },
        reportCta: {
            textAlign: 'left',
            marginTop: theme.spacing(2),
        },
        spacing: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
        },
    })
);

export default function Grazings() {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const [refreshHistory, setRefreshHistory] = useState<boolean>(false);
    const filters = useAppSelector((state) => state.filters);
    const { reportGrazingHistoryEnabled } = useFlags();
    const [areFieldsCleared, setAreFieldsCleared] = useState(false);
    const [selectedPaddock, setSelectedPaddock] = useState<Paddock | null>(null);

    const { grazingsStatus } = useAppSelector((state) => {
        return {
            grazingsStatus: state.grazings.status,
        };
    });

    useEffect(() => {
        if (grazingsStatus === STATUS.succeeded) {
            dispatch(addNotification({ message: 'Grazings reported', severity: 'success' }));
            dispatch(reset());
            setRefreshHistory((v) => true);
        }
    }, [grazingsStatus, dispatch]);

    const [dateRange, setDateRange] = React.useState([DateTime.local(), DateTime.local()]);

    const handleAfterReset = () => setAreFieldsCleared(false);

    const clearFields = () => {
        setDateRange([fromStrISOToDateTime(filters.from), fromStrISOToDateTime(filters.to)]);
        setSelectedPaddock(null);
        setAreFieldsCleared(true);
    };

    const [grazings, setGrazings] = useState<Grazing[]>([]);
    const handleAddClick = () => {
        const newGrazing: Grazing = {
            begin: dateRange[0]?.toFormat(DATE_FORMATS.dayMonthYearShort),
            end: dateRange[1]?.toFormat(DATE_FORMATS.dayMonthYearShort),
            paddock: {
                paddockId: selectedPaddock!.paddockId,
                paddockName: selectedPaddock!.paddockName,
                zones: selectedPaddock!.zones,
            },
        };
        setGrazings(_.uniqWith([...grazings, newGrazing], _.isEqual));
        setSelectedPaddock(null);
    };

    const handleDeleteClick = (grazingToDelete: Grazing) => {
        setGrazings(_.difference(grazings, [grazingToDelete]));
    };

    const handleReportClick = () => {
        dispatch(
            fetchReportGrazings({
                farmId: filters.farm.farmId,
                farmName: filters.farm.farmName,
                farmLocalTimeZone: filters.farm.timeZone,
                grazings: grazings.flatMap((g) =>
                    g.paddock.zones.map((z) => ({
                        begin: g.begin,
                        end: g.end,
                        zone_id: z.zoneId,
                        zone_name: z.zoneName,
                    }))
                ),
            })
        );
        setGrazings([]);
        clearFields();
    };

    const [refreshPaddocks, setRefreshPaddocks] = useState(false);
    const handleDateChange = (begin: DateTime, end: DateTime) => {
        setRefreshPaddocks(true);
        setDateRange([begin, end]);
    };

    const handleAfterRefresh = () => {
        setRefreshPaddocks(false);
    };

    const showAddCta = selectedPaddock?.paddockId! > 0;

    return (
        <div className={classes.root}>
            <Paper className={classes.wrapper}>
                <DateRangePicker
                    value={dateRange}
                    placeholder="Select a date range"
                    onChange={(values: any) => {
                        handleDateChange(values.begin, values.end);
                    }}
                    autoOk={true}
                    format={DATE_FORMATS.dayMonthYearShort}
                    reset={areFieldsCleared}
                    onAfterReset={handleAfterReset}
                />
                <PaddocksAutoComplete
                    farmId={filters.farm.farmId}
                    farmLocalTimeZone={filters.farm.timeZone}
                    from={dateRange[0].toJSDate()}
                    to={dateRange[1].toJSDate()}
                    preSelectedPaddock={selectedPaddock}
                    onSelect={(newValue) => setSelectedPaddock(newValue)}
                    refresh={refreshPaddocks}
                    onAfterRefresh={handleAfterRefresh}
                />
                <Cta label="add" onClick={handleAddClick} isDisabled={!showAddCta} />
            </Paper>
            {grazings.length > 0 && (
                <div className={classes.spacing}>
                    <div className={classes.reportCta}>
                        <Cta label="Report" onClick={handleReportClick} />
                    </div>
                    <AddedGrazings grazings={grazings} onDeleteClick={handleDeleteClick} />
                </div>
            )}

            {reportGrazingHistoryEnabled && (
                <GrazingHistory
                    farmId={`${filters.farm.farmId}`}
                    collectionModified={refreshHistory}
                />
            )}
        </div>
    );
}
