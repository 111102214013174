import React, { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

type BatchScenesToasterProps = {
    open: boolean;
    isSuccessful: boolean;
    successMessage: string;
    errorMessage: string;
};
export default function BatchScenesToaster({
    open,
    isSuccessful,
    successMessage,
    errorMessage,
}: BatchScenesToasterProps) {
    const [show, setShow] = useState(true);

    const snackBar = (message: string, type: 'error' | 'success') => (
        <Snackbar
            open={open && show}
            onClose={() => setShow(false)}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                severity={type}
                action={
                    <>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={() => setShow(false)}
                            style={{ padding: 4 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </>
                }
            >
                {message}
            </Alert>
        </Snackbar>
    );

    return (
        <>{isSuccessful ? snackBar(successMessage, 'success') : snackBar(errorMessage, 'error')}</>
    );
}
