import React, { useEffect, useState } from 'react';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { ZoneGrazedModel, ZoneGrazedEvent } from '../grazingsHistoryType';
import { FetchNext } from 'components/FetchNext';
import { BoxCenter } from 'components/BoxCenter';
import { IconButton, Toolbar, Typography } from '@material-ui/core';
import { PaddockDictionary } from 'features/paddocks/paddockType';
import _uniqWith from 'lodash/uniqWith';
import _isEqual from 'lodash/isEqual';
import { orderBy } from 'lodash';
import { Refresh } from '@material-ui/icons';
import TableContent from './TableContent';
import { mergeEvents } from '../groupHistoryUtil';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(2),
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        boxContainer: {
            minHeight: theme.spacing(6),
            paddingTop: theme.spacing(4),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        tbHead: {
            minHeight: '48px',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        },
        title: {
            flex: '1 1 100%',
        },
        subtitle: {
            fontSize: '0.75rem',
        },
    })
);

type Props = {
    data: ZoneGrazedEvent[];
    loading: boolean;
    rowsPerPage: number;
    page: number;
    zones: PaddockDictionary;
    refresh: boolean;
    showRefreshIcon: boolean;
    setPage: (i: number) => void;
    onIconRefreshClicked: (event: React.MouseEvent<unknown>) => void;
    onItemDeleted: (zoneId: string, grazedGroupId: string) => void;
};

export default function Index(props: Props) {
    const classes = useStyles();
    const [rows, setRows] = useState<ZoneGrazedModel[]>([]);

    const {
        data,
        loading,
        rowsPerPage,
        page,
        zones,
        showRefreshIcon,
        refresh,
        setPage,
        onIconRefreshClicked,
        onItemDeleted,
    } = props;

    useEffect(() => {
        if (!Object.keys(zones).length) {
            return;
        }

        // const mappedData = data.map(p => ({
        //     eventId: p.eventId,
        //     eventTime: p.eventTime,
        //     zoneId: p.payload.zoneId,
        //     grazedGroupId: p.payload.grazedGroupId,
        //     displayName: zones[p.payload.zoneId]?.paddockName || p.payload.zoneId,
        //     begin: p.payload.begin,
        //     end: p.payload.end,
        //     user: p.payload.userId,
        // }));

        const getDisplayName = (p: ZoneGrazedEvent) =>
            zones[p.payload.zoneId]?.paddockName || p.payload.zoneId;

        const mergedList = mergeEvents(data, getDisplayName);

        const list = orderBy(
            mergedList,
            ['end', (p) => p.displayName.toUpperCase()],
            ['desc', 'asc']
        );

        if (refresh) {
            setRows((p) => list);
            return;
        }

        setRows((p) => {
            return _uniqWith([...p, ...list], _isEqual);
        });
    }, [data, refresh, zones]);

    const onIconRefreshClickedHandler = (event: React.MouseEvent<unknown>) => {
        onIconRefreshClicked(event);
        setRows([]);
    };

    const onConfirmItemDeletehandler = (e: React.MouseEvent<any>, model: ZoneGrazedModel) => {
        onItemDeleted(model.zoneId, model.grazedGroupId);
        e.stopPropagation();
    };

    const fetchMoreHandler = (p: number) => {
        setPage(p);
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Toolbar className={classes.tbHead}>
                    <Typography
                        className={classes.title}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        Report grazings history
                    </Typography>
                    {showRefreshIcon && (
                        <IconButton
                            aria-label="refresh"
                            size="medium"
                            onClick={onIconRefreshClickedHandler}
                        >
                            <Refresh />
                        </IconButton>
                    )}
                </Toolbar>

                {!loading && !rows.length && <BoxCenter description="No records" />}

                <TableContent
                    rows={rows}
                    setSelected={() => {}}
                    onConfirmItemDeletehandler={onConfirmItemDeletehandler}
                />

                <FetchNext
                    skip={page}
                    fetchMore={fetchMoreHandler}
                    hasMore={data.length >= rowsPerPage}
                    loading={loading}
                />
            </Paper>
        </div>
    );
}
