import { DateTime } from 'luxon';
import { Str } from './StringConstants';

export const DATE_FORMATS = {
    yearMonthDayShort: 'yyyy-MM-dd',
    dayMonthYearShort: 'dd/MM/yyyy',
    dayMonthAbbreviated: 'LLL d',
};

export function browserLocalDateOnly(dateTime: DateTime): DateTime {
    return DateTime.fromISO(dateTime.toFormat(DATE_FORMATS.yearMonthDayShort));
}

export function browserLocalDateOnlyStr(isoDate: string): string {
    return browserLocalDateOnly(DateTime.fromISO(isoDate)).toLocaleString();
}

export function browserLocalDateOnlyNow(): DateTime {
    return browserLocalDateOnly(DateTime.local());
}

export function browserLocalDateOnlyDaysAgo(days: number): DateTime {
    return browserLocalDateOnly(DateTime.local().minus({ days: 6 }));
}

export function startOfDayDT(dt: DateTime, timeZone: string = dt.zoneName): DateTime {
    return dt.setZone(timeZone).set({ hour: 0, minute: 0, second: 1, millisecond: 0 });
}

/**
 * When dealing with API and Farms timezone, This format specify the current date
 * @param dt as Luxon.DateTime
 * @param timeZone optional string. Will default to date passed timezone
 * @returns date as Luxon.DateTime
 */
export function startOfDay(dt: DateTime, timeZone: string = dt.zoneName): DateTime {
    return dt.setZone(timeZone).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
}

export function middayDT(dt: DateTime, timeZone: string = dt.zoneName): DateTime {
    return dt.setZone(timeZone).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
}

export function midday(dt: Date, timeZone: string): DateTime {
    return middayDT(DateTime.fromJSDate(dt), timeZone);
}

export function middayStr(dt: string): string {
    return middayDT(DateTime.fromISO(dt), DateTime.fromISO(dt).zoneName).toISO();
}

export function middayUtcStr(dt: string, timezone: string): string {
    return middayDT(DateTime.fromISO(dt), timezone).toUTC().toISO();
}

export function startOfDayUtcStr(dt: string, timezone: string): string {
    return startOfDayDT(DateTime.fromISO(dt), timezone).toUTC().toISO();
}

export function toBrowserLocal(dt: string, timezone = 'utc'): Date {
    return DateTime.fromISO(dt).setZone(timezone).toLocal().toJSDate();
}

export function formatStrToFullTime(timeStr: string, timeZone: string): string {
    return DateTime.fromISO(timeStr)
        .setZone('utc')
        .setZone(timeZone)
        .toLocaleString(DateTime.TIME_24_WITH_SECONDS);
}

export const formatStrToDateMed = (dateStr: string, timeZone: string) =>
    DateTime.fromISO(dateStr).setZone('utc').setZone(timeZone).toLocaleString(DateTime.DATE_MED);

export function fromStrISOToDateTime(dt: string, timezone = 'utc'): DateTime {
    return DateTime.fromISO(dt).setZone(timezone);
}

export const displayDateFormat = (d: any): string => {
    if (!d) return 'invalid date';
    return fromStrISOToDateTime(d.toString()).toLocaleString();
};

/**
 * This function should be used to transform date format.
 * We will only pass date in the following format (2022-05-29) as default. Format can be passed to it
 * >> We favored luxon.DateTime instead of browser Date object.
 * @param date - [string|Date|DateTime]
 * @returns Date without time [ie. 2022-05-29]
 */
export function toDateOnly(
    date: string | Date | DateTime,
    format: string = DATE_FORMATS.yearMonthDayShort
): string {
    const error = new Error("Parameter 'date' is not valid");
    if (!date) {
        throw error;
    }

    if (date instanceof DateTime) {
        return date.toFormat(format);
    }

    if (typeof date === 'string' && date !== Str.empty) {
        return DateTime.fromISO(date, { zone: 'utc' }).toFormat(format);
    }

    if (typeof date === 'object' && typeof date.getMonth === 'function') {
        return DateTime.fromJSDate(date, { zone: 'utc' }).toFormat(format);
    }

    throw error;
}
