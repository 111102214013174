import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import NavDrawerItems from './NavDrawerItems';
import Toolbar from '@material-ui/core/Toolbar';
import Logo from '../../../resources/Farmote-Product-Logo-(white).png';
import LogoShort from '../../../resources/short-logo.png';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import Hidden from '@material-ui/core/Hidden';

const drawerWidth = 240;
const drawerShortWidth = 60;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            backgroundColor: '#EE4D9B',
            padding: 0,
        },
        logo: {
            width: 90,
        },
        logoWrapper: {
            margin: '0 auto',
        },
        logoShort: {
            width: 25,
        },
        drawer: {
            width: drawerShortWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            backgroundColor: '#383C56',
            color: '#ffffff',
            borderRight: 0,
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
        },
        shiftIcon: {
            padding: theme.spacing(2),
            position: 'absolute',
            bottom: theme.spacing(1),
            color: '#ffffff',
        },
    })
);

type NavDrawerProps = {
    onShiftClick: () => void;
    open: boolean;
    openMobileDrawer: boolean;
    onMobileDrawerClose: () => void;
};

export default function Index({
    onShiftClick,
    open,
    openMobileDrawer,
    onMobileDrawerClose,
}: NavDrawerProps) {
    const classes = useStyles();

    return (
        <nav>
            <Hidden xsDown implementation="css">
                <Drawer
                    anchor="left"
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx(classes.drawer, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                >
                    <Toolbar className={classes.toolbar}>
                        <div className={classes.logoWrapper}>
                            {open ? (
                                <img src={Logo} alt="logo" className={classes.logo} />
                            ) : (
                                <img src={LogoShort} alt="logo" className={classes.logoShort} />
                            )}
                        </div>
                    </Toolbar>
                    <Divider />
                    <div role="presentation">
                        <NavDrawerItems />
                        <IconButton className={classes.shiftIcon} onClick={onShiftClick}>
                            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </div>
                </Drawer>
            </Hidden>
            <Hidden xsUp implementation="css">
                <Drawer
                    anchor="left"
                    variant="temporary"
                    classes={{
                        paper: clsx(classes.drawer, classes.drawerOpen),
                    }}
                    open={openMobileDrawer}
                    onClose={onMobileDrawerClose}
                >
                    <div role="presentation">
                        <NavDrawerItems />
                    </div>
                </Drawer>
            </Hidden>
        </nav>
    );
}
