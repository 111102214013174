import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FarmCollective } from './farmCollectivesType';
import { getFarmCollectives } from './farmCollectivesApi';
import { STATUS } from 'utils/Store/Constants';

interface CollectiveState {
    collectives: FarmCollective[];
    loading: boolean;
    status: string;
    error: string | null;
}

export const fetchFarmsCollectives = createAsyncThunk(
    'farms/fetchAllFarmCollectives',
    async () => await getFarmCollectives()
);

const initialState: CollectiveState = {
    collectives: [],
    loading: false,
    status: STATUS.idle,
    error: null,
};

export const farmCollectivesSlice = createSlice({
    name: 'farms',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchFarmsCollectives.pending.type]: (state: CollectiveState) => {
            state.loading = true;
            state.status = STATUS.loading;
            state.error = null;
        },
        [fetchFarmsCollectives.fulfilled.type]: (
            state: CollectiveState,
            action: PayloadAction<FarmCollective[]>
        ) => {
            state.collectives = action.payload;
            state.loading = false;
            state.status = STATUS.succeeded;
            state.error = null;
        },
        [fetchFarmsCollectives.rejected.type]: (
            state: CollectiveState,
            action: PayloadAction<string>
        ) => {
            state.loading = false;
            state.status = STATUS.failed;
            state.error = action.payload;
        },
    },
});

export default farmCollectivesSlice.reducer;
