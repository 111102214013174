import React, { useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useAppSelector } from '../../../../app/hooks';
import { shallowEqual } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

type DeleteToasterProps = {
    batchId: string;
};

export default function DeleteToaster({ batchId }: DeleteToasterProps) {
    const { deleteBatchLoading, deletedBatchStatus, deleteBatchError, isDeleted } = useAppSelector(
        (state) => {
            return {
                deleteBatchLoading: state.batches.deleteStatus.loading,
                deletedBatchStatus: state.batches.deleteStatus.status,
                deleteBatchError: state.batches.deleteStatus.error,
                isDeleted: state.batches.isDeleted,
            };
        },
        shallowEqual
    );

    const [toasterType, setToasterType] = useState<'error' | 'success' | 'none'>('none');
    useEffect(() => {
        if (deletedBatchStatus === 'idle' || deleteBatchLoading) {
            setToasterType('none');
        } else if (isDeleted) {
            setToasterType('success');
        } else if (deleteBatchError) {
            setToasterType('error');
        }
    }, [isDeleted, deleteBatchError, deletedBatchStatus, deleteBatchLoading]);

    const snackBar = (message: string, type: 'error' | 'success') => (
        <Snackbar
            open={toasterType !== 'none'}
            onClose={() => setToasterType('none')}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                severity={type}
                action={
                    <>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={() => setToasterType('none')}
                            style={{ padding: 4 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </>
                }
            >
                {message}
            </Alert>
        </Snackbar>
    );

    return (
        <>
            {toasterType === 'error' &&
                snackBar(`${deleteBatchError}. Batch ${batchId} is not deleted!`, 'error')}
            {toasterType === 'success' && snackBar(`Batch ${batchId} is deleted!`, 'success')}
        </>
    );
}
