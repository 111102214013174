import firebase from 'firebase/app';
import { get, post } from '../../../../api/client';
import { PaddockMeasurementGroup } from '../types/paddockMeasurementTypes';
import 'firebase/auth';

export const getPaddockMeasurements = async (
    fromUtc: string,
    toUtc: string,
    farmId: number,
    targetPgr: number
): Promise<PaddockMeasurementGroup[]> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
        return [];
    }
    const response = await get(
        token,
        `paddocks/measurements/farm/${farmId}/from/${fromUtc}/to/${toUtc}/tpgr/${targetPgr}`
    );
    return (response as PaddockMeasurementGroup[]).map((d) => d);
};

export const downloadPaddockMeasurements = async (
    fromUtc: string,
    toUtc: string,
    farmId: number,
    farmName: string,
    targetPgr: number
): Promise<any> => {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
        return;
    }
    const response = await post(
        token,
        `paddocks/measurements/farm/${farmId}/from/${fromUtc}/to/${toUtc}/tpgr/${targetPgr}/download`,
        {}
    );
    return {
        ...response,
        fileName: `paddocks-${farmName}-${fromUtc.slice(0, 10)}-${toUtc.slice(0, 10)}.csv`,
    };
};
