import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { Box, createStyles, lighten, makeStyles, Theme } from '@material-ui/core';
import { TableCell, Tooltip, TableRow, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { ZoneGrazedModel } from '../grazingsHistoryType';
import { browserLocalDateOnlyStr } from 'utils/DateUtil';
import _map from 'lodash/map';
import clsx from 'clsx';
import { Str } from 'utils/StringConstants';
import AlertDialog from 'components/AlertDialog';
import { useState } from 'react';

const useTableStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 450,
        },
        row: {
            padding: theme.spacing(2),
            position: 'relative',
        },
        text: {
            fontSize: '0.9rem',
            color: theme.palette.secondary.main,
        },
        hide: {
            visibility: 'hidden',
        },
        spaceBetween: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        hover: {
            cursor: 'pointer',
        },
        highlight: {
            color: theme.palette.error.dark,
            backgroundColor: lighten(theme.palette.error.light, 0.5),
            boxShadow: 'inset 0 0 2px #000',
        },
    })
);

const defaultZoneGrazedModel: ZoneGrazedModel = {
    eventId: Str.empty,
    eventTime: Str.empty,
    begin: Str.empty,
    end: Str.empty,
    user: Str.empty,
    zoneId: Str.empty,
    grazedGroupId: Str.empty,
    displayName: Str.empty,
};

export type TableProps = {
    rows: ZoneGrazedModel[];
    setSelected: (model: ZoneGrazedModel) => void;
    onConfirmItemDeletehandler: (event: React.MouseEvent<unknown>, model: ZoneGrazedModel) => void;
};

export default function TableContent(props: TableProps) {
    const classes = useTableStyles();
    const { rows, onConfirmItemDeletehandler } = props;
    const [selected, setSelected] = useState<ZoneGrazedModel>(defaultZoneGrazedModel);
    const [open, setOpen] = useState(false);

    if (!rows.length) {
        return <></>;
    }

    const isSelected = (r: ZoneGrazedModel) => selected?.eventId === r.eventId;

    const userFriendlyMessage = (row: ZoneGrazedModel) => {
        return (
            <Box flex={1} className={classes.text}>
                <strong>{`${row.displayName}`}</strong>
                {' grazed from '}
                <strong>{`${browserLocalDateOnlyStr(row.begin)}`}</strong>
                {' to '}
                <strong>{`${browserLocalDateOnlyStr(row.end)}`}</strong>
            </Box>
        );
    };

    return (
        <TableContainer>
            <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
                id="table-container"
            >
                <AlertDialog
                    title="The following grazing will be deleted"
                    isOpen={open}
                    onClose={() => setOpen(false)}
                    children={selected && userFriendlyMessage(selected)}
                    onClickConfirm={(e) => onConfirmItemDeletehandler(e, selected!)}
                ></AlertDialog>
                <TableBody>
                    {_map(rows, (row, idx) => {
                        const key = `table-item-${idx}`;
                        const itemSelected = isSelected(row);
                        return (
                            <TableRow id={key} hover role="checkbox" tabIndex={-1} key={key}>
                                <TableCell
                                    component="th"
                                    id={`row-delete-${row.eventId}`}
                                    scope="row"
                                    className={clsx(classes.row, {
                                        [classes.highlight]: itemSelected,
                                    })}
                                    onClick={(e) => setSelected(row)}
                                >
                                    <Box
                                        width="100%"
                                        display="flex"
                                        alignItems="center"
                                        className={classes.hover}
                                    >
                                        <IconButton
                                            aria-label="delete"
                                            size="small"
                                            onClick={(e) => setOpen(true)}
                                            className={clsx(Str.empty, {
                                                [classes.hide]: !itemSelected,
                                            })}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                        <Tooltip
                                            key={row.eventId}
                                            title={`Actioned by ${
                                                row.user
                                            } on ${browserLocalDateOnlyStr(row.eventTime)}`}
                                        >
                                            {userFriendlyMessage(row)}
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
