import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import PaddockGroupsIcon from '@material-ui/icons/GroupWork';
import GrazingIcon from '@material-ui/icons/Texture';
import { Drawer } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Indigo from '@material-ui/core/colors/indigo';
import Typography from '@material-ui/core/Typography';
import Grazings from './grazings';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import Adjustments from './adjustments';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import PaddockGroups from '../../paddock-groups/actions';
import { useAppSelector } from '../../../app/hooks';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            width: '30%',
            [theme.breakpoints.down('md')]: {
                width: '50%',
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        toolbar: {
            backgroundColor: Indigo[50],
        },
        title: {
            flexGrow: 1,
        },
        speedDial: {
            position: 'fixed',
            '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
                bottom: theme.spacing(2),
                right: theme.spacing(2),
            },
            '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
                top: theme.spacing(2),
                left: theme.spacing(2),
            },
        },
    })
);

enum Actions {
    MoteAdjustments = 'Mote Adjustments',
    PaddockGroups = 'Paddock Groups',
    ReportGrazings = 'Report Grazings',
}

const userActions = [
    { icon: <PaddockGroupsIcon />, name: Actions.PaddockGroups },
    { icon: <GrazingIcon />, name: Actions.ReportGrazings },
];

const adminActions = [{ icon: <ReportProblemIcon />, name: Actions.MoteAdjustments }];

export default function PaddockActions() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [title, setTitle] = useState('');

    const role = useAppSelector((state) => (state.firebase as any).profile?.role);

    const isAdmin = role === 'super-admin';

    const actions = isAdmin ? [...adminActions, ...userActions] : userActions;

    const handleActionClick = (title: string) => {
        handleClose();
        setOpenDrawer(true);
        setTitle(title);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (!role) {
        return null;
    }

    return (
        <div>
            <SpeedDial
                ariaLabel="PaddockActions"
                className={classes.speedDial}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        title={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => handleActionClick(action.name)}
                    />
                ))}
            </SpeedDial>
            <Drawer
                anchor="right"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                classes={{
                    paper: classes.drawer,
                }}
            >
                <Toolbar className={classes.toolbar}>
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                    <IconButton
                        aria-label="close drawer"
                        onClick={() => setOpenDrawer(false)}
                        edge="start"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                {title === Actions.PaddockGroups && <PaddockGroups />}
                {title === Actions.ReportGrazings && <Grazings />}
                {title === Actions.MoteAdjustments && <Adjustments />}
            </Drawer>
        </div>
    );
}
