import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Drawer } from '@material-ui/core';
import Indigo from '@material-ui/core/colors/indigo';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            width: '30%',
            [theme.breakpoints.down('md')]: {
                width: '50%',
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        toolbar: {
            backgroundColor: Indigo[50],
        },
        title: {
            flexGrow: 1,
        },
        formControl: {
            width: 180,
        },
    })
);

type PaddockUnitsProps = {
    openDrawer: boolean;
    onClose: () => void;
};

export default function PaddockUnits({ openDrawer, onClose }: PaddockUnitsProps) {
    const classes = useStyles();

    return (
        <Drawer
            anchor="right"
            open={openDrawer}
            onClose={onClose}
            classes={{
                paper: classes.drawer,
            }}
        >
            <Toolbar className={classes.toolbar}>
                <Typography variant="h6" className={classes.title}>
                    Units Chart
                </Typography>
                <IconButton aria-label="close drawer" onClick={onClose} edge="start">
                    <CloseIcon />
                </IconButton>
            </Toolbar>
            <Box margin={4}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <strong>Cover</strong>
                            </TableCell>
                            <TableCell>kg DM/ha</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <strong>Available Cover</strong>
                            </TableCell>
                            <TableCell>kg DM/ha</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <strong>Available Feed</strong>
                            </TableCell>
                            <TableCell>kg DM</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <strong>Daily Growth Rate</strong>
                            </TableCell>
                            <TableCell>kg DM/ha/day</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <strong>3 Days Growth Rate</strong>
                            </TableCell>
                            <TableCell>kg DM/ha/day</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <strong>7 Days Growth Rate</strong>
                            </TableCell>
                            <TableCell>kg DM/ha/day</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <strong>Effective Area</strong>
                            </TableCell>
                            <TableCell>ha</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
        </Drawer>
    );
}
